// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__WMICClaimAndVehicleInfoBox__capitalizeText{text-transform:capitalize}.app__WMICClaimAndVehicleInfoBox__wmic-deductible-tooltip{margin-left:5px}.app__WMICClaimAndVehicleInfoBox__wmic-deductible-tooltip [class*=blue-icon]{align-items:start}.app__WMICClaimAndVehicleInfoBox__wmic-deductible{display:flex}h2[id=claimAndVehicleInfoBoxTitle]{font-weight:600}", "",{"version":3,"sources":["webpack://./node_modules/gw-capability-claim-react/components/WMICClaimAndVehicleInfoBox/WMICClaimAndVehicleInfoBox.module.scss"],"names":[],"mappings":"AAIA,iDACE,yBAAA,CAGF,0DACE,eAAA,CAEA,6EACE,iBAAA,CAIJ,kDACE,YAAA,CAGF,mCACE,eAAA","sourcesContent":["@import \"~@jutro/theme/assets/sass/helpers\";\n@import \"~wmic-amp-digital-theme-styles/sass/base/config/colors.scss\";\n@import \"~wmic-amp-digital-theme-styles/sass/customer-colors.scss\";\n\n.capitalizeText {\n  text-transform: capitalize;\n}\n\n.wmic-deductible-tooltip {\n  margin-left: 5px;\n\n  [class*=\"blue-icon\"] {\n    align-items: start;\n  }\n}\n\n.wmic-deductible {\n  display: flex;\n}\n\nh2[id=claimAndVehicleInfoBoxTitle] {\n  font-weight: 600;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"capitalizeText": "app__WMICClaimAndVehicleInfoBox__capitalizeText",
	"wmic-deductible-tooltip": "app__WMICClaimAndVehicleInfoBox__wmic-deductible-tooltip",
	"wmic-deductible": "app__WMICClaimAndVehicleInfoBox__wmic-deductible"
};
export default ___CSS_LOADER_EXPORT___;

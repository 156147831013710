// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__BillingSummaryPage__tabContent{align-items:center;display:flex}.app__BillingSummaryPage__tabContent>i{padding-right:2px}.app__BillingSummaryPage__billingSummaryContainer{margin-top:var(--GW-LAYOUT-4)}", "",{"version":3,"sources":["webpack://./node_modules/gw-capability-billing-react/BillingSummaryPage/BillingSummaryPage.module.scss"],"names":[],"mappings":"AAEA,qCAEI,kBAAA,CADA,YACA,CAEA,uCACI,iBAAA,CAIR,kDACI,6BAAA","sourcesContent":["@import \"~@jutro/theme/assets/sass/helpers\";\n\n.tabContent {\n    display: flex;\n    align-items: center;\n\n    > i {\n        padding-right: 2px;\n    }\n}\n\n.billingSummaryContainer {\n    margin-top: var(--GW-LAYOUT-4);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabContent": "app__BillingSummaryPage__tabContent",
	"billingSummaryContainer": "app__BillingSummaryPage__billingSummaryContainer"
};
export default ___CSS_LOADER_EXPORT___;

import { defineMessages } from 'react-intl';

export default defineMessages({
    FNOLVehicleInformationInsuredTitle: {
        id: 'wmic.fnol-page.vehicle-information.insured-title',
        defaultMessage: 'Wawanesa Insured Vehicle Information'
    },
    FNOLVehicleInformationOtherTitle: {
        id: 'wmic.fnol-page.vehicle-information.other-title',
        defaultMessage: 'Other Party Vehicle Information'
    },
    FNOLVehicleInformationVehicleYear: {
        id: 'wmic.fnol-page.vehicle-information.year',
        defaultMessage: 'Vehicle Year'
    },
    FNOLVehicleInformationVehicleMake: {
        id: 'wmic.fnol-page.vehicle-information.make',
        defaultMessage: 'Vehicle Make'
    },
    FNOLVehicleInformationVehicleModel: {
        id: 'wmic.fnol-page.vehicle-information.model',
        defaultMessage: 'Vehicle Model'
    },
    FNOLVehicleInformationVehicleColor: {
        id: 'wmic.fnol-page.vehicle-information.color',
        defaultMessage: 'Vehicle Color'
    },
    FNOLVehicleInformationLicensePlate: {
        id: 'wmic.fnol-page.vehicle-information.license-plate',
        defaultMessage: 'License Plate Number'
    },
    FNOLVehicleInformationVIN: {
        id: 'wmic.fnol-page.vehicle-information.vin',
        defaultMessage: 'VIN'
    },
    FNOLVehicleInformationDamage: {
        id: 'wmic.fnol-page.vehicle-information.damage',
        defaultMessage: 'Tell us about the extent and location of the damage on this vehicle'
    }
})

import React, { useState } from 'react';
import { MetadataForm } from '@jutro/legacy/uiconfig';
import { useTranslator } from '@jutro/locale';
import _ from 'lodash';
import CONSTANTS from 'wmic-portals-utils-js/StringConstants';

import messages from './FNOLVehicleInformationComponent.messages.js';
import styles from '../FNOLStyle.module.scss';
import metadata from './FNOLVehicleInformationComponent.metadata.json5';

function FNOLVehicleInformationComponent(props) {
    const { WMICFnolSharedDataService, variant } = props;
    const translator = useTranslator();
    const sharedDataPath = variant === CONSTANTS.FNOL.VARIANTS.OTHER_PARTY ? CONSTANTS.FNOL.SHARED_DATA_PATHS.OTHER_PARTY_CLAIMANT : CONSTANTS.FNOL.SHARED_DATA_PATHS.INSURED

    const [vehicleInformation, setVehicleInformation] = useState(WMICFnolSharedDataService[sharedDataPath].vehicle);


    const getSectionTitle = () => {
        return variant === CONSTANTS.FNOL.VARIANTS.OTHER_PARTY ? translator(messages.FNOLVehicleInformationOtherTitle) : translator(messages.FNOLVehicleInformationInsuredTitle)
    }

    const resolvers = {
        resolveClassNameMap: styles
    }

    const overrideProps = {
        FNOLVehicleInformationTitle:{
            content: getSectionTitle()
        }
    }

    return (
        <MetadataForm
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
            data={vehicleInformation}
            onDataChange={(value, path) => {
                const newData = {...vehicleInformation};
                _.set(newData, path, value);
                WMICFnolSharedDataService[sharedDataPath].vehicle = newData;
                setVehicleInformation(newData);
            }} />
    );
}

export default FNOLVehicleInformationComponent
import { defineMessages } from 'react-intl';

export default defineMessages({
    FNOLPAAutoGlassTitle: {
        id: 'wmic.fnol-page.auto-glass.title',
        defaultMessage: 'HAVE AN AUTO GLASS CLAIM?'
    },
    FNOLPAAutoGlassSubTitle: {
        id: 'wmic.fnol-page.auto-glass.subtitle',
        defaultMessage: 'Click below to access our Self Service portal and create an automobile glass claim.'
    },
    FNOLPAAutoGlassButton: {
        id: 'wmic.fnol-page.auto-glass.button',
        defaultMessage: 'Create Auto Glass Claim'
    },
    FNOLPAAutoGlassButtonTrackButtonIdentifier: {
        id: 'wmic.fnol-page.auto-glass.tracking.button',
        defaultMessage: 'FNOL - PA - {state} - Create Auto Glass Claim'
    },
})

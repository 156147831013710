// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__WMICCancelRemoveVehicleModal__wmic-paragraph-text{font-size:1.3rem!important;margin-bottom:18px!important;padding-left:6px;padding-right:6px}.app__WMICCancelRemoveVehicleModal__wmic-title div span{font-size:35px;line-height:47px;padding-left:30px;padding-right:30px;padding-top:25px}", "",{"version":3,"sources":["webpack://./../common/capabilities-react/gw-capability-policychange-common-react/components/WMICRemoveVehiclePA/WMICCancelRemoveVehicleModal/WMICCancelRemoveVehicleModal.module.scss"],"names":[],"mappings":"AAAA,wDAGI,0BAAA,CACA,4BAAA,CAHA,gBAAA,CACA,iBAEA,CAGF,wDACE,cAAA,CACA,gBAAA,CACA,iBAAA,CACA,kBAAA,CACA,gBAAA","sourcesContent":[".wmic-paragraph-text {\n    padding-left: 6px;\n    padding-right: 6px;\n    font-size: 1.3rem !important;\n    margin-bottom: 18px !important;\n  }\n  \n  .wmic-title div span{\n    font-size: 35px;\n    line-height: 47px;\n    padding-left: 30px;\n    padding-right: 30px;\n    padding-top: 25px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wmic-paragraph-text": "app__WMICCancelRemoveVehicleModal__wmic-paragraph-text",
	"wmic-title": "app__WMICCancelRemoveVehicleModal__wmic-title"
};
export default ___CSS_LOADER_EXPORT___;

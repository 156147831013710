import React from 'react';
import { Redirect } from 'react-router-dom';

const MaintenancePage = () => {
    return <Redirect to="/technicalErrorPage/maintenance" />;
};

MaintenancePage.propTypes = {};

MaintenancePage.defaultProps = {};

export default MaintenancePage;
import React, { useState } from 'react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { useAuthentication } from 'wmic-digital-auth-react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import WMICEmqLoginModel from '../../models/WMICEmqLoginModel';

import metadata from './WMICEMQLandingPage.metadata.json5';
import messages from './WMICEMQLandingPage.messages';
import styles from './WMICEMQLandingPage.module.scss';

function WMICEMQLandingPage(props) {
    const { onNext, searchError } = props;
    const { authHeader } = useAuthentication();
    const translator = useTranslator();
    const initialData = {
        policyNumber: '',
        lastName: ''
    };

    const [formData, setFormData] = useState(initialData);

    const handleSubmit = () => {
        const submitData = new WMICEmqLoginModel({
            policyNumber: formData.policyNumber,
            lastName: formData.lastName
        });
        onNext(submitData, authHeader);
    };

    const handleChangeValue = (value, path) => {
        const newFormData = _.clone(formData);
        _.set(newFormData, path, value);
        setFormData(newFormData);
    };

    const getFollowingInformationText = () => {
        return (
            <p>
                {translator(messages.EMQLandingPageFollowingInformation)}
                <strong>{translator(messages.EMQLandingPageEachVehicle)}</strong>
            </p>
        );
    };

    const getHelpText = () => {
        return (
            <span>
                {translator(messages.EMQLandingPageHelpText)}
                <a target="_blank" rel="noopener noreferrer" href="https://www.wawanesa.com/us/california/amq/billing_help.html">{translator(messages.EMQLandingPageClickHere)}</a>
                .
            </span>
        );
    };

    const getErrorText = () => {
        return (
            <span>
                {translator(messages.EMQLandingPageLeftColumnThe)}
                <strong>{translator(messages.EMQLandingPageCardFormAuto)}</strong>
                {translator(messages.EMQLandingPageLeftColumnOr)}
                <strong>{translator(messages.EMQLandingPageCardFormName)}</strong>
                {translator(messages.EMQLandingPageLeftColumnError1)}
                {translator(messages.EMQLandingPageLeftColumnError2)}
                <a href="tel:1-800-640-2920">{translator(messages.EMQLandingPageLeftColumnPhone)}</a>
                .
            </span>
        );
    };

    const overrideProps = {
        emqLandingLeftColumnFollowingInstructionsText: {
            content: getFollowingInformationText()
        },
        emqLandingLeftColumnHelpText: {
            content: getHelpText()
        },
        emqLandingPageCardFormButton: {
            disabled: !(formData.lastName && formData.policyNumber),
        },
        emqLandingPageCardFormError: {
            visible: searchError
        },
        emqLandingLeftColumnErrorText: {
            content: getErrorText()
        },
        emqLandingLeftColumnError: {
            visible: searchError
        },
        emqLandingLeftColumn: {
            visible: !searchError
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onSubmit: handleSubmit
        },
    };

    WMICEMQLandingPage.propTypes = {
        onNext: PropTypes.func.isRequired,
        searchError: PropTypes.func.isRequired
    };


    return (
        <ViewModelForm
            model={formData}
            uiProps={metadata.componentContent}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
            overrideProps={overrideProps}
            onValueChange={handleChangeValue}
        />
    );
}

export default WMICEMQLandingPage;

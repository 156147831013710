import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { MetadataForm } from '@jutro/legacy/uiconfig';
import _ from 'lodash';
import cx from 'classnames';
import CONSTANTS from 'wmic-portals-utils-js/StringConstants';
import { FNOLConstants } from 'wmic-capability-fnol-react';

import { useTranslator } from '@jutro/locale';
import metadata from './FNOLWitnessesComponent.metadata.json5';
import styles from '../FNOLStyle.module.scss';
import messages from './FNOLWitnessesComponent.messages.js';


function FNOLWitnessesComponent(props){
    const { WMICFnolSharedDataService, WMICFnolUtilsService, validateFields, state } = props;
    const [witnesses, setWitnesses] = useState(WMICFnolSharedDataService.witnesses);
    const [toggleValue, setToggleValue] = useState(WMICFnolSharedDataService.witnessesSelected.value);
    const [count, setCount] = useState(1);
    const translator = useTranslator();

    const handleUpdateList = useCallback(() => {
        const listLength = witnesses.list.length;
        let updatedList = [...witnesses.list];

        if (count !== listLength) {
            if (count > listLength) {
                updatedList.push(...Array.from({length: count - listLength}, () => WMICFnolUtilsService.witness(state)));
            } else  {
                witnesses.list.splice(count);
                updatedList = witnesses.list;
            }
        }

        setWitnesses({
            ...witnesses,
            list: updatedList
        });
        WMICFnolSharedDataService.witnesses.list = updatedList;
    }, [WMICFnolUtilsService.witness, WMICFnolSharedDataService.witnesses, witnesses, count]);

    useEffect(() => {
        handleUpdateList();
    }, [count, handleUpdateList]);
        
    const onDropdownChange = useCallback((item) => {
        setCount(item);
    }, [setCount]);

    const onToggleChange = (value) => {
        setToggleValue(value);
        WMICFnolSharedDataService.witnessesSelected.value = value;
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onToggleChange
        }
    };

    const renderTabs = () => {
        return _.map(witnesses.list, (item, index) => {
            const itemId = index + 1;
            return {
                id: `tab-${itemId}`,
                type: 'element',
                component: 'tab',
                componentProps: {
                    id: `witnesses-tab-${itemId}`,
                    heading: itemId,
                    showFrame: true,
                    className: 'fnolTab'
                },
                content: [
                    {
                        id: `FNOLWitnessesTabDescrption_tab${itemId}`,
                        type: 'element',
                        component: 'h4',
                        content: translator(messages.FNOLWitnessesDescription, {num: itemId})
                    },
                    {
                        id: "FNOLWitnessesFirstNameContainer",
                        type:"container",
                        component:"div",
                        componentProps: {
                            className:"ww-input--75 entry-item"
                        },
                        content: [
                            {
                                id: `FNOLWitnessesFirstName_tab${itemId}`,
                                type:"field",
                                component:"input",
                                componentProps: {
                                    value: item.firstName,
                                    labelClassName:"input-required",
                                    path:`list[${index}].firstName`,
                                    label: {
                                        id: "wmic.fnol-page.witnesses.first-name-label",
                                        defaultMessage: "First Name"
                                    },
                                    required: true,
                                    maxLength: 50
                                }
                            },
                            {
                                id: `FNOLWitnessesFirstNameError_tab${itemId}`,
                                type:"element",
                                component:"span",
                                componentProps: {
                                    className:"error",    
                                    visible: validateFields('witnesses.list', 'tabset', false, 'firstName')
                                },
                                content:{
                                    id: "wmic.fnol-page.pa.field-required",
                                    defaultMessage: "Field required"
                                }
                            },
                        ]
                    },
                    {
                        id: "FNOLWitnessesLastNameContainer",
                        type:"container",
                        component:"div",
                        componentProps: {
                            className:"ww-input--75 entry-item"
                        },
                        content: [
                            {
                                id: `FNOLWitnessesLastName_tab${itemId}`,
                                type:"field",
                                component:"input",
                                componentProps: {
                                    value: item.lastName,
                                    labelClassName:"input-required",
                                    path:`list[${index}].lastName`,
                                    label: {
                                        id: "wmic.fnol-page.witnesses.last-name-label",
                                        defaultMessage: "Last Name"
                                    },
                                    required: true,
                                    maxLength: 50
                                }
                            },
                            {
                                id: `FNOLWitnessesLastNameError_tab${itemId}`,
                                type:"element",
                                component:"span",
                                componentProps: {
                                    className:"error",
                                    visible: validateFields('witnesses.list', 'tabset', false, 'lastName')
                                },
                                content:{
                                    id: "wmic.fnol-page.pa.field-required",
                                    defaultMessage: "Field required"
                                }
                            }
                        ]
                    },
                    {
                        id: `FNOLWitnessesAddress_tab${itemId}`,
                        type:"field",
                        component:"input",
                        componentProps: {
                            value: item.address,
                            className:"entry-item ww-input--75",
                            path:`list[${index}].address.street`,
                            label: {
                                id: "wmic.fnol-page.witnesses.address-label",
                                defaultMessage: "Address"
                            },
                            maxLength: 50 
                        }
                    },
                    {
                        id: `FNOLWitnessesCity_tab${itemId}`,
                        type:"field",
                        component: "input",
                        componentProps: {
                            value: item.city,
                            className: "entry-item ww-input--75",
                            path: `list[${index}].address.city`,
                            label: {
                                id: "wmic.fnol-page.witnesses.city-label",
                                defaultMessage: "City"
                            },
                            maxLength: 50
                        }
                    },
                    {
                        id: `FNOLWitnessesCountryContainer_tab${itemId}`,
                        type: "container",
                        component:"div",
                        componentProps: {
                            className:"ww-input--75 entry-item"
                        },
                        content: [
                            {
                                id: `FNOLWitnessesCountry_tab${itemId}`,
                                type: "field",
                                component: "dropdownselect",
                                componentProps: {
                                    availableValues: WMICFnolUtilsService.getSelectCanadaUSA(),
                                    path: `list[${index}].address.country`,
                                    className: "wmic-fnol-dropdown",
                                    label: {
                                        id: "wmic.fnol-page.loss-information.country-label",
                                        defaultMessage: "Country"
                                    },
                                    internalClassNames: {
                                        option: "dropdownOption"
                                    }
                                }
                            }
                        ]
                    },
                    {
                        id: `FNOLWitnessesStateProvinceContainer_tab${itemId}`,
                        type: "container",
                        component:"div",
                        componentProps: {
                            className:"ww-input--75 entry-item"
                        },
                        content: [
                            {
                                id: `FNOLWitnessesStateProvince_tab${itemId}`,
                                type: "field",
                                component: "dropdownselect",
                                componentProps: {
                                    availableValues: WMICFnolUtilsService.getStatesShown(item),
                                    path: `list[${index}].address.state`,
                                    className: "wmic-fnol-dropdown",
                                    label: {
                                        id: "wmic.fnol-page.loss-information.state-province-label",
                                        defaultMessage: "State"
                                    },
                                    internalClassNames: {
                                        option: "dropdownOption"
                                    }
                                }
                            }
                        ]
                    },
                    {
                        id: `FNOLWitnessesZipCode_tab${itemId}`,
                        type:"field",
                        component: "inputmask",
                        componentProps: {
                            mask: "99999",
                            maskChar: "_",
                            alwaysShowMask: true,
                            value: item.postalCode,
                            className: "entry-item ww-input--75",
                            path: `list[${index}].address.postalCode`,
                            label: {
                                id: "wmic.fnol-page.witnesses.zipcode-label",
                                defaultMessage: "Zip Code"
                            }   
                        }
                    },
                    {
                        id: `FNOLWitnessesHomePhone_tab${itemId}`,
                        type:"field",
                        component: "inputmask",
                        componentProps: {
                            mask: "(999) 999-9999",
                            maskChar: "_",
                            alwaysShowMask: true,
                            value: item.phoneNumber,
                            className: "entry-item ww-input--75",
                            path: `list[${index}].phoneNumber`,
                            label: {
                                id: "wmic.fnol-page.witnesses.home-phone-label",
                                defaultMessage: "Home Phone Number"
                            }   
                        }
                    },
                    {
                        id: `FNOLWitnessesBusinessPhone_tab${itemId}`,
                        type:"field",
                        component: "inputmask",
                        componentProps: {
                            mask: "(999) 999-9999",
                            maskChar: "_",
                            alwaysShowMask: true,
                            value: item.homeBusinessNumber,
                            className: "entry-item ww-input--75",
                            path: `list[${index}].businessPhoneNumber`,
                            label: {
                                id: "wmic.fnol-page.witnesses.business-phone-label",
                                defaultMessage: "Business Phone Number"
                            }   
                        }
                    },
                    {
                        id: `FNOLWitnessesExt_tab${itemId}`,
                        type:"field",
                        component: "Input",
                        componentProps: {
                            maxLength: 5,
                            placeholder: "_____",
                            registerValidation: () => _.noop,
                            value: item.ext,
                            className: "entry-item ww-input--75",
                            path: `list[${index}].businessPhoneNumberExt`,
                            label: {
                                id: "wmic.fnol-page.witnesses.ext-label",
                                defaultMessage: "Ext"
                            }   
                        }
                    },
                    {
                        id: `FNOLWitnessesCellPhone_tab${itemId}`,
                        type:"field",
                        component: "inputmask",
                        componentProps: {
                            mask: "(999) 999-9999",
                            maskChar: "_",
                            alwaysShowMask: true,
                            value: item.cellPhoneNumber,
                            className: "entry-item ww-input--75",
                            path: `list[${index}].cellPhoneNumber`,
                            label: {
                                id: "wmic.fnol-page.witnesses.cellphone-label",
                                defaultMessage: "Cell Phone Number"
                            }   
                        }
                    },
                    {
                        id: `FNOLWitnessesDriversLicense_tab${itemId}`,
                        type:"field",
                        component: "input",
                        componentProps: {
                            value: item.driversLicenseNo,
                            className: "entry-item ww-input--75",
                            path: `list[${index}].driverLicNo`,
                            label: {
                                id: "wmic.fnol-page.witnesses.drivers-license-label",
                                defaultMessage: "Drivers License Number"
                            },
                            maxLength: 20
                        }
                    },
                    {
                        id: `FNOLWitnessesInjuriesOrTreatments_tab${itemId}`,
                        type:"field",
                        component: "textarea",
                        componentProps: {
                            value: item.injuries,
                            className: "entry-item ww-input--75",
                            path: `list[${index}].injuries`,
                            label: {
                                id: "wmic.fnol-page.witnesses.injuries-or-treatments-label",
                                defaultMessage: "Tell us about any injuries or treatments received"
                            },
                            maxLength: 1333   
                        }
                    },
                ]
            };
        })
    };

    const setZipLabel = useCallback((country) => {
        return country === CONSTANTS.COUNTRY.US ? translator(messages.FNOLWitnessesFormZipCode)  : translator(messages.FNOLWitnessesFormPostalCode) 
    }, [translator])

    const zipOverrides = useMemo(() => {
        const overrides = _.get(witnesses, 'list')
            .reduce((partial, witness, index) => ({
                ...partial,
                [`FNOLWitnessesZipCode_tab${index+1}`]: {
                    mask: FNOLConstants.setZipMask(witness?.address.country),
                    label: setZipLabel(witness?.address.country)
                }
            }), {});
        return overrides;
    }, [witnesses, setZipLabel]);

    const overrideProps = {
        ...zipOverrides,
        FNOLWitnessesCount: {
            availableValues: FNOLConstants.AVAILABLE_COUNTS,
            value: count,
            onValueChange: onDropdownChange
        },
        FNOLWitnessesToggle: {
            value: toggleValue
        },
        FNOLWitnessesFormContainer: {
            visible: toggleValue === true
        },
        FNOLWitnessesNoWitnessesWarning: {
            visible: toggleValue === false
        }, 
        FNOLWitnessesTabSet: {
            activeTab: 'witnesses-tab-1',
            content: renderTabs(),
            className: cx('entry-item', styles.fnolTabSet)
        },
        FNOLWitnessesToggleError: {
            visible: validateFields('witnessesSelected.value', 'toggle')

        }
    }

    return (
        <MetadataForm
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
            data={witnesses}
            onDataChange={(value, path) => {
                value = (path.split('.').at(-1) ==='businessPhoneNumberExt') ? value.replace(/\D/g,'') : value;
                const newData = {...witnesses};
                _.set(newData, path, value);
                WMICFnolSharedDataService.witnesses = newData;
                setWitnesses(newData);
                if (path.split('.').at(-1) === 'country') {
                    WMICFnolUtilsService.countryOptionChanged(path, value, state, witnesses, setWitnesses, WMICFnolSharedDataService.witnesses);
                }
            }} />
    );
}

export default FNOLWitnessesComponent
import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Icon, useModal } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { Flex, FlexItem } from '@jutro/layout';
import WMICReviewContactInfoModal from './WMICReviewContactInfoModal';
import styles from './WMICReviewContactInfo.module.scss';
import messages from './WMICReviewContactInfo.messages';

const WMICReviewContactInfo = ({
    openModalOnRender,
    onClose,
    updateIsContactReviewed
}) => {
    const modalApi = useModal();
    const translator = useTranslator();
    const triggerModal = useCallback(async () => {
        await modalApi.showModal(
            <WMICReviewContactInfoModal
                onClose={onClose}
                updateIsContactReviewed={updateIsContactReviewed}
            />
        );
    }, [onClose]);

    useEffect(() => {
        if (openModalOnRender) {
            triggerModal();
        }
    }, [openModalOnRender, triggerModal]);

    return (
        <section role='alert'>
            <button role='link' className={styles.card} onClick={() => triggerModal()}>
                <Flex alignItems="center" wrap={false}>
                    <FlexItem>
                        <i className={`${styles.icon} fa fa-user-circle-o`} />
                    </FlexItem>
                    <FlexItem textAlign="left">
                        <h2 className={styles.reviewNotice}>
                            {translator(messages.reviewContactInfoWarningMsg)}
                        </h2>
                        <div>{translator(messages.updateMsg)}</div>
                    </FlexItem>
                </Flex>
            </button>
        </section>
    );
};

WMICReviewContactInfo.propTypes = {
    openModalOnRender: PropTypes.bool,
};

WMICReviewContactInfo.defaultProps = {
    openModalOnRender: true,
};

export default WMICReviewContactInfo;

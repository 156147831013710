import { defineMessages } from 'react-intl';

export default defineMessages({
    provideVerificationInformation: {
        id: 'enrollment.components.additionalenrollment.Please provide verification information for the policy you want to access',
        defaultMessage: 'Please provide verification information for the policy you want to access.'
    },
    relatedPoliciesAddedAutomatically: {
        id: 'enrollment.components.additionalenrollment.Related policies will be added automatically',
        defaultMessage: 'Related policies will be added automatically.'
    },
    completeFieldsToAccessPolicyInfo: {
        id: 'enrollment.components.additionalenrollment.Simply complete all fields for online access to your policy information',
        defaultMessage: 'Simply complete all fields for online access to your policy information.'
    }
});

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__Claims__popupFieldComponent{width:100%}", "",{"version":3,"sources":["webpack://./node_modules/gw-capability-claim-react/Claims.module.scss"],"names":[],"mappings":"AAAA,kCACI,UAAA","sourcesContent":[".popupFieldComponent {\n    width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popupFieldComponent": "app__Claims__popupFieldComponent"
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__WMICBankAccountsList__ww-detail-payment-row{border-top:1px solid #ccc;padding:2rem 0}.app__WMICBankAccountsList__ww-form-item-marge-xxs{align-items:center;display:flex}.app__WMICBankAccountsList__ww-form-item-marge-xxs>div{margin-left:1rem}.app__WMICBankAccountsList__ww-span-bold{font-weight:700}.app__WMICBankAccountsList__ww-span-italic{display:block;font-style:italic}.app__WMICBankAccountsList__used-for-mar{font-weight:700}.app__WMICBankAccountsList__used-for-mar,.app__WMICBankAccountsList__used-for-mar-increase-font-size{font-size:1.33rem}.app__WMICBankAccountsList__edit-payment-instrument-button{margin-right:3px}", "",{"version":3,"sources":["webpack://./node_modules/wmic-components-amp-common-react/WMICUserProfile/WMICBankAccountsList/WMICBankAccountsList.module.scss"],"names":[],"mappings":"AAKA,kDACE,yBAAA,CACA,cAAA,CAGF,mDAEE,kBAAA,CADA,YACA,CAEA,uDACE,gBAAA,CAIJ,yCACE,eAAA,CAGF,2CAEE,aAAA,CADA,iBACA,CAGF,yCAEE,eAAA,CAGF,qGAJE,iBAKA,CAGF,2DACE,gBAAA","sourcesContent":["@import \"~@jutro/theme/assets/sass/helpers\";\n@import \"~wmic-amp-digital-theme-styles/sass/base/config/colors.scss\";\n@import \"~wmic-amp-digital-theme-styles/sass/customer-colors.scss\";\n@import \"~wmic-amp-digital-theme-styles/sass/customer-variables.scss\";\n\n.ww-detail-payment-row {\n  border-top: 1px solid $clr-ww-pinline;\n  padding: 2rem 0;\n}\n\n.ww-form-item-marge-xxs {\n  display: flex;\n  align-items: center;\n\n  > div {\n    margin-left: 1rem;\n  }\n}\n\n.ww-span-bold {\n  font-weight: bold;\n}\n  \n.ww-span-italic {\n  font-style: italic;\n  display: block;\n}\n\n.used-for-mar {\n  font-size: 1.33rem;\n  font-weight: bold;\n}\n\n.used-for-mar-increase-font-size {\n  font-size: 1.33rem;\n}\n\n.edit-payment-instrument-button {\n  margin-right: 3px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ww-detail-payment-row": "app__WMICBankAccountsList__ww-detail-payment-row",
	"ww-form-item-marge-xxs": "app__WMICBankAccountsList__ww-form-item-marge-xxs",
	"ww-span-bold": "app__WMICBankAccountsList__ww-span-bold",
	"ww-span-italic": "app__WMICBankAccountsList__ww-span-italic",
	"used-for-mar": "app__WMICBankAccountsList__used-for-mar",
	"used-for-mar-increase-font-size": "app__WMICBankAccountsList__used-for-mar-increase-font-size",
	"edit-payment-instrument-button": "app__WMICBankAccountsList__edit-payment-instrument-button"
};
export default ___CSS_LOADER_EXPORT___;

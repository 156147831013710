import React from 'react';
import { wmicUsePagination, DOTS } from './WMICUsePagination';
import { useTranslator } from '@jutro/locale';
import messages from './WMICAutoShopsListViewComponent.messages';
import styles from './WMICAutoShopsListViewComponent.module.scss';
import { Link } from '@jutro/router';
const WMICPagination = props => {
  const {onPageChange, totalCount, currentPage, pageSize} = props;
  const translator = useTranslator();

  const paginationRange = wmicUsePagination({
    currentPage,
    totalCount,
    pageSize
  });

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange[paginationRange.length - 1];
  return (
    <div className={`${styles['pagination-container']}`}>
      <ul className={`${styles['list-view-pagination']}`}>
        <li className={`pt-5-5 pb-5-5`}>
          {
            paginationRange.map(pageNumber => {
              if (pageNumber === DOTS) {
                return <a className={`${styles['pagination-item']} ${styles['dots']}`} aria-hidden={true}>
                          &#8230;
                      </a>
              }
              
              return (
                <Link className={`${styles['pagination-item']} ${pageNumber === currentPage ? styles['selected'] : ''}`} onClick={() => onPageChange(pageNumber)} aria-label={`Goto page ${currentPage}`}>
                  {pageNumber}
                </Link>
              );
            })
          }
        </li>
      </ul>
      <ul className={`${styles['list-view-pagination']}`}>
        <li className={`pt-5-5 pb-5-5`}>
          <Link className={`${styles['pagination-item']}`} hidden={currentPage === 1} onClick={onPrevious} aria-hidden={currentPage === 1 ? true : false} aria-label={currentPage === 1 ? `You are on the first page` : `Goto page ${currentPage - 1}`}>
            {translator(messages.autoShopsListPrevious)}
          </Link>
          <Link className={`${styles['pagination-item']}`} hidden={currentPage === lastPage} onClick={onNext} aria-hidden={currentPage === lastPage ? true : false} aria-label={currentPage === lastPage ? `You are on the last page` : `Goto page ${currentPage + 1}`}>
            {translator(messages.autoShopsListNext)}
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default WMICPagination;
import React, { useState, useEffect, useCallback } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { MetadataContent } from '@jutro/legacy/uiconfig';
import { useTranslator } from '@jutro/locale';
import { WMICRichTextUtil, WMICFeatureFlagUtil } from 'wmic-portals-utils-js';
import { useAuthentication } from 'wmic-digital-auth-react';
import CONSTANTS from "wmic-portals-utils-js/StringConstants";
import { get } from 'lodash';
import moment from 'moment';
import { WMICButton } from 'wmic-components-platform-react';
import { 
    broadcastEvent,
    FNOLConstants, 
    FNOLDisclaimer, 
    FNOLYourInformationComponent, 
    FNOLLossInformationComponent, 
    FNOLInsuredInformationComponent,
    FNOLPoliceReportComponent,
    FNOLMortgageesComponent
} from 'wmic-capability-fnol-react';
import { WMICFnolStateService, WMICFnolSharedDataService, WMICFnolUtilsService, WMICFnolSharedDataServiceObject } from 'wmic-capability-fnol';

import metadata from './WMICFNOLHOPage.metadata.json5';
import messages from '../WMICFNOLPage.messages.js';
import styles from '../WMICFNOLPage.module.scss';

function WMICFNOLHOPage(props){
    const { match } = props;
    const translator = useTranslator();
    const [trySubmit, setTrySubmit] = useState(false);
    const [fFlags, setfFlags] = useState({});
    const { userInfo: authUserData } = useAuthentication();
    const featureFlags = WMICFeatureFlagUtil.getFeatureFlags();
    const [hasError, setHasError] = useState(false);
    const stateParam = get(match, 'params.state');
    const claimType = FNOLConstants.CLAIM_TYPE.RESIDENTIAL;
    const history = useHistory();
    const [loading, setLoading] = useState(true);

    const state = WMICFnolStateService.getState();

    const initFeatureFlags = async () => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const rFlags = await WMICFeatureFlagUtil.useFlags(authUserData);
        setfFlags(rFlags);
    }

    useEffect (() => {
        initFeatureFlags();
    }, []);

    const fnolFFAvailable = useCallback(() => {
        return WMICFeatureFlagUtil.queryAvailabilityAMP(fFlags, featureFlags.FNOL)?.isAvailable;
    },[fFlags, featureFlags]);

    useEffect(() => {
        if (Object.entries(fFlags).length > 1) {
            if (state) {
                WMICFnolUtilsService.copyAttributes(state, WMICFnolSharedDataService);
            } else {
                WMICFnolUtilsService.copyAttributes(WMICFnolSharedDataServiceObject, WMICFnolSharedDataService);
            }
    
            if (!fnolFFAvailable() || (stateParam !== CONSTANTS.STATE.CA_LONG && stateParam !== CONSTANTS.STATE.OR_LONG)) {
                history.push('/home');
            } else {
                setTimeout(() => {
                    setLoading(false);
                }, 100);
            }
        }
    }, [stateParam, fFlags]);

    const handleYourInfoDispatcher = (data) => {
        if (data.reportingParty) {
            broadcastEvent.dispatch("yourInfoUpdated", {data});            
        }
    }

    const handleSave = async () => {
        setTrySubmit(true);
        const result = await WMICFnolUtilsService.scrollToError()
        if(!result){
            WMICFnolStateService.addState(WMICFnolSharedDataService);
            history.push({
                pathname: `/${stateParam}/residential/review`,
                state: {
                    previousStep: FNOLConstants.FLOW_STEPS.START
                }
            });
            window.scrollTo(0,0)
        }
    };
    
    const validateDate = (value, optional) => {
        const date = moment(value, 'MM/DD/YYYY', true);
        if(optional)
            return !((date.isValid() || FNOLConstants.REGEX_VALIDATIONS.DATE.test(value) || value.length < 1) && (date <= new Date()))
        return !((date.isValid() || FNOLConstants.REGEX_VALIDATIONS.DATE.test(value)) && (date <= new Date()))
    };

    const validateFields = (path, type, optional = false, attr = '') => {
        const value = get(WMICFnolSharedDataService, path);
        let res = false;
        switch(type) {
            case 'field':
                res = !(value || !trySubmit);
                break;
            case 'policy':
                res = !((value && value.length > 6)|| !trySubmit);
                break;
            case 'date': 
                res = (trySubmit && validateDate(value, optional))
                break;
            case 'time':
                res = (trySubmit && value.length > 1 && !FNOLConstants.REGEX_VALIDATIONS.TIME.test(value))
                break;
            case 'toggle':
                res = !(value !== null || !trySubmit)
                break;
            case 'tabset':
                res = !(value.every((obj) => obj[attr]) || !trySubmit)
                break;
            default:
                break
        }
        if (res && !hasError) setHasError(true)
        if (trySubmit && WMICFnolUtilsService.getFNOLHOHasError(WMICFnolSharedDataService)) setHasError(false);
        return res
    }

    const overrideProps = {
        FNOLHOContainer75PolicyNumberRequired: {
            content: WMICRichTextUtil.translateRichText(translator(messages.FNOLPagePolicyNumberRequired))
        },
        FNOLHODisclaimer: {
            showCaliforniaLawDisclaimer: stateParam === CONSTANTS.STATE.CA_LONG
        },
        FNOLHOFormYourInformation: {
            claimType: claimType,
            WMICFnolSharedDataService,
            validateFields,
            handleYourInfoDispatcher
        },
        FNOLHOFormLossInformation: {
            state: FNOLConstants.formatState(stateParam),
            WMICFnolSharedDataService,
            claimType,
            validateFields
        },
        FNOLHOInsuredInformationComponent: {
            WMICFnolSharedDataService,
            claimType,
            state: FNOLConstants.formatState(stateParam),
            validateFields
        },
        FNOLHOPoliceReportComponent: {
            WMICFnolSharedDataService,
            validateFields
        },
        FNOLHOMortgageesComponent: {
            state: FNOLConstants.formatState(stateParam),
            WMICFnolSharedDataService,
            WMICFnolUtilsService,
            validateFields
        },
        FNOLHOErrorMessage: {
            visible: hasError
        },
        FNOLHOContainer: {
            visible: !loading
        },
        FNOLHOContinueButton: {
            trackButtonIdentifier: translator(messages.FNOLContinueTrackButtonIdentifier, {claimType: claimType, state: stateParam})
        }
    }

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            FNOLDisclaimer,
            FNOLYourInformationComponent,
            FNOLLossInformationComponent,
            FNOLInsuredInformationComponent,
            FNOLPoliceReportComponent,
            FNOLMortgageesComponent,
            WMICButton
        },
        resolveCallbackMap: {
            handleSave
        }
    }

    return (
        <div>
            <MetadataContent
                uiProps={metadata.pageContent}
                overrideProps={overrideProps}
                {...resolvers} />
        </div>
    );
}

export default withRouter(WMICFNOLHOPage)

const WMICEnrollmentErrorCodes = {
    ACCOUNT_ACTIVATED: 1001,
    ACCOUNT_INVALID_STATUS: 1002,
    NO_DATA_FOUND: 1003,
    INVALID_LOCALE: 1004,
    PC_INFO_MISMATCH: 1005,
    REQUIRED_FIELD: 1006,
    POLICY_CENTER_JSON_RPC_ERROR: 1007,
    ACCOUNT_NOT_FOUND: 1008,
    GENERIC_ERROR: 1000,
    FORGOT_PASSWORD_NOT_ALLOWED: "E0000034",
    DOES_NOT_MEET_MIN_REQUIREMENT: "E0000080"
}

export default WMICEnrollmentErrorCodes;
import { defineMessages } from 'react-intl';

export default defineMessages({
    autoshopsVehicleEstimateMilesTitle: {
        id: 'wmic.autoshops.vehicle-page.distance-calculation.title',
        defaultMessage: 'Need Help Calculating Distance?'
    },
    autoshopsVehicleEstimateMilesOrigin: {
        id: 'wmic.autoshops.vehicle-page.distance-calculation.home-address',
        defaultMessage: 'Enter your home address'
    },
    autoshopsVehicleEstimateMilesDestination: {
        id: 'wmic.autoshops.vehicle-page.distance-calculation.destination-address',
        defaultMessage: 'Enter your work / school Address'
    },
    autoshopsVehicleEstimateMilesButton: {
        id: 'wmic.autoshops.vehicle-page.distance-calculation.button',
        defaultMessage: 'Calculate the distance'
    },
    autoshopsVehicleEstimateRecalculateButton: {
        id: 'wmic.autoshops.vehicle-page.distance-recalculation.button',
        defaultMessage: 'Calculate a new distance'
    },
    autoshopsVehicleEstimateResultTitle: {
        id: 'wmic.autoshops.vehicle-page.distance-calculation.result-title',
        defaultMessage: 'The distance, one way, is:'
    },
    autoshopsVehicleEstimateResultNotFound: {
        id: 'wmic.autoshops.vehicle-page.distance-calculation.result-not-found',
        defaultMessage: 'The distance cannot be calculated.'
    },
    autoshopsVehicleEstimateMiles: {
        id: 'wmic.autoshops.vehicle-page.distance-calculation.estimate-miles',
        defaultMessage: '{distance} Miles'
    },
    autoshopsVehicleEstimateNoResult: {
        id: 'wmic.autoshops.vehicle-page.distance-calculation.no-result',
        defaultMessage: 'No results'
    },
    autoshopsVehicleEstimateNotFound: {
        id: 'wmic.autoshops.vehicle-page.distance-calculation.not-found',
        defaultMessage: 'Not found'
    },
    autoshopsVehicleEstimatePlaceholder: {
        id: 'wmic.autoshops.vehicle-page.distance-calculation.placeholder',
        defaultMessage: '123 Anywhere Drive, Los Angeles, CA'
    }
});

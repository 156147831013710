import { defineMessages } from 'react-intl';

export default defineMessages({
    EMQSuccessComponentCardText1: {
        id: 'wmic.emq-success-component.card-text-1',
        defaultMessage: 'Thanks for providing the estimated mileage for policy'
    },
    EMQSuccessComponentCardText2: {
        id: 'wmic.emq-success-component.card-text-2',
        defaultMessage: 'on'
    },
    EMQSuccessComponentCardText3: {
        id: 'wmic.emq-success-component.card-text-3',
        defaultMessage: 'We will contact you if we have any questions, and an amended policy declaration document may be sent to you.'
    },
});

import { defineMessages } from 'react-intl';

export default defineMessages({
    FNOLPAPageTitle: {
        id: 'wmic.fnol-page.pa.title',
        defaultMessage: 'Create a Claim Report - Automobile'
    },
    FNOLHOPageTitle: {
        id: 'wmic.fnol-page.ho.title',
        defaultMessage: 'Create a Claim Report - Residential'
    },
    FNOLPagePolicyNumberRequired: {
        id: 'wmic.fnol-page.policy-number-required',
        defaultMessage: '&lt;strong&gt;A Wawanesa Policy Number is required to report a claim online. If you do not have our insured\'s policy number, please call 888-WAWA-CLAIM (929-2252) to report a claim.&lt;/strong&gt;'
    },
    FNOLPAPageToggleLabel: {
        id: 'wmic.fnol-page.pa.toggle-label',
        defaultMessage: 'Is this claim for auto glass only?'
    },
    FNOLPAPageAutoGlassWarning: {
        id: 'wmic.fnol-page.pa.auto-glass-warning',
        defaultMessage: '&lt;strong&gt;Our new auto glass claim vendor, Safelite, will take care of an auto glass claim for you. Please click on Create Auto Glass Claim link provided on this screen.&lt;/strong&gt;'
    },
    FNOLFormRequiredField: {
        id: 'wmic.fnol-page.form-required-field',
        defaultMessage: 'indicates a required field'
    },
    FNOLPAFormContinue: {
        id: 'wmic.fnol-page.continue',
        defaultMessage: 'Continue'
    },
    FNOLContinueTrackButtonIdentifier: {
        id: 'wmic.fnol-page.tracking.continue',
        defaultMessage: 'FNOL - {claimType} - {state} - Continue'
    },
    FNOLPAFormFieldRequired: {
        id: 'wmic.fnol-page.pa.phone-required',
        defaultMessage: 'Fill out at least 1 phone number'
    },
    FNOLPAFormInputRequired: {
        id: 'wmic.fnol-page.pa.field-required',
        defaultMessage: 'Field required'
    },
    FNOLPAFormToggleRequired: {
        id: 'wmic.fnol-page.pa.toggle-required',
        defaultMessage: 'Field required, select an option.'
    },
    FNOLPAFormDateFormat: {
        id: 'wmic.fnol-page.pa.date-format',
        defaultMessage: 'Invalid date format'
    },
    FNOLPAFormTimeFormat: {
        id: 'wmic.fnol-page.pa.time-format',
        defaultMessage: 'Invalid time format'
    },
    FNOLErrorMessage: {
        id: 'wmic.fnol-page.error',
        defaultMessage: "The form has errors, please check those before proceed."
    }
})
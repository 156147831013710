// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__PoliciesTable__policyLinkContainer{padding-bottom:0;text-decoration:none}.app__PoliciesTable__currencyContainer div[class*=fieldComponent]{padding-bottom:0}", "",{"version":3,"sources":["webpack://./node_modules/gw-capability-billing-react/PoliciesTable/PoliciesTable.module.scss"],"names":[],"mappings":"AAAA,yCACI,gBAAA,CACA,oBAAA,CAIA,kEACI,gBAAA","sourcesContent":[".policyLinkContainer {\n    padding-bottom: 0;\n    text-decoration: none;\n}\n\n.currencyContainer {\n    div[class*='fieldComponent'] {\n        padding-bottom: 0;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"policyLinkContainer": "app__PoliciesTable__policyLinkContainer",
	"currencyContainer": "app__PoliciesTable__currencyContainer"
};
export default ___CSS_LOADER_EXPORT___;

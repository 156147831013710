/**
 * customer definitions for any added errors outside of Underwriting errors, validation errors etc
 * New errors should be added as customerErrors
 * @typedef {Array<customerError>} customerErrors
 */

/**
 * Definition of a customerError. 
 * @typedef {Object} customerError
 * @property {String} customerIssueName the name of the error defined
 * @property {function} customerMappingFunction function used to map the error response with
*/


module.exports = {
    /**
    *  define custom Error Levels like so:
    *  LEVEL_INFO: 'LEVEL_INFO'
    *  */
    ErrorLevel: {
    },
    /**
*  define custom Error types like so:
*  QUOTE_FAILED: 'QUOTE_FAILED
*  */
    ErrorType: {
    },
    /**
*  define custom underwriting blocking levels like so:
*  BLOCKS_QUOTE_FIRSTPASS: 'BLOCKS_QUOTE_FIRSTPASS
*  */
    UWBlockingPoint: {

    },
    /**
 * customerErrors is an array used to allow customised error definitions to be passed through 
 * and executed on the frontend without modifying the Edge Validation source.
 * customerError is defined above. 
 * The customerMappingFunction defined will be mapped against edge issues passed to the frontend, similar to how
 * Underwriting and Validation issues are handled. 
 * A sample would look like so, where "apiIssues" is a customer-added edge issue.
 *  {
        customerIssueName: 'apiIssues',
        customerMappingFunction: (issue) => {
            const {
                relatedEntity,
                apiError,
                flowStepId,
            } = issue;
            const {
                publicID,

            } = relatedEntity;
            return {
                id: `${publicID} - ${apiError}`,
                type: 'CONNECTION_FAILED',
                level: 'LEVEL_INFO',
                validationStep: flowStepId,
                isHidden: false,
            }
        }
    }
 */
    customerErrors: [
    ]

};
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__WMICBoundPolicyChangePA__wmic-bound-policy-container{padding:0}.app__WMICBoundPolicyChangePA__wmic-loading-quote-title{font-weight:600!important}.app__WMICBoundPolicyChangePA__wmic-loader-div{padding:25px 0}.app__WMICBoundPolicyChangePA__wmic-bound-change-loading{padding:5rem}.app__WMICBoundPolicyChangePA__wmicBoundSubtitle{font-size:2rem!important;font-weight:var(--GW-FONT-WEIGHT-BOLD)!important}", "",{"version":3,"sources":["webpack://./../common/capabilities-react/gw-capability-policychange-common-react/components/WMICBoundPolicyChangePA/WMICBoundPolicyChangePA.module.scss"],"names":[],"mappings":"AAKA,2DACE,SAAA,CAGF,wDACE,yBAAA,CAGF,+CACE,cAAA,CAGF,yDACE,YAAA,CAGF,iDACE,wBAAA,CACA,gDAAA","sourcesContent":["@import \"~@jutro/theme/assets/sass/helpers\";\n@import \"~wmic-amp-digital-theme-styles/sass/base/config/colors.scss\";\n@import \"~wmic-amp-digital-theme-styles/sass/customer-colors.scss\";\n@import \"~wmic-amp-digital-theme-styles/sass/customer-variables.scss\";\n\n.wmic-bound-policy-container {\n  padding: 0;\n}\n\n.wmic-loading-quote-title {\n  font-weight: 600 !important;\n}\n\n.wmic-loader-div {\n  padding: 25px 0px;\n}\n\n.wmic-bound-change-loading {\n  padding: 5rem;\n}\n\n.wmicBoundSubtitle {\n  font-size: 2rem !important;\n  font-weight: var(--GW-FONT-WEIGHT-BOLD) !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wmic-bound-policy-container": "app__WMICBoundPolicyChangePA__wmic-bound-policy-container",
	"wmic-loading-quote-title": "app__WMICBoundPolicyChangePA__wmic-loading-quote-title",
	"wmic-loader-div": "app__WMICBoundPolicyChangePA__wmic-loader-div",
	"wmic-bound-change-loading": "app__WMICBoundPolicyChangePA__wmic-bound-change-loading",
	"wmicBoundSubtitle": "app__WMICBoundPolicyChangePA__wmicBoundSubtitle"
};
export default ___CSS_LOADER_EXPORT___;

import React, { useContext } from 'react';
import { MetadataContent } from '@jutro/legacy/uiconfig';
import { useHistory } from 'react-router-dom';
import { WMICButton } from 'wmic-components-platform-react';

import FurtherAssistanceComponent from '../../common/FurtherAssistance/FurtherAssistance';
import metadata from './ExpiredRegistrationTokenDialog.metadata.json5';
import './ExpiredRegistrationTokenDialog.messages';

function ExpiredRegistrationTokenDialog(props) {
    const history = useHistory();

    const submitForm = () => {
        history.push('/enrollment/registration');
    }

    const overrideProps = {};

    const resolvers = {
        resolveCallbackMap: {
            submitForm: submitForm
        },
        resolveComponentMap: {
            furtherassistancecomponent: FurtherAssistanceComponent,
            WMICButton
        }
    };

    return (
        <MetadataContent
            uiProps={metadata.pageContent}
            overrideProps={overrideProps}
            {...resolvers} />
    );
}

export default ExpiredRegistrationTokenDialog;
import React from 'react';
import messages from './WMICEnrollmentPageFooter.messages';
import { useTranslator } from '@jutro/locale';
import { CONSTANTS } from 'wmic-portals-utils-js';

function WMICEnrollmentPageFooter() {
    const translator = useTranslator();

    const currentYear = new Date().getFullYear();

    return (
        <footer className="amp-registration-footer pt-6 pb-6">
            <div className="ww-footer-centered mb-5">
                <div className="copy-policy-links">
                    <div className="is-size-5" tabIndex={'0'}>{translator(messages.enrollmentCopyright, {currentYear}) } </div>
                    <div className="is-size-5 pb-3">
                        <a href={`${CONSTANTS.WAWANESA_URL}/home/amp/privacy-policy.html`}>{translator(messages.privacyPolicy)}</a> | <a href={`${CONSTANTS.WAWANESA_URL}/home/amp/terms-of-use.html`}>{translator(messages.termsOfUse)}</a>
                    </div>
                </div>

                <p className="is-size-5 pt-2" tabIndex={'0'}>{translator(messages.trademarkInfo)}</p>
            </div>       

        </footer>
    )
}

export default WMICEnrollmentPageFooter;
import messages from './Models.messages';

export default class WMICEmqLoginModel {
    constructor({
        policyNumber, lastName
    }) {
        this.policyNumber = policyNumber;
        this.lastName = lastName;
    }

    valid() {
        const required = [this.policyNumber, this.lastName];
        return required.every((v) => v);
    }

    toHTML() {
        return `<span>
        Policy Number: ${this.policyNumber} <br/>
        Last Name: ${this.lastName} <br/>
        </span>`;
    }

    toString(translator) {
        if (!this.valid()) {
            throw new Error(translator(messages.LoginModelRequired));
        }

        return `Policy Number: ${this.policyNumber}
        Last Name: ${this.lastName}`;
    }
}

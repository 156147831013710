import { FNOLConstants } from 'wmic-capability-fnol-react';

export default class BaseYourInformationModel {
    constructor({ relationToInsured, firstName, lastName, phoneNumber, businessPhoneNumber, businessPhoneNumberExt,
        cellPhoneNumber, email, companyName, fileNumber }) {
        this.relationToInsured = relationToInsured;
        this.firstName = firstName;
        this.lastName = lastName;
        this.phoneNumber = phoneNumber || '';
        this.businessPhoneNumber = businessPhoneNumber || '';
        this.businessPhoneNumberExt = businessPhoneNumberExt || '';
        this.cellPhoneNumber = cellPhoneNumber || '';
        this.email = email || '';
        this.companyName = companyName;
        this.fileNumber = fileNumber || '';
    }

    getType() {
        throw new Error('getType not implemented');
    }

    toString() {
        if (!this.isValid()) {
            throw new Error('Missing required fields.');
        }

        let relationship;

        switch (this.getType()) {
            case FNOLConstants.CLAIM_TYPE.AUTO:
                relationship = BaseYourInformationModel.PA_RELATIONSHIP[this.relationToInsured];
                break;
            case FNOLConstants.CLAIM_TYPE.RESIDENTIAL:
                relationship = BaseYourInformationModel.HO_RELATIONSHIP[this.relationToInsured];
                break;
            default:
                break;
        }

        let result = `<ReportingParty>
    <ReportingPartyRelationToInsured>${relationship}</ReportingPartyRelationToInsured>
    <FirstName>${this.firstName}</FirstName>
    <LastName>${this.lastName}</LastName>
    <PhoneNumber>${this.phoneNumber}</PhoneNumber>
    <BusinessPhoneNumber>${this.businessPhoneNumber}</BusinessPhoneNumber>
    <BusinessPhoneNumberExt>${this.businessPhoneNumberExt}</BusinessPhoneNumberExt>
    <CellPhoneNumber>${this.cellPhoneNumber}</CellPhoneNumber>
    <EmailAddress>${this.email}</EmailAddress>
    <WhoIsReporting></WhoIsReporting>
`;

        if (BaseYourInformationModel.isCompany(this.relationToInsured)) {
            result +=
                `    <CompanyInfo>
        <CompanyName>${this.companyName}</CompanyName>
        <FileNumber>${this.fileNumber}</FileNumber>
    </CompanyInfo>
`;
        }
        result += '</ReportingParty>';

        return result;
    }

    static isCompany(relationToInsured) {
        return !['INSURED', 'OTHER_PARTY', 'CLAIM_REPORTING_SERVICE'].includes(relationToInsured);
    }

    isValid() {
        const required = [this.relationToInsured, this.firstName, this.lastName];
        const phones = [this.phoneNumber, this.businessPhoneNumber, this.cellPhoneNumber];
        return required.every((v) => v) && phones.some((v) => v);
    }
}

BaseYourInformationModel.PA_RELATIONSHIP = {
    INSURED: 'Insured',
    OTHER_PARTY: 'Other Party',
    LIENHOLDER: 'Lienholder',
    CLAIMANT_CARRIER: 'Claimant Carrier',
    ATTORNEY: 'Attorney',
    CLAIM_REPORTING_SERVICE: 'Claim Reporting Service'
};

BaseYourInformationModel.PA_RELATIONSHIP_OPTIONS = [
    {
        "code":"INSURED",
        "displayName": {
            "id": "wmic.fnol-page.your-information.dropdown.wawanesa-policy-holder",
            "defaultMessage": "Wawanesa Policy Holder"
        }
    },
    {
        "id":"OtherParty",
        "code":"OTHER_PARTY",
        "displayName": {
            "id": "wmic.fnol-page.your-information.dropdown.other-party",
            "defaultMessage": "Other Party Involved In The Accident"
        }
    },
    {
        "id":"Lienholder",
        "code":"LIENHOLDER",
        "displayName": {
            "id": "wmic.fnol-page.your-information.dropdown.lienholder",
            "defaultMessage": "Lienholder"
        }
    },
    {
        "id":"AnotherInsuranceCompany",
        "code":"CLAIMANT_CARRIER",
        "displayName": {
            "id": "wmic.fnol-page.your-information.dropdown.another-insurance-company",
            "defaultMessage": "Another Insurance Company"
        }
    },
    {
        "id":"Attorney",
        "code":"ATTORNEY",
        "displayName": {
            "id": "wmic.fnol-page.your-information.dropdown.attorney",
            "defaultMessage": "Attorney"
        }
    }
]

BaseYourInformationModel.HO_RELATIONSHIP = {
    INSURED: 'Insured',
    MORTGAGEE: 'Mortgagee',
    CLAIMANT_CARRIER: 'Claimant Carrier',
    ATTORNEY: 'Attorney',
    CLAIM_REPORTING_SERVICE: 'Claim Reporting Service'
};

BaseYourInformationModel.HO_RELATIONSHIP_OPTIONS = [
    {
        "id":"WawanesaPolicyHolder",
        "code":"INSURED",
        "displayName": {
            "id": "wmic.fnol-page.your-information.dropdown.wawanesa-policy-holder",
            "defaultMessage": "Wawanesa Policy Holder"
        }
    },
    {
        "id":"Mortgagee",
        "code":"MORTGAGEE",
        "displayName": {
            "id": "wmic.fnol-page.your-information.dropdown.mortgagee",
            "defaultMessage": "Mortgagee"
        }
    },
    {
        "id":"AnotherInsuranceCompany",
        "code":"CLAIMANT_CARRIER",
        "displayName": {
            "id": "wmic.fnol-page.your-information.dropdown.another-insurance-company",
            "defaultMessage": "Another Insurance Company"
        }
    },
    {
        "id":"Attorney",
        "code":"ATTORNEY",
        "displayName": {
            "id": "wmic.fnol-page.your-information.dropdown.attorney",
            "defaultMessage": "Attorney"
        }
    }
];
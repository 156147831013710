// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__WMICLink__wmic-link:focus{box-shadow:none;outline:none}.app__WMICLink__wmic-link:focus .app__WMICLink__wmic-link-text{text-decoration:none}.app__WMICLink__wmic-link:focus-visible{box-shadow:0 0 0 2px var(--GW-FOCUS-COLOR-LIGHT);outline:none}.app__WMICLink__wmic-link:focus-visible .app__WMICLink__wmic-link-text{text-decoration:underline}", "",{"version":3,"sources":["webpack://./node_modules/wmic-components-amp-common-react/WMICLink/WMICLink.module.scss"],"names":[],"mappings":"AAGI,gCAEI,eAAA,CADA,YACA,CAEJ,+DACI,oBAAA,CAEJ,wCAEI,gDAAA,CADA,YACA,CAEJ,uEACI,yBAAA","sourcesContent":["@import \"~wmic-amp-digital-theme-styles/sass/app/portal-specific/styles/sass/portal-variables.scss\";\n\n.wmic-link {\n    &:focus {\n        outline: none;\n        box-shadow: none;\n    }\n    &:focus .wmic-link-text {\n        text-decoration: none;\n    }\n    &:focus-visible {\n        outline: none;\n        box-shadow: 0 0 0 2px var(--GW-FOCUS-COLOR-LIGHT);\n    }\n    &:focus-visible .wmic-link-text {\n        text-decoration: underline;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wmic-link": "app__WMICLink__wmic-link",
	"wmic-link-text": "app__WMICLink__wmic-link-text"
};
export default ___CSS_LOADER_EXPORT___;

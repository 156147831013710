import React, { useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter, useHistory } from 'react-router-dom';
import { MetadataContent } from '@jutro/legacy/uiconfig';
import { useTranslator } from '@jutro/locale';
import CONSTANTS from "wmic-portals-utils-js/StringConstants";
import _ from 'lodash';
import cx from 'classnames';
import appConfig from 'app-config';
import { 
    WMICFnolStateService, 
    WMICFnolSharedDataService, 
    WMICFnolUtilsService, 
    BaseYourInformationModel, 
    WMICFnolService
} from 'wmic-capability-fnol';
import { FNOLConstants, FNOLDisclaimer } from 'wmic-capability-fnol-react';
import { WMICReCaptchaService } from 'wmic-portals-recaptcha-js';
import { WMICButton } from 'wmic-components-platform-react';
import { WMICLoader } from 'gw-components-platform-react';
import metadata from './WMICFNOLPAReview.metadata.json5';
import messages from './WMICFNOLPAReview.messages.js';
import styles from './WMICFNOLPAReview.module.scss'


function WMICFNOLPAReview({ match, variant }) {
    const [trySubmit, setTrySubmit] = useState(false)
    const claimType = FNOLConstants.CLAIM_TYPE.AUTO;
    const stateParam = _.get(match, 'params.state');
    const translator = useTranslator();
    const history = useHistory();
    const { serviceUrls } = appConfig;
    const state = WMICFnolStateService.getState();

    const insuranceOptionMap = {
        INSURED: 'FNOLPAReviewInsuredOptionInsured',
        OTHER_PARTY: 'FNOLPAReviewInsuredOptionOtherParty',
        LIENHOLDER: 'FNOLPAReviewInsuredOptionLienholder',
        CLAIMANT_CARRIER: 'FNOLPAReviewInsuredOptionAnotherInsuranceCompany',
        ATTORNEY: 'FNOLPAReviewInsuredOptionAttorney',
    };

    useEffect(() => {
        if (variant === 'page' && history.location?.state?.previousStep !== FNOLConstants.FLOW_STEPS.START) {
            WMICFnolStateService.clearState();
            history.push(`/${stateParam}/${claimType}-claim`);
            return;
        }

        if (state) {
            WMICFnolUtilsService.copyAttributes(state, WMICFnolSharedDataService);
        }
        
        switch (stateParam) {
            case CONSTANTS.STATE.CA_LONG:
                break;
            case CONSTANTS.STATE.OR_LONG:
                break;
            default:
                history.push('/home');
                break;
        };
        if (!WMICFnolSharedDataService.driverOfInsuredVehicle.address.stateProvince) {
            WMICFnolSharedDataService.driverOfInsuredVehicle.address.stateProvince = stateParam;
        }
    }, [stateParam, history, state])

    const countryOptions = WMICFnolUtilsService.getSelectCanadaUSA();
    const stateOptions = WMICFnolUtilsService.getSelectState();

    const getCountry = (country) => {
        const value = countryOptions.find((v) => v.code === country);
        return value.displayName.defaultMessage;
    }
        
    const getState = (stateProvince) => {
        const value = stateOptions.find((v) => v.code === stateProvince);
        return !value ? '' : value.displayName.defaultMessage;
    }

    const getDriversLicense = (item) => {
        let value = '';
        if (item.driverLicNo) {
            value  = item.driverLicNo    
        } else  if (item.driversLicenseNo){
            value = item.driversLicenseNo
        }
        else {
            value = WMICFnolSharedDataService.driverOfInsuredVehicle.driversLicenseNo;
        }        
        return value;
    }

    const formatBusinessPhoneNumber = (item) => {
        let value = '';
        if (item.businessPhoneNumberExt) {
            value = `${item.businessPhoneNumber} ext. ${item.businessPhoneNumberExt}`
        } else {
            value = item.businessPhoneNumber
        }
        return value;
    }
    
    const getGenericInformationSection = (item, sectionTitle = false, num = 0) => ({
        sectionTitle: sectionTitle && translator(sectionTitle, {num}),
        list: [
            { label: translator(messages.FNOLReviewFirstName), value: item.firstName },
            { label: translator(messages.FNOLReviewLastName), value: item.lastName },
            { label: translator(messages.FNOLReviewStreet), value: item.address.street },
            { label: translator(messages.FNOLReviewCity), value: item.address.city },
            { label: translator(messages.FNOLReviewCountry), value: getCountry(item.address.country) },
            { label: translator(messages.FNOLReviewState), value: item.address.state ? getState(item.address.state) : getState(item.address.stateProvince)  },
            { label: translator(messages.FNOLReviewZipcode), value: item.address.postalCode },
            { label: translator(messages.FNOLReviewPhoneNumber), value: item.phoneNumber },
            { label: translator(messages.FNOLReviewBusinessPhoneNumber), value: formatBusinessPhoneNumber(item) },
            { label: translator(messages.FNOLReviewCellPhoneNumber), value: item.cellPhoneNumber },
            { label: translator(messages.FNOLReviewDriversLicenseNumber), value: getDriversLicense(item) },
            { label: translator(messages.FNOLReviewInjuriesRecieved), value: `${item.injuries ? item.injuries : WMICFnolSharedDataService.driverOfInsuredVehicle.injuries}` }
        ]
    });

    const getGenericVehicleSection = (vehicle, sectionTitle = false, commentsLabel, num = 0) => ({
        sectionTitle: sectionTitle && translator(sectionTitle, {num}),
        list: [
            { label: translator(messages.FNOLReviewVehicleYear), value: vehicle.vehicleYear },
            { label: translator(messages.FNOLReviewVehicleMake), value: vehicle.vehicleMake },
            { label: translator(messages.FNOLReviewVehicleModel), value: vehicle.vehicleModel },
            { label: translator(messages.FNOLReviewVehicleColor), value: vehicle.vehicleColor },
            { label: translator(messages.FNOLReviewVehicleLicensePlate), value: vehicle.licensePlateNumber },
            { label: translator(messages.FNOLReviewVehicleVIN), value: vehicle.VIN },
            { label: translator(commentsLabel), value: vehicle.vehicleDamages }
        ]
    });

    const getYourInfo = () => {
        const yourInfo = {
            sectionTitle: false,
            list: [
                { label: '', value: translator(messages[insuranceOptionMap[state.reportingParty.insuredOption]]) },
                { label: translator(messages.FNOLReviewFirstName), value: state.reportingParty.firstName },
                { label: translator(messages.FNOLReviewLastName), value: state.reportingParty.lastName },
                { label: translator(messages.FNOLReviewPhoneNumber), value: state.reportingParty.phoneNumber },
                { label: translator(messages.FNOLReviewBusinessPhoneNumber), value: formatBusinessPhoneNumber(state.reportingParty) },
                { label: translator(messages.FNOLReviewCellPhoneNumber), value: state.reportingParty.cellPhoneNumber },
                { label: translator(messages.FNOLReviewEmailAddress), value: state.reportingParty.emailAddress }
            ]
        };

        if (BaseYourInformationModel.isCompany(state.reportingParty.insuredOption))
            yourInfo.list.push(...[
                { label: translator(messages.FNOLReviewCompanyName), value: state.reportingParty.companyInfo.companyName },
                { label: translator(messages.FNOLReviewFileNumber), value: state.reportingParty.companyInfo.fileNumber },
            ])

        return [yourInfo];
    }


    const getLossInformation = () => [{
        sectionTitle: false,
        list: [
            { label: translator(messages.FNOLReviewWawanesaPolicyNumber), value: state.lossInformation.policyNumber },
            { label: translator(messages.FNOLReviewDateOfLoss), value: state.lossInformation.dateOfLoss },
            { label: translator(messages.FNOLReviewTimeOfLoss), value: state.lossInformation.timeOfLoss },
            { label: translator(messages.FNOLReviewStreetFreewayLocation), value: state.lossInformation.lossLocation.street },
            { label: translator(messages.FNOLReviewCity), value: state.lossInformation.lossLocation.city },
            { label: translator(messages.FNOLReviewCountry), value: getCountry(state.lossInformation.lossLocation.country) },
            { label: translator(messages.FNOLReviewState), value: getState(state.lossInformation.lossLocation.stateProvince) },
            { label: translator(messages.FNOLReviewTellUsWhatHappened), value: state.lossInformation.lossDescription },
        ]
    }];

    const getPoiceReportInformation = () => [{
        sectionTitle: false,
        list: [
            { label: translator(messages.FNOLReviewPoliceReportDeptNameLabel), value: state.policeInformation.policeDeptName },
            { label: translator(messages.FNOLReviewPoliceReportReportNumberLabel), value: state.policeInformation.reportNumber },
            { label: translator(messages.FNOLReviewPoliceReportContactLabel), value: state.policeInformation.policeDeptContactInfo },
        ]
    }];

    const getWawanesaPolicyholderInformation = () => 
        [getGenericInformationSection(state.insured)];

    const getWawanesaInsuredVehicleInformation = () => 
        [getGenericVehicleSection(state.insured.vehicle, false, messages.FNOLReviewInsuredVehicleDamage, 0)];

    const getOtherPartiesInformation = () => {
        if (!state.otherPartiesSelected.value) return [];
        const formattedData = [];

        state.otherParties.list.forEach((otherParty, index) => {
            formattedData.push(getGenericInformationSection(otherParty, messages.FNOLReviewOtherPartiesInformationTitle, index + 1))
            formattedData.push(getGenericVehicleSection(otherParty.vehicle, messages.FNOLReviewOtherPartiesVehicleInformationTitle, messages.FNOLReviewVehicleDamages, index + 1))
        });

        return formattedData;
    }

    const getPassengersOrWitnessesInformation = (type = 'passengers') => 
        state[`${type}Selected`].value 
            ? state[type].list.map((passenger, index) => getGenericInformationSection(passenger, messages[`FNOLReview${type}Title`], index + 1)) 
            : [];

    const handleSave = async () => {
        setTrySubmit(true);
        try {
            const token = await WMICReCaptchaService.fetchReCaptchaToken('submitClaim')
            const fnolHeaders = {
                'x-forwarded-host': serviceUrls.xForwardedHost,
                'rcToken': token
            };

            const trackingNumber = await WMICFnolService.submitClaim(state, fnolHeaders, claimType);
            const locationState = { ...history.location.state };
            delete locationState.previousStep;
            history.replace(locationState);
            history.push({
                pathname: `/${stateParam}/${claimType}/${trackingNumber}/success`,
                state: {
                    success: true
                }
            });

        } catch (err) {
            history.push(FNOLConstants.FNOL_TECHNICAL_ERORR_PATH);
        } finally {
            setTrySubmit(false);
        }
    };

    const handleEdit = useCallback(() => {
        history.push({pathname: `/${stateParam}/${claimType}-claim`});
    }, [history, stateParam]);

    
    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            WMICButton,
            WMICLoader,
            FNOLDisclaimer
        },
        resolveCallbackMap: {
            handleSave,
            handleEdit
        }
    };

    const overrideProps = {
        FNOLPAReviewContainer: {
            visible: !trySubmit
        },
        FNOLPALoader: {
            visible: trySubmit
        },
        FNOLPAReviewContentContainer: {
            className: cx(variant !== 'component' ? 'ww-main-content':  '', 'ww-content-limit ww-main-content-responsive fnol-margin-bottom')
        },
        FNOLPATitleComponentCardTitle: {
            visible: variant === 'component'
        },
        FNOLPATitlePageCardTitle: {
            visible: variant !== 'component'
        },
        FNOLPAContinueContainer: {
            visible: variant !== 'component'
        },
        FNOLPAReviewContainerYourInfo: {
            title: translator(messages.FNOLPAReviewYourInformationTitle),
            data: getYourInfo()
        },
        FNOLPAReviewContainerLossInfo: {
            title: translator(messages.FNOLPAReviewLossInformationTitle),
            data: getLossInformation()
        },
        FNOLPAReviewWawanesaPolicyholderInfo: {
            title: translator(messages.FNOLPAReviewWawanesaPolicyholderInformationTitle),
            data: getWawanesaPolicyholderInformation()
        },
        FNOLPAOtherPartyInfo: {
            title: translator(messages.FNOLPAReviewOtherPartyInformationTitle),
            data: [getGenericInformationSection(state.otherPartyClaimant)],
            visible: state.reportingParty.insuredOption === CONSTANTS.FNOL.YOUR_INFORMATION.OTHER_PARTY
        },
        FNOLPAOtherPartyVehicleInfo: {
            title: translator(messages.FNOLPAReviewOtherPartyVehicleInformationTitle),
            data: [getGenericVehicleSection(state.otherPartyClaimant.vehicle)],
            visible: state.reportingParty.insuredOption === CONSTANTS.FNOL.YOUR_INFORMATION.OTHER_PARTY
        },
        FNOLPAReviewWawanesaInsureVehicleInfo: {
            title: translator(messages.FNOLPAReviewWawanesaInsuredVehicleInformationTitle),
            data: getWawanesaInsuredVehicleInformation()
        },
        FNOLPAReviewPoliceReportInfo: {
            title: translator(messages.FNOLPAReviewPoliceReportTitle),
            data: getPoiceReportInformation(),
            visible: state.policeReportSelected.value
        },
        FNOLPAReviewOtherPartiesContainer: {
            title: translator(messages.FNOLPAReviewOtherPartiesTitle),
            data: getOtherPartiesInformation(),
            noContentWarningMessage: translator(messages.FNOLReviewNoOtherPartiesWarning)
        },
        FNOLPAReviewPassengersContainer: {
            title: translator(messages.FNOLReviewPassengersWitnessesTitle),
            data: getPassengersOrWitnessesInformation('passengers'),
            noContentWarningMessage: translator(messages.FNOLReviewNoPassengersWarning)
        },
        FNOLPAReviewWitnessesContainer: {
            title: '',
            data: getPassengersOrWitnessesInformation('witnesses'),
            noContentWarningMessage: translator(messages.FNOLReviewNoWitnessesWarning)
        },
        FNOLPAEditButton: {
            trackButtonIdentifier: translator(messages.FNOLPAEditButtonTrackButtonIdentifier, {claimType: claimType, state: stateParam})
        },
        FNOLPASubmitButton: {
            trackButtonIdentifier: translator(messages.FNOLPASubmitButtonTrackButtonIdentifier, {claimType: claimType, state: stateParam})
        },
        FNOLPADisclaimer: {
            showCaliforniaLawDisclaimer: stateParam === CONSTANTS.STATE.CA_LONG
        }
    }

    return (
        <div>
            <MetadataContent
                uiProps={metadata.pageContent}
                overrideProps={overrideProps}
                {...resolvers} />
        </div>
    );
}

WMICFNOLPAReview.propTypes = {
    variant: PropTypes.oneOf(['component', 'page'])
};

WMICFNOLPAReview.defaultProps = {
    variant: 'page'
};

export default withRouter(WMICFNOLPAReview);


import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { MetadataForm } from '@jutro/legacy/uiconfig';
import _ from 'lodash';
import CONSTANTS from 'wmic-portals-utils-js/StringConstants';
import { FNOLConstants } from 'wmic-capability-fnol-react';
import { WMICCheckbox } from 'wmic-components-platform-react';
import { useTranslator } from '@jutro/locale';

import metadata from './FNOLOtherPartiesComponent.metadata.json5';
import styles from '../FNOLStyle.module.scss';
import messages from './FNOLOtherPartiesComponent.messages.js';


function FNOLOtherPartiesComponent(props){
    const { 
        WMICFnolSharedDataService,
        WMICFnolUtilsService, 
        state,
        validateFields,
    } = props;
    const [otherPartiesInformation, setOtherPartiesInformation] = useState(WMICFnolSharedDataService.otherParties);
    const [toggleValue, setToggleValue] = useState(WMICFnolSharedDataService.otherPartiesSelected.value);
    const [count, setCount] = useState(1);
    const translator = useTranslator();

    const handleUpdateList = useCallback(() => {
        const listLength = otherPartiesInformation.list.length;
        let updatedList = [...otherPartiesInformation.list];

        if (count !== listLength) {
            if (count > listLength) {
                updatedList.push(...Array.from({length: count - listLength}, () => WMICFnolUtilsService.otherParty(state)))
            } else  {
                otherPartiesInformation.list.splice(count);
                updatedList = otherPartiesInformation.list;
            }
        }
        

        setOtherPartiesInformation({
            ...otherPartiesInformation,
            list: updatedList
        });
        WMICFnolSharedDataService.otherParties.list = updatedList;
    }, [otherPartiesInformation, WMICFnolSharedDataService.otherParties, WMICFnolUtilsService.otherParty, count])


    useEffect(() => {
        handleUpdateList()
    }, [count, handleUpdateList])
    
    const onDropdownChange = useCallback((item) => {
        setCount(item);
    }, [setCount]);

    const onToggleChange = (value) => {
        setToggleValue(value)
        WMICFnolSharedDataService.otherPartiesSelected.value = value;
    };

    const onCheckboxChange = (value, index) => {
        setOtherPartiesInformation({
            ...otherPartiesInformation,
            list: otherPartiesInformation.list.map((item, i) => {
                if (i === index) {
                    item.unknownParty = !item.unknownParty;

                    if (value) {
                        item.firstName = 'Unknown';
                        item.lastName = 'Unknown';
                    }
                }
                return item
            })
        })
    }

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onToggleChange,
        },
    }

    const renderTabs = () => {
        return _.map(otherPartiesInformation.list, (item, index) => {
            const itemId = index + 1;
            return {
                id: `tab-${itemId}`,
                type: 'element',
                component: 'tab',
                componentProps: {
                    id: `other-parties-tab-${itemId}`,
                    heading: itemId,
                    showFrame: true,
                    className: 'fnolTab'
                },
                content: [
                    {
                        id: `FNOLOtherPartiesTabDescrption_tab${itemId}`,
                        type: 'element',
                        component: 'h4',
                        content: translator(messages.FNOLOtherPartiesDescription, {num: itemId})
                    },
                    {
                        id: `FNOLOtherPartiesCheckboxContainer_tab${itemId}`,
                        type: 'element',
                        component: 'div',
                        componentProps: {
                            className: styles.fnolCheckboxContainer
                        },
                        content: [
                            {
                                id: `FNOLOtherPartiesNoInfo_tab${itemId}`,
                                type: 'element',
                                component: WMICCheckbox,
                                componentProps: {
                                    className: styles.fnolCheckbox,
                                    value: item.unknownParty,
                                    labelPosition: 'left',
                                    label: {
                                        id: 'wmic.fnol-page.other-parties.missing-info-checkbox',
                                        defaultMessage: "I don't have the other party's information"
                                    },
                                    onValueChange: (value) => onCheckboxChange(value, index),
                                    path:`list[${index}].unknownParty`
                                } 
                            },
                        ]
                    },
                    {
                        id: "FNOLWitnessesFirstNameContainer",
                        type:"container",
                        component:"div",
                        componentProps: {
                            className:"ww-input--75 entry-item"
                        },
                        content: [
                            {
                                id: `FNOLOtherPartiesFirstName_tab${itemId}`,
                                type:"field",
                                component:"input",
                                componentProps: {
                                    value: item.firstName,
                                    labelClassName:"input-required",
                                    path:`list[${index}].firstName`,
                                    label: {
                                        id: "wmic.fnol-page.other-parties.first-name-label",
                                        defaultMessage: "First Name"
                                    },
                                    required: true,
                                    maxLength: 50
                                }
                            },
                            {
                                id: `FNOLOtherPartiesFirstNameError_tab${itemId}`,
                                type:"element",
                                component:"span",
                                componentProps: {
                                    className:"error",
                                    visible: validateFields('otherParties.list', 'tabset', false, 'firstName')

                                },
                                content:{
                                    id: "wmic.fnol-page.pa.field-required",
                                    defaultMessage: "Field required"
                                }
                            },
                        ]
                    },
                    {
                        id: `FNOLOtherPartiesLastName_tab${itemId}`,
                        type:"container",
                        component:"div",
                        componentProps: {
                            className:"ww-input--75 entry-item"
                        },
                        content: [
                            {
                                id: `FNOLOtherPartiesLastName_tab${itemId}`,
                                type:"field",
                                component:"input",
                                componentProps: {
                                    value: item.lastName,
                                    labelClassName:"input-required",
                                    path:`list[${index}].lastName`,
                                    label: {
                                        id: "wmic.fnol-page.other-parties.last-name-label",
                                        defaultMessage: "Last Name"
                                    },
                                    required: true,
                                    maxLength: 50
                                }
                            },
                            {
                                id: `FNOLOtherPartiesLasttNameError_tab${itemId}`,
                                type:"element",
                                component:"span",
                                componentProps: {
                                    className:"error",
                                    visible: validateFields('otherParties.list', 'tabset', false, 'lastName')
                                },
                                content:{
                                    id: "wmic.fnol-page.pa.field-required",
                                    defaultMessage: "Field required"
                                }
                            },
                        ]
                    },
                    {
                        id: `FNOLOtherPartiesAddress_tab${itemId}`,
                        type:"field",
                        component:"input",
                        componentProps: {
                            value: item.address,
                            className:"entry-item ww-input--75",
                            path:`list[${index}].address.street`,
                            label: {
                                id: "wmic.fnol-page.other-parties.address-label",
                                defaultMessage: "Address"
                            },
                            maxLength: 50
                        }
                    },
                    {
                        id: `FNOLOtherPartiesCity_tab${itemId}`,
                        type:"field",
                        component: "input",
                        componentProps: {
                            value: item.city,
                            className: "entry-item ww-input--75",
                            path: `list[${index}].address.city`,
                            label: {
                                id: "wmic.fnol-page.other-parties.city-label",
                                defaultMessage: "City"
                            },
                            maxLength: 50
                        }
                    },
                    {
                        id: `FNOLOtherPartiesCountryContainer_tab${itemId}`,
                        type: "container",
                        component:"div",
                        componentProps: {
                            className:"ww-input--75 entry-item"
                        },
                        content: [
                            {
                                id: `FNOLOtherPartiesCountry_tab${itemId}`,
                                type: "field",
                                component: "dropdownselect",
                                componentProps: {
                                    availableValues: WMICFnolUtilsService.getSelectCanadaUSA(),
                                    path: `list[${index}].address.country`,
                                    className: "wmic-fnol-dropdown",
                                    defaultValue: state,
                                    label: {
                                        id: "wmic.fnol-page.other-parties.country-label",
                                        defaultMessage: "Country"
                                    },
                                    internalClassNames: {
                                        option: "dropdownOption"
                                    }
                                }
                            }
                        ]
                    },
                    {
                        id: `FNOLOtherPartiesStateProvinceContainer_tab${itemId}`,
                        type: "container",
                        component:"div",
                        componentProps: {
                            className:"ww-input--75 entry-item"
                        },
                        content: [
                            {
                                id: `FNOLOtherPartiesStateProvince_tab${itemId}`,
                                type: "field",
                                component: "dropdownselect",
                                componentProps: {
                                    availableValues: WMICFnolUtilsService.getStatesShown(item),
                                    path: `list[${index}].address.state`,
                                    className: "wmic-fnol-dropdown",
                                    label: {
                                        id: "wmic.fnol-page.other-parties.state-province-label",
                                        defaultMessage: "State"
                                    },
                                    internalClassNames: {
                                        option: "dropdownOption"
                                    }
                                }
                            }
                        ]
                    },
                    {
                        id: `FNOLOtherPartiesZipCode_tab${itemId}`,
                        type:"field",
                        component: "inputmask",
                        componentProps: {
                            mask: "99999",
                            maskChar: "_",
                            alwaysShowMask: true,
                            value: item.postalCode,
                            className: "entry-item ww-input--75",
                            path: `list[${index}].address.postalCode`,
                            label: {
                                id: "wmic.fnol-page.other-parties.zipcode-label",
                                defaultMessage: "Zip Code"
                            }   
                        }
                    },
                    {
                        id: `FNOLOtherPartiesHomePhone_tab${itemId}`,
                        type:"field",
                        component: "inputmask",
                        componentProps: {
                            mask: "(999) 999-9999",
                            maskChar: "_",
                            alwaysShowMask: true,
                            value: item.phoneNumber,
                            className: "entry-item ww-input--75",
                            path: `list[${index}].phoneNumber`,
                            label: {
                                id: "wmic.fnol-page.other-parties.home-phone-label",
                                defaultMessage: "Home Phone Number"
                            }   
                        }
                    },
                    {
                        id: `FNOLOtherPartiesBusinessPhone_tab${itemId}`,
                        type:"field",
                        component: "inputmask",
                        componentProps: {
                            mask: "(999) 999-9999",
                            maskChar: "_",
                            alwaysShowMask: true,
                            value: item.homeBusinessNumber,
                            className: "entry-item ww-input--75",
                            path: `list[${index}].businessPhoneNumber`,
                            label: {
                                id: "wmic.fnol-page.other-parties.business-phone-label",
                                defaultMessage: "Business Phone Number"
                            }   
                        }
                    },
                    {
                        id: `FNOLOtherPartiesExt_tab${itemId}`,
                        type:"field",
                        component: "inputmask",
                        componentProps: {
                            mask: "99999",
                            maskChar: "_",
                            alwaysShowMask: true,
                            registerValidation: () => _.noop,
                            value: item.ext,
                            className: "entry-item ww-input--75",
                            path: `list[${index}].businessPhoneNumberExt`,
                            label: {
                                id: "wmic.fnol-page.other-parties.ext-label",
                                defaultMessage: "Ext"
                            }   
                        }
                    },
                    {
                        id: `FNOLOtherPartiesCellPhone_tab${itemId}`,
                        type:"field",
                        component: "inputmask",
                        componentProps: {
                            mask: "(999) 999-9999",
                            maskChar: "_",
                            alwaysShowMask: true,
                            value: item.cellPhoneNumber,
                            className: "entry-item ww-input--75",
                            path: `list[${index}].cellPhoneNumber`,
                            label: {
                                id: "wmic.fnol-page.other-parties.cell-phone-label",
                                defaultMessage: "Cell Phone Number"
                            }   
                        }
                    },
                    {
                        id: `FNOLOtherPartiesDriversLicense_tab${itemId}`,
                        type:"field",
                        component: "input",
                        componentProps: {
                            value: item.driversLicenseNo,
                            className: "entry-item ww-input--75",
                            path: `list[${index}].driversLicenseNo`,
                            label: {
                                id: "wmic.fnol-page.other-parties.drivers-license-label",
                                defaultMessage: "Drivers License Number"
                            },
                            maxLength: 20
                        }
                    },
                    // Tell us about any injuries or treatments received
                    {
                        id: `FNOLOtherPartiesInjuriesOrTreatments_tab${itemId}`,
                        type:"field",
                        component: "input",
                        componentProps: {
                            value: item.injuries,
                            className: "entry-item ww-input--75",
                            path: `list[${index}].injuries`,
                            label: {
                                id: "wmic.fnol-page.other-parties.injuries-or-treatments-label",
                                defaultMessage: "Tell us about any injuries or treatments received"
                            },
                            maxLength: 1333 
                        }
                    },
                    {
                        id: "FNOLOtherPartiesOtherVehicleTitle",
                        type: "element",
                        component: "h3",
                        content: translator(messages.FNOLOtherPartiesOtherVehicleTitle, {num: itemId})
                    },
                    {
                        id: `FNOLOtherPartiesVehicleYear_tab${itemId}`,
                        type:"field",
                        component: "inputmask",
                        componentProps: {
                            mask: "9999",
                            maskChar: "_",
                            alwaysShowMask: true,
                            value: item.vehicle.year,
                            className: "entry-item ww-input--75",
                            path: `list[${index}].vehicle.vehicleYear`,
                            label: {
                                id: "wmic.fnol-page.other-parties.vehicle-year-label",
                                defaultMessage: "Vehicle Year"
                            }   
                        }
                    },
                    {
                        id: `FNOLOtherPartiesVehicleMake_tab${itemId}`,
                        type:"field",
                        component: "input",
                        componentProps: {
                            value: item.vehicle.make,
                            className: "entry-item ww-input--75",
                            path: `list[${index}].vehicle.vehicleMake`,
                            label: {
                                id: "wmic.fnol-page.other-parties.vehicle-make-label",
                                defaultMessage: "Vehicle Make"
                            },
                            maxLength: 40  
                        }
                    },
                    {
                        id: `FNOLOtherPartiesVehicleModel_tab${itemId}`,
                        type:"field",
                        component: "input",
                        componentProps: {
                            value: item.vehicle.model,
                            className: "entry-item ww-input--75",
                            path: `list[${index}].vehicle.vehicleModel`,
                            label: {
                                id: "wmic.fnol-page.other-parties.vehicle-model-label",
                                defaultMessage: "Vehicle Model"
                            },
                            maxLength: 40
                        }
                    },
                    {
                        id: `FNOLOtherPartiesVehicleColor_tab${itemId}`,
                        type:"field",
                        component: "input",
                        componentProps: {
                            value: item.vehicle.color,
                            className: "entry-item ww-input--75",
                            path: `list[${index}].vehicle.vehicleColor`,
                            label: {
                                id: "wmic.fnol-page.other-parties.vehicle-color-label",
                                defaultMessage: "Vehicle Color"
                            },
                            maxLength: 40
                        }
                    },
                    {
                        id: `FNOLOtherPartiesLicensePlate_tab${itemId}`,
                        type:"field",
                        component: "input",
                        componentProps: {
                            value: item.vehicle.licensePlateNumber,
                            className: "entry-item ww-input--75",
                            path: `list[${index}].vehicle.licensePlateNumber`,
                            label: {
                                id: "wmic.fnol-page.other-parties.vehicle-license-plate-label",
                                defaultMessage: "License Plate Number"
                            },
                            maxLength: 7
                        }
                    },
                    {
                        id: `FNOLOtherPartiesVehicleVin_tab${itemId}`,
                        type:"field",
                        component: "input",
                        componentProps: {
                            value: item.vehicle.VIN,
                            className: "entry-item ww-input--75",
                            path: `list[${index}].vehicle.VIN`,
                            label: {
                                id: "wmic.fnol-page.other-parties.vehicle-vin-label",
                                defaultMessage: "VIN"
                            }   
                        }
                    },
                    {
                        id: `FNOLOtherPartiesVehicleComment_tab${itemId}`,
                        type:"field",
                        component: "textarea",
                        componentProps: {
                            value: item.vehicle.vehicleDamages,
                            className: "entry-item ww-input--75",
                            path: `list[${index}].vehicle.vehicleDamages`,
                            label: {
                                id: "wmic.fnol-page.other-parties.vehicle-damages-label",
                                defaultMessage: "Tell us about the extent and location of the damage on this vehicle"
                            }   
                        }
                    },
                ]
            };
        })
    }

    const setZipLabel = useCallback((country) => {
        return country === CONSTANTS.COUNTRY.US ? translator(messages.FNOLOtherPartiesFormZipCode)  : translator(messages.FNOLOtherPartiesFormPostalCode) 
    }, [translator])

    const zipOverrides = useMemo(() => {
        const overrides = _.get(otherPartiesInformation, 'list')
            .reduce((partial, otherParty, index) => ({
                ...partial,
                [`FNOLOtherPartiesZipCode_tab${index+1}`]: {
                    mask: FNOLConstants.setZipMask(otherParty?.address.country),
                    label: setZipLabel(otherParty?.address.country)
                }
            }), {});
        return overrides
    }, [otherPartiesInformation, setZipLabel])

    const overrideProps = {
        ...zipOverrides,
        FNOLOtherPartiesCount: {
            availableValues: FNOLConstants.AVAILABLE_COUNTS,
            value: count,
            onValueChange: onDropdownChange
        },
        FNOLOtherPartiesToggle: {
            value: toggleValue
        },
        FNOLOtherPartiesFormContainer: {
            visible: toggleValue === true
        },
        FNOLOtherPartiesSoloVehicleLoss: {
            visible: toggleValue === false
        },
        FNOLOtherPartiesTabSet: {
            activeTab: 'other-parties-tab-1',
            content: renderTabs(),
            className: styles.fnolTabSet
        },
        FNOLOtherPartiesToggleError: {
            visible: validateFields('otherPartiesSelected.value', 'toggle')
        }
    }

    return (
        <MetadataForm
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
            data={otherPartiesInformation}
            onDataChange={(value, path) => {
                const newData = {...otherPartiesInformation};
                _.set(newData, path, value)
                WMICFnolSharedDataService.otherPartiesInformation = newData
                setOtherPartiesInformation(newData);
                if (path.split('.').at(-1) === 'country') WMICFnolUtilsService.countryOptionChanged(path, value, state, otherPartiesInformation, setOtherPartiesInformation, WMICFnolSharedDataService.otherPartiesInformation);
            }} />
    );
}

export default FNOLOtherPartiesComponent
import { defineMessages } from 'react-intl';

export default defineMessages({
    EMQVehicleAccordionVIN: {
        id: 'wmic.emq.vehicle-accordion.vin',
        defaultMessage: 'VIN'
    },
    EMQVehicleAccordionNotComplete: {
        id: 'wmic.emq.vehicle-accordion.not-complete',
        defaultMessage: 'not complete'
    },
    EMQVehicleAccordionComplete: {
        id: 'wmic.emq.vehicle-accordion.complete',
        defaultMessage: 'complete'
    },
    EMQVehicleAccordionAnnualMileage: {
        id: 'wmic.emq.vehicle-accordion.annual-mileage',
        defaultMessage: 'Annual Mileage on your policy'
    },
    EMQVehicleAccordionPrimaryUsage: {
        id: 'wmic.emq.vehicle-accordion.primary-usage',
        defaultMessage: 'Primary Usage for this vehicle'
    },
    EMQVehicleAccordionSelect: {
        id: 'wmic.emq.vehicle-accordion.select',
        defaultMessage: 'Select'
    },
    EMQVehicleAccordionOdometerReading: {
        id: 'wmic.emq.vehicle-accordion.odometer-reading',
        defaultMessage: 'Current Odometer Reading'
    },
    EMQVehicleAccordionEstimatedMiles: {
        id: 'wmic.emq.vehicle-accordion.estimated-miles',
        defaultMessage: 'Estimated Miles to be driven over the next 12 months'
    },
    EMQVehicleAccordionDaysPerWeek: {
        id: 'wmic.emq.vehicle-accordion.days-per-week',
        defaultMessage: 'How many days per week do you drive to work / school?'
    },
    EMQVehicleAccordionHowFar: {
        id: 'wmic.emq.vehicle-accordion.how-far',
        defaultMessage: 'How far is your work / school one way?'
    },
    EMQVehicleAccordionMilesYear: {
        id: 'wmic.emq.vehicle-accordion.miles-year',
        defaultMessage: ' miles / year'
    },
});

import { defineMessages } from 'react-intl';

export default defineMessages({
    emqVehicleEstimateMilesTitle: {
        id: 'wmic.emq.vehicle-page.distance-calculation.title',
        defaultMessage: 'Need Help Calculating Distance?'
    },
    emqVehicleEstimateMilesOrigin: {
        id: 'wmic.emq.vehicle-page.distance-calculation.home-address',
        defaultMessage: 'Enter your home address'
    },
    emqVehicleEstimateMilesDestination: {
        id: 'wmic.emq.vehicle-page.distance-calculation.destination-address',
        defaultMessage: 'Enter your work / school Address'
    },
    emqVehicleEstimateMilesButton: {
        id: 'wmic.emq.vehicle-page.distance-calculation.button',
        defaultMessage: 'Calculate the distance'
    },
    emqVehicleEstimateRecalculateButton: {
        id: 'wmic.emq.vehicle-page.distance-recalculation.button',
        defaultMessage: 'Calculate a new distance'
    },
    emqVehicleEstimateResultTitle: {
        id: 'wmic.emq.vehicle-page.distance-calculation.result-title',
        defaultMessage: 'The distance, one way, is:'
    },
    emqVehicleEstimateResultNotFound: {
        id: 'wmic.emq.vehicle-page.distance-calculation.result-not-found',
        defaultMessage: 'The distance cannot be calculated.'
    },
    emqVehicleEstimateMiles: {
        id: 'wmic.emq.vehicle-page.distance-calculation.estimate-miles',
        defaultMessage: '{distance} Miles'
    },
    emqVehicleEstimateNoResult: {
        id: 'wmic.emq.vehicle-page.distance-calculation.no-result',
        defaultMessage: 'No results'
    },
    emqVehicleEstimateNotFound: {
        id: 'wmic.emq.vehicle-page.distance-calculation.not-found',
        defaultMessage: 'Not found'
    },
    emqVehicleEstimatePlaceholder: {
        id: 'wmic.emq.vehicle-page.distance-calculation.placeholder',
        defaultMessage: '123 Anywhere Drive, Los Angeles, CA'
    }
});

import moment from 'moment';
import { cloneDeep } from 'lodash';
import { getConfigValue, DEFAULT_HTTP_TIMEOUT } from '@jutro/config';
import { WMICFnolUtilsService, BaseYourInformationModel } from 'wmic-capability-fnol';
import CONSTANTS from 'wmic-portals-utils-js/StringConstants';
import { FNOLConstants } from 'wmic-capability-fnol-react';
import appConfig from 'app-config';
const { policyChangeSubmitConfig,serviceUrls } = appConfig;

const httpTimeout = getConfigValue('httpSettings.timeout', DEFAULT_HTTP_TIMEOUT);
const basicAuthHeader = window.btoa(`${policyChangeSubmitConfig.username}:${policyChangeSubmitConfig.password}`);
const baseHeaders = {
    'Authorization': `Basic ${basicAuthHeader}`,
    'Content-Type': 'application/xml; charset=utf-8',
};

const xmlClaimType = (claimType) => {
    if (claimType === FNOLConstants.CLAIM_TYPE.AUTO) {
        return FNOLConstants.CLAIM_TYPE_XML.AUTO;
    }
    return FNOLConstants.CLAIM_TYPE_XML.HOME;
};

const _getXMLRequest = (body, claimType) => {
    return `<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:ns="http://namespaces.wawanesa.com/services/claim/1.2">
   <soapenv:Header/>
   <soapenv:Body>
      <ns:SubmitClaim>
      <ClaimRequest>
        <Claim>
           ${WMICFnolUtilsService.OBJtoXML(body)}
        </Claim>
        <ClaimType>${xmlClaimType(claimType)}</ClaimType>
        <EmailTo>
            <address>USWebContentMgmt@wawanesa.com</address>
        </EmailTo>
        </ClaimRequest>
      </ns:SubmitClaim>
   </soapenv:Body>
</soapenv:Envelope>`;
};

// The endpoint expects these three items to be an array
// They have a list<array> child at the moment for rendering in Jutro metadata
// This function pushes the array up a level before it is parsed as XML and sent
const organiseFormData = (formData, claimType) => {
    const body = cloneDeep(formData)
    body.otherParties = body.otherParties.list;
    body.claimantCollection = body.otherParties;
    body.mortgagees = body.mortgagees.list;
    body.otherPartyClaimant = body.otherParties;
    if (body.insured.driverOption === CONSTANTS.FNOL.DRIVER_VALES.OUR_INSURED) {
        body.insured.dateOfBirth = body.driverOfInsuredVehicle.dateOfBirth
        body.insured.driversLicenseNo = body.driverOfInsuredVehicle.driversLicenseNo
        body.insured.injuries = body.driverOfInsuredVehicle.injuries
    }

    const dateTimeOfLoss = moment(`${body.lossInformation.dateOfLoss} ${body.lossInformation.timeOfLoss}`, FNOLConstants.DATE_FORMAT).toDate();
    body.dateTimeOfLoss = moment(dateTimeOfLoss).format(FNOLConstants.DATE_FORMAT)
    body.lossLocation = body.lossInformation.lossLocation;
    body.lossDescription = body.lossInformation.lossDescription;
    body.policyNumber = body.lossInformation.policyNumber;
    if (claimType === FNOLConstants.CLAIM_TYPE.AUTO) {
        body.driverType = body.insured.driverOption;
        body.passengerWitnessCollection = body.passengers.list.concat(body.witnesses.list);
        body.reportingParty.reportingPartyRelationToInsured = BaseYourInformationModel.PA_RELATIONSHIP[body.reportingParty.insuredOption]
    }

    body.policyNumberPrefix = claimType === FNOLConstants.CLAIM_TYPE.AUTO ? 'FA' : 'HP';
    delete body.lossInformation;

    return body;
}

export default class WMICFnolService {
    constructor(props) {
        this.authHeader = props.authHeader;
    }

    static submitClaim = (body, customHeaders, claimType) => {
        if (!body) {
            throw Error('Body is required.');
        }
        const headers = cloneDeep(baseHeaders);
        if (customHeaders) {
            Object.entries(customHeaders).forEach(([k, v]) => {
                headers[k] = v;
            });
        }

        const callStart = new Date().getTime();

        return fetch(serviceUrls.fnolEndpoint, {
            method: 'POST',
            headers,
            body: _getXMLRequest(organiseFormData(body, claimType), claimType)
        }).then((response) => {
            return response.text();
        }).then((text) => {
            const data = (new window.DOMParser()).parseFromString(text, 'text/xml');
            const response = data.querySelector('ClaimResponse');
            if (!response || response.querySelector('Success').innerHTML !== 'true') {
                if (data.querySelector('*|Fault')) {
                    const faultCode = data.querySelector('faultcode').innerHTML;
                    const faultText = data.querySelector('faultstring').innerHTML;
                    throw Error(`Unexpected response ${faultCode}: ${faultText}`);
                }
                throw Error(`Unexpected response ${response}`);
            }
            return response.querySelector('TrackingNumber').innerHTML;
        }).catch((error) => {
            const callTime = new Date().getTime() - callStart;
            return Promise.reject({
                baseError: error,
                isTimeout: (error.status === 0) && callTime < httpTimeout
            });
        })
    }
}

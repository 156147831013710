import { defineMessages } from 'react-intl';

export default defineMessages({
    FNOLInformationDetailFirstName: {
        id: 'wmic.fnol-page.information-detail.first-name',
        defaultMessage: 'First Name'
    },
    FNOLInformationDetailLastName: {
        id: 'wmic.fnol-page.information-detail.last-name',
        defaultMessage: 'Last Name'
    },
    FNOLInformationDetailAddress: {
        id: 'wmic.fnol-page.information-detail.address',
        defaultMessage: 'Address'
    },
    FNOLInformationDetailCity: {
        id: 'wmic.fnol-page.information-detail.city',
        defaultMessage: 'City'
    },
    FNOLInformationDetailCountry: {
        id: 'wmic.fnol-page.information-detail.country',
        defaultMessage: 'Country'
    },
    FNOLInformationDetailState: {
        id: 'wmic.fnol-page.information-detail.state',
        defaultMessage: 'State'
    },
    FNOLInformationDetailZIP: {
        id: 'wmic.fnol-page.information-detail.zip',
        defaultMessage: 'Zip Code'
    },
    FNOLInformationDetailPostal: {
        id: 'wmic.fnol-page.information-detail.postal-code',
        defaultMessage: 'Postal Code'
    },
    FNOLInformationDetailHomePhone: {
        id: 'wmic.fnol-page.information-detail.home-phone',
        defaultMessage: 'Home Phone Number'
    },
    FNOLInformationDetailBusinessPhone: {
        id: 'wmic.fnol-page.information-detail.business-phone',
        defaultMessage: 'Business Phone Number'
    },
    FNOLInformationDetailExt: {
        id: 'wmic.fnol-page.information-detail.ext',
        defaultMessage: 'ext.'
    },
    FNOLInformationDetailCellPhone: {
        id: 'wmic.fnol-page.information-detail.cell-phone',
        defaultMessage: 'Cell Phone Number'
    },
    FNOLInformationDetailLicenseNumber: {
        id: 'wmic.fnol-page.information-detail.license-number',
        defaultMessage: 'Drivers License Number'
    },
    FNOLInformationDetailInjuriesOrTreatments: {
        id: 'wmic.fnol-page.loss-information.injuries-or-treatments',
        defaultMessage: 'Tell us about any injuries or treatments received'
    },
    FNOLInformationDetailPhoneTitle: {
        id: 'wmic.fnol-page.information-detail.phone-title',
        defaultMessage: 'Please type at least one phone number below'
    }
})
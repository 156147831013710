import _ from 'lodash';
import CONSTANTS from 'wmic-portals-utils-js/StringConstants';

const getSelectProvince = () => [
    {
        "code":"AB",
        "displayName": {
            "id": "wmic.fnol-page.loss-information.state-dropdown.alberta",
            "defaultMessage": "Alberta"
        }
    },
    {
        "code":"BC",
        "displayName": {
            "id": "wmic.fnol-page.loss-information.state-dropdown.british-columbia",
            "defaultMessage": "British Columbia"
        }
    },
    {
        "code":"MB",
        "displayName": {
            "id": "wmic.fnol-page.loss-information.state-dropdown.manitoba",
            "defaultMessage": "Manitoba"
        }
    },
    {
        "code":"NB",
        "displayName": {
            "id": "wmic.fnol-page.loss-information.state-dropdown.new-brunswick",
            "defaultMessage": "New Brunswick"
        }
    },
    {
        "code":"NL",
        "displayName": {
            "id": "wmic.fnol-page.loss-information.state-dropdown.newfoundland-and-labrador",
            "defaultMessage": "Newfoundland and Labrador"
        }
    },
    {
        "code":"NT",
        "displayName": {
            "id": "wmic.fnol-page.loss-information.state-dropdown.northwest-territories",
            "defaultMessage": "Northwest Territories"
        }
    },
    {
        "code":"NS",
        "displayName": {
            "id": "wmic.fnol-page.loss-information.state-dropdown.nova-scotia",
            "defaultMessage": "Nova Scotia"
        }
    },
    {
        "code":"NU",
        "displayName": {
            "id": "wmic.fnol-page.loss-information.state-dropdown.nunavut",
            "defaultMessage": "Nunavut"
        }
    },
    {
        "code":"ON",
        "displayName": {
            "id": "wmic.fnol-page.loss-information.state-dropdown.ontario",
            "defaultMessage": "Ontario"
        }
    },
    {
        "code":"PE",
        "displayName": {
            "id": "wmic.fnol-page.loss-information.state-dropdown.prince-edward-island",
            "defaultMessage": "Prince Edward Island"
        }
    },
    {
        "code":"QC",
        "displayName": {
            "id": "wmic.fnol-page.loss-information.state-dropdown.quebec",
            "defaultMessage": "Quebec"
        }
    },
    {
        "code":"SK",
        "displayName": {
            "id": "wmic.fnol-page.loss-information.state-dropdown.saskatchewan",
            "defaultMessage": "Saskatchewan"
        }
    },
    {
        "code":"YT",
        "displayName": {
            "id": "wmic.fnol-page.loss-information.state-dropdown.yukon",
            "defaultMessage": "Yukon"
        }
    }
];


const getSelectState = () => [
    {
        "code":"AL",
        "displayName": {
            "id": "wmic.fnol-page.loss-information.state-dropdown.alabama",
            "defaultMessage": "Alabama"
        }
    },
    {
        "code":"AK",
        "displayName": {
            "id": "wmic.fnol-page.loss-information.state-dropdown.alaska",
            "defaultMessage": "Alaska"
        }
    },
    {
        "code":"AZ",
        "displayName": {
            "id": "wmic.fnol-page.loss-information.state-dropdown.arizona",
            "defaultMessage": "Arizona"
        }
    },
    {
        "code":"AR",
        "displayName": {
            "id": "wmic.fnol-page.loss-information.state-dropdown.arkansas",
            "defaultMessage": "Arkansas"
        }
    },
    {
        "code":"CA",
        "displayName": {
            "id": "wmic.fnol-page.loss-information.state-dropdown.california",
            "defaultMessage": "California"
        }
    },
    {
        "code":"CO",
        "displayName": {
            "id": "wmic.fnol-page.loss-information.state-dropdown.colorado",
            "defaultMessage": "Colorado"
        }
    },
    {
        "code":"CT",
        "displayName": {
            "id": "wmic.fnol-page.loss-information.state-dropdown.connecticut",
            "defaultMessage": "Connecticut"
        }
    },
    {
        "code":"DE",
        "displayName": {
            "id": "wmic.fnol-page.loss-information.state-dropdown.delaware",
            "defaultMessage": "Delaware"
        }
    },
    {
        "code":"DC",
        "displayName": {
            "id": "wmic.fnol-page.loss-information.state-dropdown.district-of-columbia",
            "defaultMessage": "District of Columbia"
        }
    },
    {
        "code":"FL",
        "displayName": {
            "id": "wmic.fnol-page.loss-information.state-dropdown.florida",
            "defaultMessage": "Florida"
        }
    },
    {
        "code":"GA",
        "displayName": {
            "id": "wmic.fnol-page.loss-information.state-dropdown.georgia",
            "defaultMessage": "Georgia"
        }
    },
    {
        "code":"HI",
        "displayName": {
            "id": "wmic.fnol-page.loss-information.state-dropdown.hawaii",
            "defaultMessage": "Hawaii"
        }
    },
    {
        "code":"ID",
        "displayName": {
            "id": "wmic.fnol-page.loss-information.state-dropdown.idaho",
            "defaultMessage": "Idaho"
        }
    },
    {
        "code":"IL",
        "displayName": {
            "id": "wmic.fnol-page.loss-information.state-dropdown.illinois",
            "defaultMessage": "Illinois"
        }
    },
    {
        "code":"IN",
        "displayName": {
            "id": "wmic.fnol-page.loss-information.state-dropdown.indiana",
            "defaultMessage": "Indiana"
        }
    },
    {
        "code":"IA",
        "displayName": {
            "id": "wmic.fnol-page.loss-information.state-dropdown.iowa",
            "defaultMessage": "Iowa"
        }
    },
    {
        "code":"KS",
        "displayName": {
            "id": "wmic.fnol-page.loss-information.state-dropdown.kansas",
            "defaultMessage": "Kansas"
        }
    },
    {
        "code":"KY",
        "displayName": {
            "id": "wmic.fnol-page.loss-information.state-dropdown.kentucky",
            "defaultMessage": "Kentucky"
        }
    },
    {
        "code":"LA",
        "displayName": {
            "id": "wmic.fnol-page.loss-information.state-dropdown.louisiana",
            "defaultMessage": "Louisiana"
        }
    },
    {
        "code":"ME",
        "displayName": {
            "id": "wmic.fnol-page.loss-information.state-dropdown.maine",
            "defaultMessage": "Maine"
        }
    },
    {
        "code":"MD",
        "displayName": {
            "id": "wmic.fnol-page.loss-information.state-dropdown.maryland",
            "defaultMessage": "Maryland"
        }
    },
    {
        "code":"MA",
        "displayName": {
            "id": "wmic.fnol-page.loss-information.state-dropdown.massachusetts",
            "defaultMessage": "Massachusetts"
        }
    },
    {
        "code":"MI",
        "displayName": {
            "id": "wmic.fnol-page.loss-information.state-dropdown.michigan",
            "defaultMessage": "Michigan"
        }
    },
    {
        "code":"MN",
        "displayName": {
            "id": "wmic.fnol-page.loss-information.state-dropdown.minnesota",
            "defaultMessage": "Minnesota"
        }
    },
    {
        "code":"MS",
        "displayName": {
            "id": "wmic.fnol-page.loss-information.state-dropdown.mississippi",
            "defaultMessage": "Mississippi"
        }
    },
    {
        "code":"MO",
        "displayName": {
            "id": "wmic.fnol-page.loss-information.state-dropdown.missouri",
            "defaultMessage": "Missouri"
        }
    },
    {
        "code":"MT",
        "displayName": {
            "id": "wmic.fnol-page.loss-information.state-dropdown.montana",
            "defaultMessage": "Montana"
        }
    },
    {
        "code":"NE",
        "displayName": {
            "id": "wmic.fnol-page.loss-information.state-dropdown.nebraska",
            "defaultMessage": "Nebraska"
        }
    },
    {
        "code":"NV",
        "displayName": {
            "id": "wmic.fnol-page.loss-information.state-dropdown.nevada",
            "defaultMessage": "Nevada"
        }
    },
    {
        "code":"NH",
        "displayName": {
            "id": "wmic.fnol-page.loss-information.state-dropdown.new-hampshire",
            "defaultMessage": "New Hampshire"
        }
    },
    {
        "code":"NJ",
        "displayName": {
            "id": "wmic.fnol-page.loss-information.state-dropdown.new-jersey",
            "defaultMessage": "New Jersey"
        }
    },
    {
        "code":"NM",
        "displayName": {
            "id": "wmic.fnol-page.loss-information.state-dropdown.new-mexico",
            "defaultMessage": "New Mexico"
        }
    },
    {
        "code":"NY",
        "displayName": {
            "id": "wmic.fnol-page.loss-information.state-dropdown.new-york",
            "defaultMessage": "New York"
        }
    },
    {
        "code":"NC",
        "displayName": {
            "id": "wmic.fnol-page.loss-information.state-dropdown.north-carolina",
            "defaultMessage": "North Carolina"
        }
    },
    {
        "code":"ND",
        "displayName": {
            "id": "wmic.fnol-page.loss-information.state-dropdown.north-dakota",
            "defaultMessage": "North Dakota"
        }
    },
    {
        "code":"OH",
        "displayName": {
            "id": "wmic.fnol-page.loss-information.state-dropdown.ohio",
            "defaultMessage": "Ohio"
        }
    },
    {
        "code":"OK",
        "displayName": {
            "id": "wmic.fnol-page.loss-information.state-dropdown.oklahoma",
            "defaultMessage": "Oklahoma"
        }
    },
    {
        "code":"OR",
        "displayName": {
            "id": "wmic.fnol-page.loss-information.state-dropdown.oregon",
            "defaultMessage": "Oregon"
        }
    },
    {
        "code":"PA",
        "displayName": {
            "id": "wmic.fnol-page.loss-information.state-dropdown.pennsylvania",
            "defaultMessage": "Pennsylvania"
        }
    },
    {
        "code":"RI",
        "displayName": {
            "id": "wmic.fnol-page.loss-information.state-dropdown.rhode-island",
            "defaultMessage": "Rhode Island"
        }
    },
    {
        "code":"SC",
        "displayName": {
            "id": "wmic.fnol-page.loss-information.state-dropdown.south-carolina",
            "defaultMessage": "South Carolina"
        }
    },
    {
        "code":"SD",
        "displayName": {
            "id": "wmic.fnol-page.loss-information.state-dropdown.south-dakota",
            "defaultMessage": "South Dakota"
        }
    },
    {
        "code":"TN",
        "displayName": {
            "id": "wmic.fnol-page.loss-information.state-dropdown.tennessee",
            "defaultMessage": "Tennessee"
        }
    },
    {
        "code":"TX",
        "displayName": {
            "id": "wmic.fnol-page.loss-information.state-dropdown.texas",
            "defaultMessage": "Texas"
        }
    },
    {
        "code":"UT",
        "displayName": {
            "id": "wmic.fnol-page.loss-information.state-dropdown.utah",
            "defaultMessage": "Utah"
        }
    },
    {
        "code":"VT",
        "displayName": {
            "id": "wmic.fnol-page.loss-information.state-dropdown.vermont",
            "defaultMessage": "Vermont"
        }
    },
    {
        "code":"VA",
        "displayName": {
            "id": "wmic.fnol-page.loss-information.state-dropdown.virginia",
            "defaultMessage": "Virginia"
        }
    },
    {
        "code":"WA",
        "displayName": {
            "id": "wmic.fnol-page.loss-information.state-dropdown.washington",
            "defaultMessage": "Washington"
        }
    },
    {
        "code":"WV",
        "displayName": {
            "id": "wmic.fnol-page.loss-information.state-dropdown.west-virginia",
            "defaultMessage": "West Virginia"
        }
    },
    {
        "code":"WI",
        "displayName": {
            "id": "wmic.fnol-page.loss-information.state-dropdown.wisconsin",
            "defaultMessage": "Wisconsin"
        }
    },
    {
        "code":"WY",
        "displayName": {
            "id": "wmic.fnol-page.loss-information.state-dropdown.wyoming",
            "defaultMessage": "Wyoming"
        }
    }
];

const getSelectCanadaUSA = () => [
    {
        "code":"US",
        "displayName": {
            "id": "wmic.fnol-page.loss-information.country-dropdown.united-states-of-america",
            "defaultMessage": "United States of America"
        }
    },
    {
        "code":"CA",
        "displayName": {
            "id": "wmic.fnol-page.loss-information.country-dropdown.canada",
            "defaultMessage": "Canada"
        }
    }
];

const getSelectDriverOfInsuredVehicle = () => [
    {
        "code":"OurInsured",
        "displayName": {
            "id": "wmic.fnol-page.insured-information.insured-radio.yes",
            "defaultMessage": "Yes"
        }
    },
    {
        "code":"OtherParty",
        "displayName": {
            "id": "wmic.fnol-page.insured-information.insured-radio.no",
            "defaultMessage": "No"
        }
    },
    {
        "code":"NoDriver",
        "displayName": {
            "id": "wmic.fnol-page.insured-information.insured-radio.no-driver",
            "defaultMessage": "No Driver"
        }
    },
    {
        "code":"Unknown",
        "displayName": {
            "id": "wmic.fnol-page.insured-information.insured-radio.unknown",
            "defaultMessage": "Unknown"
        }
    }
];
const getVehicleOptions = () => [
    {
        "code":"wawanesa",
        "displayName": {
            "id": "wmic.fnol-page.passengers-witnesses.vehicle-dropdown.wawanesa",
            "defaultMessage": "Wawanesa Insured Vehicle"
        }
    },
];

const scrollToError = () => {
    const promise = new Promise((resolve) => {
        setTimeout(() => {
            const error = document.querySelector('span[class*=__error], div[class*=__error]');
            if (error) {
                error.parentElement.scrollIntoView({inline: 'center', block: 'center', behavior: 'smooth'});
                resolve(error)
            }
            resolve()
        }, 500);
    });
    return promise
};

const copyAttributes = (src, dst = {}) => {
    Object.keys(dst).forEach((k) => {
        if (src[k] === null) {
            dst[k] = src[k]
        } else if (Array.isArray(src[k])) {
            dst[k] = src[k] || [];
        } else if (typeof src[k] === 'object') {
            copyAttributes(src[k], dst[k]);
        } else {
            dst[k] = src[k];
        }
    });
};

const unsanitarySet = new Set(['firstName', 'lastName', 'email', 'companyName', 'fileNumber',
    'street', 'city', 'lossDescription', 'licensePlateNumber', 'vehicleColor', 'damagesDesc', 'vehicleMake',
    'vehicleModel', 'driversLicense', 'injuries', 'name', 'policeDeptName', 'reportNumber',
    'policeDeptContactInfo']);

const sanitizeValue = (v) => `<![CDATA[${v}]]>`;

const OBJtoXML = (obj) =>  {
    let xml = '';
    for (const prop in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, prop)) {
            const outerProp = _.startCase(prop).replace(/\s/g,'');
            xml += obj[prop] instanceof Array ? '' : `<${ outerProp }>`;
            if (obj[prop] instanceof Array) {
                for (const array in obj[prop]) {
                    if (Object.prototype.hasOwnProperty.call(obj, prop)) {
                        const nestedProp = _.startCase(prop).replace(/\s/g,'');
                        xml += `<${  nestedProp  }>`;
                        xml += OBJtoXML({...obj[prop][array]});
                        xml += `</${  nestedProp  }>`;
                    }
                }
            } else if (typeof obj[prop] === "object") {
                xml += OBJtoXML({...obj[prop]});
            } else if (unsanitarySet.has(prop)) {
                xml += sanitizeValue(obj[prop]);
            } else {
                xml += obj[prop];
            }
            xml += obj[prop] instanceof Array ? '' : `</${ outerProp }>`;
        }
    }
    xml = xml.replace(/<\/?[0-9]{1,}>/g, '');
    return xml
}

const otherParty = (state = 'CA') => ({
    unknownParty: false,
    passengerLocation: "",
    firstName: "",
    lastName: "",
    address: {
        street: "",
        city: "",
        country: "US",
        state: state,
        postalCode: ""
    },
    phoneNumber: "",
    businessPhoneNumber: "",
    businessPhoneNumberExt: "",
    cellPhoneNumber: "",
    dateOfBirth: "",
    driversLicenseNo: "",
    injuries: "",
    vehicle: {
        vehicleYear: "",
        vehicleMake: "",
        vehicleModel: "",
        vehicleColor: "",
        licensePlateNumber: "",
        VIN: "",
        vehicleDamages: ""
    }
})

const witness = (state = 'CA') => ({
    passengerWitnessType: 'Witness',
    firstName: '',
    lastName: '',
    address: {
        street: '',
        city: '',
        country: 'US',
        state: state,
        postalCode: ''
    },
    phoneNumber: '',
    businessPhoneNumber: '',
    businessPhoneNumberExt: '',
    cellPhoneNumber: '',
    dateOfBirth: '',
    driverLicNo: '',
    injuries: '',
})

const passenger = (state = 'CA') => ({
    passengerLocation: '',
    passengerWitnessType: 'Passenger',
    firstName: '',
    lastName: '',
    address: {
        street: '',
        city: '',
        country: 'US',
        state: state,
        postalCode: ''
    },
    phoneNumber: '',
    businessPhoneNumber: '',
    businessPhoneNumberExt: '',
    cellPhoneNumber: '',
    dateOfBirth: '',
    driverLicNo: '',
    injuries: '',
})

const mortgagees = (state = 'CA') => ({
    name: '',
    address: {
        street: '',
        city: '',
        country: 'US',
        stateProvince: state,
        postalCode: ''
    },
    phoneNumber: '',
    businessPhoneNumber: '',
    ext: '',
    cellPhoneNumber: ''
})

const getFNOLHOHasError = (data) => {
    return Boolean(data.reportingParty.firstName && data.reportingParty.lastName && 
        (data.reportingParty.phoneNumber || data.reportingParty.businessPhoneNumber || data.reportingParty.cellPhoneNumber) &&
        data.lossInformation.policyNumber && data.lossInformation.dateOfLoss && data.lossInformation.timeOfLoss &&
        data.lossInformation.lossDescription && data.insured.firstName && data.insured.lastName)
}

const getFNOLPAHasError = (data) => {
    return Boolean(data.reportingParty.firstName && data.reportingParty.lastName && 
        (data.reportingParty.phoneNumber || data.reportingParty.businessPhoneNumber || data.reportingParty.cellPhoneNumber) &&
        data.lossInformation.policyNumber && data.lossInformation.dateOfLoss && data.lossInformation.timeOfLoss &&
        data.lossInformation.lossLocation.street && data.lossInformation.lossLocation.city && data.lossInformation.lossDescription && data.insured.firstName && data.insured.lastName)
}

const countryOptionChanged = (path ,value, stateParam, data, setData, sharedDataService) => {
    let newState;
    switch (value) {
        case CONSTANTS.COUNTRY.CA:
            newState = CONSTANTS.STATE.AB;
            break;
        case CONSTANTS.COUNTRY.US:
            newState = stateParam;
            break;
        default:
            break;
    }
    const newData = {...data};
    _.set(newData, path.substring(0, path.lastIndexOf('.')).concat('.stateProvince'), newState)
    // eslint-disable-next-line no-unused-vars
    sharedDataService = newData
    setData(newData)
};

const getStatesShown = (item) => {
    const country = item?.address?.country ? item?.address?.country : item?.lossLocation?.country
    return country === CONSTANTS.COUNTRY.US 
        ? getSelectState() 
        : getSelectProvince()
};

const FNOLPAFormShape = {
    isAutoglassOnly: {
        value: null
    },
    reportingParty: {
        reportingPartyRelationToInsured: '',
        insuredOption: '',
        firstName: '',
        lastName: '',
        emailAddress: '',
        phoneNumber: '',
        businessPhoneNumber: '',
        businessPhoneNumberExt: '',
        cellPhoneNumber: '',
        companyInfo: {
            companyName: '',
            fileNumber: ''
        }
    },
    lossInformation: {
        policyNumber: '',
        dateOfLoss: '',
        timeOfLoss: '',
        lossLocation: {
            street: '',
            city: '',
            stateProvince: '',
            country: 'US'
        },
        lossDescription: ''
    },
    policeInformation: {
        policeDeptName: '',
        reportNumber: '',
        policeDeptContactInfo: ''
    },
    insured: {
        sameAsInformer: false,
        firstName: '',
        lastName: '',
        address: {
            street: '',
            city: '',
            country: 'US',
            stateProvince: '',
            postalCode: ''
        },
        phoneNumber: '',
        businessPhoneNumber: '',
        businessPhoneNumberExt: '',
        cellPhoneNumber: '',
        vehicle: {
            year: '',
            make: '',
            model: '',
            color: '',
            licensePlateNumber: '',
            VIN: '',
            vehicleDamages: ''
        },
        driverOption: ''
    },
    driverOfInsuredVehicle: {
        firstName: '',
        lastName: '',
        address: {
            street: '',
            city: '',
            country: 'US',
            stateProvince: '',
            postalCode: ''
        },
        phoneNumber: '',
        businessPhoneNumber: '',
        businessPhoneNumberExt: '',
        cellPhoneNumber: '',
        dateOfBirth: '',
        driversLicenseNo: '',
        injuries: ''
    },
    otherPartyClaimant: {
        firstName: '',
        lastName: '',
        address: {
            street: '',
            city: '',
            country: 'US',
            state: '',
            postalCode: ''
        },
        phoneNumber: '',
        businessPhoneNumber: '',
        businessPhoneNumberExt: '',
        cellPhoneNumber: '',
        vehicle: {
            vehicleYear: '',
            vehicleMake: '',
            vehicleModel: '',
            vehicleColor: '',
            licensePlateNumber: '',
            VIN: '',
            vehicleDamages: ''
        }
    },
    driverOfInsuredVehicleBlank: {
        firstName: '',
        lastName: '',
        address: {
            street: '',
            city: '',
            country: 'US',
            stateProvince: '',
            postalCode: ''
        },
        phoneNumber: '',
        businessPhoneNumber: '',
        businessPhoneNumberExt: '',
        cellPhoneNumber: '',
        dateOfBirth: '',
        driversLicenseNo: '',
        injuries: ''
    },
    otherPartyModel: {
        unknownParty: false,
        passengerLocation: '',
        firstName: '',
        lastName: '',
        address: {
            street: '',
            city: '',
            country: 'US',
            stateProvince: '',
            postalCode: ''
        },
        phoneNumber: '',
        businessPhoneNumber: '',
        businessPhoneNumberExt: '',
        cellPhoneNumber: '',
        dateOfBirth: '',
        driversLicenseNo: '',
        injuries: ''
    },
    vehicleModel: {
        year: '',
        make: '',
        model: '',
        color: '',
        licensePlateNumber: '',
        VIN: '',
        vehicleDamages: ''
    },
    policeReportSelected: {
        value: null
    },
    otherPartiesSelected: {
        value: null
    },
    passengersSelected: {
        value: null
    },
    witnessesSelected: {
        value: null
    },
    mortgageesSelected: {
        value: null
    },
    otherParties: {
        list: [],
    },
    witnesses: {
        list: [],
    },
    passengers: {
        list: []
    },
    mortgagees: {
        list: []
    }
};

export default {
    getSelectProvince,
    getSelectState,
    getSelectCanadaUSA,
    getSelectDriverOfInsuredVehicle,
    scrollToError,
    copyAttributes,
    OBJtoXML,
    getVehicleOptions,
    otherParty,
    witness,
    passenger,
    mortgagees,
    getFNOLHOHasError,
    getFNOLPAHasError,
    countryOptionChanged,
    getStatesShown,
    FNOLPAFormShape
}
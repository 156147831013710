import PersonalizedOfferComponent from './components/PersonalizedOfferComponent/PersonalizedOfferComponent';

const personalizedOfferComponentMap = {
    personalizedoffercomponent: { component: 'PersonalizedOfferComponent' }
};

const personalizedOfferComponents = {
    PersonalizedOfferComponent
};

export {
    PersonalizedOfferComponent,

    personalizedOfferComponentMap,
    personalizedOfferComponents
};

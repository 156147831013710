import React, { useState, useEffect } from "react";
import { MetadataForm } from '@jutro/legacy/uiconfig';
import _ from 'lodash';
import CONSTANTS from "wmic-portals-utils-js/StringConstants";
import { FNOLConstants } from 'wmic-capability-fnol-react';

import FNOLPAYourInformationModel from './models/FNOLPAYourInformationModel';
import FNOLHOYourInformationModel from './models/FNOLHOYourInformationModel';

import styles from '../FNOLStyle.module.scss';
import metadata from './FNOLYourInformationComponent.metadata.json5';

function FNOLYourInformation(props) {
    const { claimType, WMICFnolSharedDataService, handleYourInfoDispatcher, validateFields } = props;
    
    const [hasCompanyInfo, setHasCompanyInfo] = useState(false)
    const [yourInformation, setYourInformation] = useState(WMICFnolSharedDataService.reportingParty)

    useEffect(() => {
        if(!WMICFnolSharedDataService.reportingParty.insuredOption) {
            const newData = {...yourInformation};
            _.set(newData, 'insuredOption', CONSTANTS.FNOL.YOUR_INFORMATION.INSURED)
            WMICFnolSharedDataService.reportingParty = newData
            setYourInformation(newData)
        }
    }, [])

    const insuranceOptions = (type) => {
        return type === FNOLConstants.CLAIM_TYPE.AUTO ? FNOLPAYourInformationModel.RELATIONSHIP_OPTIONS : FNOLHOYourInformationModel.RELATIONSHIP_OPTIONS
    }

    const showPhoneError = () => {
        if (yourInformation.phoneNumber || yourInformation.businessPhoneNumber || yourInformation.cellPhoneNumber) {
            return false
        }
        return true
    };

    const insuredOptionChange = (newValue) => {
        let showReportingParty = false;
        let showOtherPartySection = false;

        setHasCompanyInfo(FNOLPAYourInformationModel.isCompany(newValue));

        if (newValue !== CONSTANTS.FNOL.YOUR_INFORMATION.INSURED) showReportingParty = false;
        else showReportingParty = true;
        
        if (newValue === CONSTANTS.FNOL.YOUR_INFORMATION.OTHER_PARTY) showOtherPartySection = true;

        handleYourInfoDispatcher({ showReportingParty: showReportingParty, showOtherPartySection: showOtherPartySection })
    }
    
    const overrideProps = {
        FNOLYourInformationInsuranceOptions: {
            availableValues: insuranceOptions(claimType)
        },
        FNOLYourInformationCompanyContainer: {
            visible: hasCompanyInfo
        },
        FNOLYourInformationNameError: {
            visible: validateFields('reportingParty.firstName', 'field')
        },
        FNOLYourInformationLastNameError: {
            visible: validateFields('reportingParty.lastName', 'field')
        },
        FNOLYourInformationHomePhoneError:{
            visible: validateFields('reportingParty.phoneNumber', 'field') && showPhoneError()
        },
        FNOLYourInformationBusinessPhoneError:{
            visible: validateFields('reportingParty.businessPhoneNumber', 'field') && showPhoneError()
        },
        FNOLYourInformationExt: {
            registerValidation: () => _.noop
        },
        FNOLYourInformationCellPhoneError:{
            visible: validateFields('reportingParty.cellPhoneNumber', 'field') && showPhoneError()
        }
    };

    const resolvers = {
        resolveCallbackMap: {
            insuredOptionChange,
        },
        resolveClassNameMap: styles,

    }

    return (
        <MetadataForm
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
            data={yourInformation}
            onDataChange={(value, path) => {
                if (path === 'insuredOption') insuredOptionChange(value);
                const newData = {...yourInformation};
                _.set(newData, path, value);
                WMICFnolSharedDataService.reportingParty = newData;
                setYourInformation(newData);
                if (FNOLConstants.updateableFields.some((item) => item === path)) {
                    handleYourInfoDispatcher({reportingParty: WMICFnolSharedDataService.reportingParty});
                }
            }} />
    );
}

export default FNOLYourInformation

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__ForgotLoginInfo__forgot-links-override{justify-content:flex-start!important}", "",{"version":3,"sources":["webpack://./src/pages/wmic-enrollment/common/ForgotLoginInfo/ForgotLoginInfo.module.scss"],"names":[],"mappings":"AAAA,6CACI,oCAAA","sourcesContent":[".forgot-links-override {\n    justify-content: flex-start !important;   \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"forgot-links-override": "app__ForgotLoginInfo__forgot-links-override"
};
export default ___CSS_LOADER_EXPORT___;

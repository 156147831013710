import 'react-app-polyfill/ie9';
import { start } from '@jutro/app';
import { getConfigValue } from '@jutro/config';
import { subscribe, unsubscribeAll, JUTRO_TOPICS, loadGtag, initDefaultGA } from '@jutro/events';
import { LocaleService, G11nstore } from 'gw-portals-i18n-react';
import wmicAMPDefaultThemeConfig from 'wmic-amp-digital-theme-styles';

// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';

import 'regenerator-runtime';

import App from './app/App';

import { configureLegacyComponentMap } from '@jutro/legacy/uiconfig';
configureLegacyComponentMap();

// loading reCAPTCHA
const recaptchaKey = appConfig.credentials.CAPTCHA_API_KEY;
const script = document.createElement('script');
script.src = `https://www.google.com/recaptcha/api.js?render=${recaptchaKey}`;
document.head.append(script);

const oneincScriptUrl = appConfig.serviceUrls.oneIncScriptUrl;
const oneIncScript = document.createElement('script');
oneIncScript.type = 'text/javascript';
oneIncScript.async = true;
oneIncScript.defer = true;
oneIncScript.src = oneincScriptUrl;
document.head.appendChild(oneIncScript);

const { trackingConfig = {}, authentication = {} } = appConfig;

const gaAdditionalTrackingId = getConfigValue("GA4_TRACKING_ID");
let gtag;

if (gaAdditionalTrackingId) {
    gtag = loadGtag(gaAdditionalTrackingId);
}

const config = {
    ...authentication.servers.okta,
    ...appConfig.credentials
}

start(App, {
    rootId: 'root',
    messageLoader: LocaleService.loadMessages,
    coreMessageLoader: LocaleService.loadCoreMessages,
    config: [config],
    refreshOnLocaleChange: true,
    routerBasename: '/account-management',
    themeConfig: wmicAMPDefaultThemeConfig,
    g11nStore: G11nstore(),

    onInit: () => {
        LocaleService.register();
    },

    onRender: () => {
        // Unsubscribe from all default button and link click events
        unsubscribeAll([JUTRO_TOPICS.BUTTON_CLICKED, JUTRO_TOPICS.LINK_CLICKED, JUTRO_TOPICS.FETCH_REQUEST, JUTRO_TOPICS.FETCH_RESPONSE]);
        // Setup these custom events if ga initialized. If gtag is also initialized, it will handle all of ga events too.

        if (gtag) {
            subscribe(
                [ JUTRO_TOPICS.BUTTON_CLICKED, JUTRO_TOPICS.LINK_CLICKED ],
                (event) => {
                    const eventLabel = event.trackButtonIdentifier || event.trackbuttonidentifier || event.label;

                    // Only send when there is a label. It is not worth sending if we don't know which button was pressed.
                    if (eventLabel) {
                        gtag('event', 'click', {
                            event_category: 'ButtonClicked',
                            event_label: eventLabel
                        });
                    }
                }
            );
            subscribe(
                ['CLAIM_TRACKING'],
                (event) => {
                    gtag('event', event.action, {
                        hitType: 'event',
                        event_category: event.name,
                        event_label: event.label
                    });
                }
            );
            subscribe(
                ['ENROLLMENT_TRACKING'],
                (event) => {
                    gtag('event', event.action, {
                        event_category: event.name,
                        event_label: event.label
                    });
                }
            );
            subscribe(
                ['POLICY_CHANGE_TRACKING'],
                (event) => {
                    gtag('event', event.action, {
                        event_category: event.name,
                        event_label: event.label
                    });
                }
            );
        }
    },

    appName: {
        id: 'digital.appName',
        defaultMessage: 'Account Management Portal'
    },

    appDescription: 'Account Management Portal',
    disableAuthForApplicationRoot: true,
});

import { validationFunctions, utils }  from 'wmic-portals-viewmodel-js';

// EXAMPLE: Adding a new Expression Language Type
// Declare a new Expression Language Type
// const exampleExpressionLanguageType = {
//     namespace: 'pc', // Optional namespace for Expression Type
//     type: {  // Required Expression Language Object which should contain function properties that correspond to your backend Expression Object
//         getValue(val) {
//             return val || 'default value';
//         }
//     }
// }

// EXAMPLE - Adding a new Validation function
// const exampleArrLength = (a) => {
//     return a ? a.length : 0;
// }

const isInteger = (val) => {
    if ((undefined === val) || (val === null)) {
        return false;
    }
    return val % 1 === 0;
};

// Export Expression Types and Validation Functions

// Types
// Key should match the backend package name for your Expression Object
// Value should be your new Expression Language Type
export const types = {
    'edge.time.LocalDateDTO': { namespace: 'pc', type: validationFunctions.WMICLocalDateDTOValidationFunctions },
    'wmic.edge.ca.aspects.validation.ValidationFunctions_WMIC': { namespace: 'pc', type: validationFunctions.WMICValidationFunctions },
    'wmic.edge.ca.capabilities.policyjob.lob.homeownersHOE.coverables.dto.dwelling.DwellingDTO_WMIC': { namespace: 'pc', type: validationFunctions.WMICDwellingDTOValidationFunctions },
    'wmic.edge.common.capabilities.locale.util.DateFormatter': { namespace: undefined, type: utils.DateFormatter },
    'wmic.edge.us.capabilities.address.dto.AddressDTO':  { namespace: 'pc', type: validationFunctions.WMICAddressDTOValidationFunctions },
    'wmic.edge.us.capabilities.quote.submission.base.dto.QuoteBaseDataDTO':  { namespace: 'pc', type: validationFunctions.WMICQuoteBaseDataDTOValidationFunctions }
};

// Validations
// Function name should match backend validation function
export const validations = {
    isInteger
};
export const validationMessagesToIgnore = [
    // validation messages that the view model surfaces that Jutro might duplicate

    // This field is required
    'displaykey.Edge.Web.Api.Model.NotNull',
    // Value entered must be a valid phone number
    'displaykey.Edge.Web.Api.Model.Phone'
]

import { defineMessages } from 'react-intl';

export default defineMessages({
    EMQPageTitle: {
        id: 'wmic.emq-page.title',
        defaultMessage: 'Estimated Mileage'
    },
    EMQPageTitleSubmitted: {
        id: 'wmic.emq-page.title-submitted',
        defaultMessage: 'Estimated Mileage - Successfully Submitted'
    }
});

import _ from 'lodash';

export default {
    formatShortDate: (date) => {
        return date;
    },
    formatFullDate: (date) => {
        return date?.toLocaleDateString('en-us', { weekday:"long", year:"numeric", month:"long", day:"numeric"}) 
    },
    formatMediumDate_WMIC: (date) => {
        return _.filter('date')(new Date(date), 'mediumDate');
    }
};

import React from 'react';
import _ from 'lodash';
import { useTranslator } from '@jutro/locale';
import { MetadataContent, resolveClassName } from '@jutro/legacy/uiconfig';

import metadata from './ForgotLoginInfo.metadata.json5';
import messages from './ForgotLoginInfo.messages';
import styles from './ForgotLoginInfo.module.scss';

import { Link } from '@jutro/router';

function ForgotLoginInfo(props) {
    const translator = useTranslator();

    const overrideProps = {
        forgotPassword: {
            content: translator(messages.forgotPassword)
        },
        forgotEmail: {
            content: translator(messages.forgotEmail)
        }
    };

    return (
        <MetadataContent uiProps={metadata.componentContent} overrideProps={overrideProps} resolveComponentMap={{ link: Link }} resolveClassNameMap={styles} />
    );
}

export default ForgotLoginInfo;
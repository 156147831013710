// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__WMICRentalVehicleInfoBox__capitalizeText{text-transform:capitalize}.app__WMICRentalVehicleInfoBox__wmic-rental-vehicle-tip{display:flex}.app__WMICRentalVehicleInfoBox__wmic-rental-vehicle-tip div:first-child{padding-right:1rem}h2[id=rentalVehicleInfoBoxTitle]{font-weight:600}", "",{"version":3,"sources":["webpack://./node_modules/gw-capability-claim-react/components/WMICRentalVehicleInfoBox/WMICRentalVehicleInfoBox.module.scss"],"names":[],"mappings":"AAIA,+CACE,yBAAA,CAGF,wDACE,YAAA,CACA,wEACE,kBAAA,CAIJ,iCACE,eAAA","sourcesContent":["@import \"~@jutro/theme/assets/sass/helpers\";\n@import \"~wmic-amp-digital-theme-styles/sass/base/config/colors.scss\";\n@import \"~wmic-amp-digital-theme-styles/sass/customer-colors.scss\";\n\n.capitalizeText {\n  text-transform: capitalize;\n}\n\n.wmic-rental-vehicle-tip {\n  display: flex;\n  & div:first-child {\n    padding-right: 1rem;\n  }\n}\n\nh2[id=rentalVehicleInfoBoxTitle] {\n  font-weight: 600;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"capitalizeText": "app__WMICRentalVehicleInfoBox__capitalizeText",
	"wmic-rental-vehicle-tip": "app__WMICRentalVehicleInfoBox__wmic-rental-vehicle-tip"
};
export default ___CSS_LOADER_EXPORT___;

import { defineMessages } from 'react-intl';

export default defineMessages({
    policies: {
        id: 'billing.policies-table.Policies',
        defaultMessage: 'Policies'
    },
    type: {
        id: 'billing.policies-table.Type',
        defaultMessage: 'Type'
    },
    policy: {
        id: 'billing.policies-table.Policy',
        defaultMessage: 'Policy'
    },
    effective: {
        id: 'billing.policies-table.Effective',
        defaultMessage: 'Effective'
    },
    expiry: {
        id: 'billing.policies-table.Expiry',
        defaultMessage: 'Expiry'
    },
    premium: {
        id: 'billing.policies-table.Premium',
        defaultMessage: 'Premium'
    }
});

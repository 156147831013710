import _ from 'lodash';
import CONSTANTS from "wmic-portals-utils-js/StringConstants";

export default class FNOLConstants {}

FNOLConstants.CLAIM_TYPE = {
    AUTO: 'auto',
    RESIDENTIAL: 'residential'
};

FNOLConstants.CLAIM_TYPE_XML = {
    AUTO: 'Auto',
    HOME: 'Home'
};

FNOLConstants.REGEX_VALIDATIONS = {
    DATE: new RegExp('^((1[0-2]|0[1-9])\\/([0-2][0-9]|3[0-1])\\/\\d{4})$'),
    TIME: new RegExp('^((1[0-2]|0[1-9])\\:[0-5]\\d [Aa|Pp][Mm])$')
};

FNOLConstants.TEXT_CONSTANTS = {
    INSUREDS_VEHICLE: 'Wawanesa Insured Vehicle'
};

FNOLConstants.updateableFields = ['firstName', 'lastName', 'phoneNumber', 'businessPhoneNumber', 'businessPhoneNumberExt', 'cellPhoneNumber'];
    
FNOLConstants.AVAILABLE_COUNTS = _.map(new Array(5), (el, i) => ({code: i+1, name: i+1}))

FNOLConstants.resetDriverKeys = ['driversLicenseNo', 'injuries']

FNOLConstants.TECHNICAL_ERORR_PATH = '/technicalErrorPage/error';

FNOLConstants.FNOL_TECHNICAL_ERORR_PATH = '/technicalErrorPage/fnol';

FNOLConstants.DATE_FORMAT = 'MM/DD/YYYY hh:mm A';

FNOLConstants.formatState = (stateParameter) => {
    return stateParameter === CONSTANTS.STATE.CA_LONG ? CONSTANTS.STATE.CA : CONSTANTS.STATE.OR;
}

FNOLConstants.setZipMask = (country) => {
    return country === CONSTANTS.COUNTRY.US ? CONSTANTS.FNOL.POSTAL_CODE_MASKS.US_CODE : CONSTANTS.FNOL.POSTAL_CODE_MASKS.CA_CODE
}

FNOLConstants.FLOW_STEPS = {
    START: 'start',
    REVIEW: 'review',
    SUCCESS: 'success'
};
import { defineMessages } from 'react-intl';

export default defineMessages({
    register: {
        id: 'accountManagement.views.migration.title',
        defaultMessage: 'Reset Password',
    },
    registerDescription: {
        id: 'accountManagement.views.migration.description.Enter your email',
        defaultMessage: 'Enter your email to receive a password reset link.',
    },
    emailLabel: {
        id: 'accountManagement.views.migration.Email address',
        defaultMessage: 'Email address',
    },
    submitButton: {
        id: 'accountManagement.views.migration.Submit',
        defaultMessage: 'Submit',
    },
    forgotEmailLink: {
        id: 'accountManagement.views.migration.Forgot email',
        defaultMessage: '&lt;a href="{link}"&gt;Forgot email&lt;/a&gt;',
    },
    genericErrorMessage: {
        id: 'accountManagement.views.migration.genericErrorMessage',
        defaultMessage: 'Unable to process request at this moment',
    },
    invalidEmail: {
        id: 'accountManagement.views.migration.validation.Email address',
        defaultMessage: 'Invalid email address'
    },
    userNotFound: {
        id: 'accountManagement.views.migration.validation.User not found',
        defaultMessage: 'User not registered or active'
    }
 });
import { defineMessages } from 'react-intl';

export default defineMessages({
    address: {
        id: 'endorsement.pa.directives.templates.pa-address-change-edit.Address',
        defaultMessage: 'Address',
    },
    garageAddress: {
        id: 'endorsement.common.views.change-address.Garage Address Label',
        defaultMessage: 'Apply this address as the garaging address for all the vehicles?',
    },
    toggleYes: {
        id: 'endorsement.common.views.Yes',
        defaultMessage: 'Yes',
    },
    toggleNo: {
        id: 'endorsement.common.views.No',
        defaultMessage: 'No',
    },
});

import { defineMessages } from 'react-intl';

export default defineMessages({
    FNOLPassengersTitle: {
        id: 'wmic.fnol-page.passengers.title',
        defaultMessage: 'Passengers / Witnesses'
    },
    FNOLPassengersNoPassengersWarning: {
        id: 'wmic.fnol-page.passengers.no-passengers-warning',
        defaultMessage: 'No passengers in any involved vehicles'
    },
    FNOLPassengersDescription: {
        id: 'wmic.fnol-page.passengers.descrption-label',
        defaultMessage: `Passenger {num} Information`
    },
    FNOLPassengerWhichVechicle: {
        id: 'wmic.fnol-page.loss-information.which-vehicle-label',
        defaultMessage: `Which vehicle was the passenger in?`
    },
    FNOLPassengersToggleLabel: {
        id: 'wmic.fnol-page.passengers.toggle-label',
        defaultMessage: 'Were there any passengers in any of the involved vehicles?'
    },
    FNOLPassengersTabsetLabel: {
        id: 'wmic.fnol-page.passengers.tabset-label',
        defaultMessage: 'Passengers:'
    },
    FNOLPassengersCountDropdown: {
        id: 'wmic.fnol-page.passengers.count-label',
        defaultMessage: 'How Many?'
    },
    FNOLPassengersFormFirstName: {
        id: 'wmic.fnol-page.passengers.first-name-label',
        defaultMessage: 'First Name'
    },
    FNOLPassengersFormLastName: {
        id: 'wmic.fnol-page.passengers.last-name-label',
        defaultMessage: 'Last Name'
    },
    FNOLPassengersFormAddress: {
        id: 'wmic.fnol-page.passengers.address-label',
        defaultMessage: 'Address'
    },
    FNOLPassengersFormCity: {
        id: 'wmic.fnol-page.passengers.city-label',
        defaultMessage: 'City'
    },
    FNOLPassengersFormCountry: {
        id: 'wmic.fnol-page.passengers.country-label',
        defaultMessage: 'Country'
    },
    FNOLPassengersFormStateProvince: {
        id: 'wmic.fnol-page.passengers.state-province-label',
        defaultMessage: 'State'
    },
    FNOLPassengersFormZipCode: {
        id: 'wmic.fnol-page.passengers.zipcode-label',
        defaultMessage: 'Zip Code'
    },
    FNOLPassengersFormPostalCode: {
        id: 'wmic.fnol-page.passengers.postalcode-label',
        defaultMessage: 'Postal Code'
    },
    FNOLPassengersFormHomePhone: {
        id: 'wmic.fnol-page.passengers.home-phone-label',
        defaultMessage: 'Home Phone Number'
    },
    FNOLPassengersFormBusinessPhone: {
        id: 'wmic.fnol-page.passengers.business-phone-label',
        defaultMessage: 'Business Phone Number'
    },
    FNOLPassengersFormExt: {
        id: 'wmic.fnol-page.passengers.ext-label',
        defaultMessage: 'Ext'
    },
    FNOLPassengersFormCellPhone: {
        id: 'wmic.fnol-page.passengers.cell-phone-label',
        defaultMessage: 'Cell Phone Number'
    },
    FNOLPassengersFormDriversLicense: {
        id: 'wmic.fnol-page.passengers.drivers-license-label',
        defaultMessage: 'Drivers License Number'
    },
    FNOLPassengersFormInjuriesOrTreatments: {
        id: 'wmic.fnol-page.passengers.injuries-or-treatments-label',
        defaultMessage: 'Tell us about any injuries or treatments received'
    },
    FNOLPassengersOtherVehicleTitle: {
        id: 'wmic.fnol-page.passengers.vehicle-info-title',
        defaultMessage: 'Other Party {num} Vehicle Information'
    },
    FNOLPassengersVehicleYear: {
        id: 'wmic.fnol-page.passengers.vehicle-year-label',
        defaultMessage: 'Vehicle Year'
    },
    FNOLPassengersVehicleMake: {
        id: 'wmic.fnol-page.passengers.vehicle-make-label',
        defaultMessage: 'Vehicle Make'
    },
    FNOLPassengersVehicleModel: {
        id: 'wmic.fnol-page.passengers.vehicle-model-label',
        defaultMessage: 'Vehicle Model'
    },
    FNOLPassengersVehicleColor: {
        id: 'wmic.fnol-page.passengers.vehicle-color-label',
        defaultMessage: 'Vehicle Color'
    },
    FNOLPassengersVehicleVin: {
        id: 'wmic.fnol-page.passengers.vehicle-vin-label',
        defaultMessage: 'Vehicle Vin'
    },
    FNOLPassengersVehicleComment: {
        id: 'wmic.fnol-page.passengers.vehicle-comment-label',
        defaultMessage: 'Tell us about the extent and location of the damage on this vehicle'
    },
    FNOLPassengersVehicleRequiredOption: {
        id: "wmic.fnol-page.passengers.toggle-required",
        defaultMessage: "Field required, select an option."
    },
})
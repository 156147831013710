import React, { useState, useEffect } from 'react';
import { MetadataForm } from '@jutro/legacy/uiconfig';
import { WMICFnolUtilsService } from 'wmic-capability-fnol';
import _ from 'lodash';
import CONSTANTS from 'wmic-portals-utils-js/StringConstants';

import metadata from './FNOLLossInformationComponent.metadata.json5';
import styles from '../FNOLStyle.module.scss';
import { FNOLConstants } from '../..';

function FNOLLossInformationComponent(props){
    const { WMICFnolSharedDataService, state, claimType, validateFields } = props;
    
    const [lossInformation, setLossInformation] = useState(WMICFnolSharedDataService.lossInformation);

    const countryOptionChanged = () => {
        if(lossInformation.lossLocation.country){
            let newState;
            switch (lossInformation.lossLocation.country) {
                case CONSTANTS.COUNTRY.CA:
                    newState = CONSTANTS.STATE.AB;
                    break;
                case CONSTANTS.COUNTRY.US:
                    newState = state;
                    break;
                default:
                    break;
            }
            const newData = {...lossInformation};
            _.set(newData, 'lossLocation.stateProvince', newState)
            WMICFnolSharedDataService.lossInformation = newData
            setLossInformation(newData)
        }
    }

    useEffect(() => {
        countryOptionChanged();
        if (!lossInformation.lossLocation.stateProvince) {
            lossInformation.lossLocation.stateProvince = state;
        }
    }, [])

    const resolvers = {
        resolveClassNameMap: styles
    }

    const overrideProps = {
        FNOLLossInformationCountry: {
            availableValues: WMICFnolUtilsService.getSelectCanadaUSA()
        },
        FNOLLossInformationState: {
            availableValues: WMICFnolUtilsService.getStatesShown(lossInformation)
        },
        FNOLLossInformationDateLoss:{
            controlClassName: styles.inputMaskControl,
        },
        FNOLLossInformationTimeLoss:{
            controlClassName: styles.inputMaskControl,
        },
        FNOLLossInformationPolicyNumberError:{
            visible: validateFields('lossInformation.policyNumber', 'policy')
        },
        FNOLLossInformationDateLossError:{
            visible: validateFields('lossInformation.dateOfLoss', 'field')
        },
        FNOLLossInformationTimeLossError:{
            visible: validateFields('lossInformation.timeOfLoss', 'field')
        },
        FNOLLossInformationStreetError:{
            visible: validateFields('lossInformation.lossLocation.street', 'field')
        },
        FNOLLossInformationCityError:{
            visible: validateFields('lossInformation.lossLocation.city', 'field')
        },
        FNOLLossInformationWhatHappenedError:{
            visible: validateFields('lossInformation.lossDescription', 'field')
        },
        FNOLLossInformationTimeLossFormat: {
            visible: validateFields('lossInformation.timeOfLoss', 'time')
        },
        FNOLLossInformationDateLossFormat: {
            visible: validateFields('lossInformation.dateOfLoss', 'date') && !validateFields('lossInformation.dateOfLoss', 'field')
        },
        FNOLLossInformationOnlyPA: {
            visible: claimType === FNOLConstants.CLAIM_TYPE.AUTO
        }
    }

    return (
        <MetadataForm
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
            data={lossInformation}
            onDataChange={(value, path) => {
                const newData = {...lossInformation};
                _.set(newData, path, value)
                WMICFnolSharedDataService.lossInformation = newData
                setLossInformation(newData)
                if (path === 'lossLocation.country') countryOptionChanged();
            }} />
    );
}

export default FNOLLossInformationComponent
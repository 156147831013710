import { setComponentMapOverrides } from '@jutro/legacy/uiconfig';
import WMICEMQPage from './pages/WMICEMQPage/WMICEMQPage';
import WMICEMQLandingPage from './components/WMICEMQLandingPage/WMICEMQLandingPage';
import WMICEMQVehiclesComponent from './components/WMICEMQVehiclesComponent/WMICEMQVehiclesComponent';
import WMICEMQVehicleAccordionComponent from './components/WMICEMQVehicleAccordionComponent/WMICEMQVehicleAccordionComponent';
import WMICEMQSuccessComponent from './components/WMICEMQSuccessComponent/WMICEMQSuccessComponent';


setComponentMapOverrides(
    {
        WMICEMQPage: { component: 'WMICEMQPage' },
        WMICEMQLandingPage: { component: 'WMICEMQLandingPage' },
        WMICEMQVehiclesComponent: { component: 'WMICEMQVehiclesComponent' },
        WMICEMQVehicleAccordionComponent: { component: 'WMICEMQVehicleAccordionComponent' },
        WMICEMQSuccessComponent: { component: 'WMICEMQSuccessComponent' }
    },
    {
        WMICEMQPage,
        WMICEMQLandingPage,
        WMICEMQVehiclesComponent,
        WMICEMQVehicleAccordionComponent,
        WMICEMQSuccessComponent
    }
);

export { default as WMICEMQPage } from './pages/WMICEMQPage/WMICEMQPage';
export { default as WMICEMQLandingPage } from './components/WMICEMQLandingPage/WMICEMQLandingPage';
export { default as WMICEMQVehiclesComponent } from './components/WMICEMQVehiclesComponent/WMICEMQVehiclesComponent';
export { default as WMICEMQVehicleAccordionComponent } from './components/WMICEMQVehicleAccordionComponent/WMICEMQVehicleAccordionComponent';
export { default as WMICEMQSuccessComponent } from './components/WMICEMQSuccessComponent/WMICEMQSuccessComponent';

import React, {
    useEffect,
    useState,
    useCallback
} from 'react';
import _ from 'lodash';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { useAuthentication } from 'wmic-digital-auth-react';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { messages } from 'gw-capability-policychange-common-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import styles from './QuotePage.module.scss';
import metadata from './QuotePage.metadata.json5';


function QuotePage(props) {
    const { wizardData, updateWizardData } = props;
    const { submissionVM } = wizardData;
    const { authenticated } = useAuthentication();
    const [allDrivers, setAllDrivers] = useState();
    const [allVehicles, setAllVehicles] = useState();

    useEffect(() => {
        const setDriverNames = () => {
            const drivers = _.get(
                submissionVM.value,
                'lobData.personalAuto.coverables.drivers',
                []
            );
            const driverNames = drivers.map((driver) => driver.displayName);
            setAllDrivers(driverNames.join());
        };

        const setVehicleNames = () => {
            const vehicles = _.get(
                submissionVM.value,
                'lobData.personalAuto.coverables.vehicles',
                []
            );
            const vehicleNames = vehicles.map((vehicle) => vehicle.displayName);
            setAllVehicles(vehicleNames.join());
        };

        if (authenticated) {
            setDriverNames();
            setVehicleNames();
        }
    }, [authenticated, submissionVM, updateWizardData]);

    const resolvers = {
        resolveClassNameMap: styles
    };

    const overrideProps = {
        drivers: {
            value: allDrivers
        },
        vehicles: {
            value: allVehicles
        },
        dateId: {
            labelPosition: undefined
        },
        effectiveDateRange: {
            value: {
                startDate: _.get(submissionVM, 'baseData.periodStartDate.value'),
                endDate: _.get(submissionVM, 'baseData.periodEndDate.value')
            }
        }
    };

    const readValue = useCallback(
        (id, path) => {
            return readViewModelValue(metadata.pageContent, submissionVM, id, path, overrideProps);
        },
        [overrideProps, submissionVM]
    );

    return (
        <WizardPage
            cancelLabel={(appConfig.persona === 'policyholder' ? messages.cancelAllChanges : messages.cancel)}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                resolveValue={readValue}
                classNameMap={resolvers.resolveClassNameMap}
            />
        </WizardPage>
    );
}

QuotePage.propTypes = wizardProps;
export default QuotePage;

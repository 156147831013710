import { defineMessages } from 'react-intl';


export default defineMessages({
    FNOLPASuccessTitle: {
        id: 'wmic.fnol-success.pa.title',
        defaultMessage: 'Claim Successfully Submitted'
    },
    FNOLPASuccessThanksText: {
        id: 'wmic.fnol-success.pa.thanks-text',
        defaultMessage: 'Thank you for submitting your claim online. A member of our claims team will contact you within the next business day.'
    },
    FNOLPASuccessRecordTrackingNumberText: {
        id: 'wmic.fnol-success.pa.record-tracking-text',
        defaultMessage: 'Please record the number below to use as reference until a claim number is confirmed for you by a member of our claims team.'
    },
    FNOLPASuccessAvailablityText: {
        id: 'wmic.fnol-success.pa.availability-text',
        defaultMessage: '&lt;span&gt;We are available 24 hours a day, 7 days a week at &lt;a href="tel:888-929-2252"&gt;888-WAWA-CLAIM (929-2252)&lt;/a&gt;&lt;/span&gt;'
    },
    FNOLPASuccessClaimEmergencyText: {
        id: 'wmic.fnol-success.pa.claim-emergency-text',
        defaultMessage: 'If you are experiencing a claim emergency (i.e. you have a need for a tow or rental) please call immediately for assistance.'
    },
    FNOLPASuccessPrintClaimText: {
        id: 'wmic.fnol-success.pa.print-claim-text',
        defaultMessage: 'Print Claim'
    },
    FNOLPASuccessContactText: {
        id: 'wmic.fnol-success.pa.contact-text',
        defaultMessage: '&lt;span&gt;For your other insurance needs, our representatives are available Monday through Friday, 7:30am - 7:30pm PT, and on Saturday from 8:00am - 4:30pm PT at &lt;a href="tel:800-640-2920"&gt;800-640-2920&lt;/a&gt;.&lt;/span&gt;'
    },
    FNOLPAReviewTitle: {
        id: 'wmic.fnol-success-summary.pa.title',
        defaultMessage: 'Claim Summary'
    },
    FNOLPAReviewYourInformationTitle: {
        id: 'wmic.fnol-success.pa.your-information-title',
        defaultMessage: 'Your Information'
    },
    FNOLPAReviewPoliceReportTitle: {
        id: 'wmic.fnol-success.pa.police-report-title',
        defaultMessage: 'Police Report'
    },
    FNOLPAReviewInsuredOptionInsured: {
        id: 'wmic.fnol-success.insured-option.wawanesa-policy-holder',
        defaultMessage: 'Wawanesa Policy Holder'
    },
    FNOLPAReviewInsuredOptionOtherParty: {
        id: 'wmic.fnol-success.insured-option.other-party',
        defaultMessage: 'Other Party Involved In The Accident'
    },
    FNOLPAReviewInsuredOptionLienholder: {
        id: 'wmic.fnol-success.insured-option.lienholder',
        defaultMessage: 'Lienholder'
    },
    FNOLPAReviewInsuredOptionAnotherInsuranceCompany: {
        id: 'wmic.fnol-success.insured-option.another-insurance-company',
        defaultMessage: 'Another Insurance Company'
    },
    FNOLPAReviewInsuredOptionAttorney: {
        id: 'wmic.fnol-success.insured-option.attorney',
        defaultMessage: 'Attorney'
    },
    FNOLReviewPoliceReportDeptNameLabel: {
        id: 'wmic.fnol-success.pa.police-dept-label',
        defaultMessage: 'Law enforcement agency:'
    },
    FNOLReviewPoliceReportReportNumberLabel: {
        id: 'wmic.fnol-success.pa.police-report-number-label',
        defaultMessage: 'Accident report/case number:'
    },
    FNOLReviewPoliceReportContactLabel: {
        id: 'wmic.fnol-success.pa.police-contact-label',
        defaultMessage: 'Address and phone number for this agency:'
    },
    FNOLPAReviewLossInformationTitle: {
        id: 'wmic.fnol-success.pa.loss-information-title',
        defaultMessage: 'Loss Information'
    },
    FNOLPAReviewWawanesaPolicyholderInformationTitle: {
        id: 'wmic.fnol-success.pa.wawanesa-policyholder-information-title',
        defaultMessage: 'Wawanesa Policyholder Information'
    },
    FNOLPAReviewOtherPartyInformationTitle: {
        id: 'wmic.fnol-success.pa.other-party-information-title',
        defaultMessage: 'Other Party Information'
    },
    FNOLPAReviewOtherPartyVehicleInformationTitle: {
        id: 'wmic.fnol-success.pa.other-party-vehicle-information-title',
        defaultMessage: 'Other Party Vehicle Information'
    },
    FNOLPAReviewWawanesaInsuredVehicleInformationTitle: {
        id: 'wmic.fnol-success.pa.wawanesa-insured-vehicle-information-title',
        defaultMessage: 'Wawanesa Insured Vehicle Information'
    },
    FNOLPAReviewOtherPartiesTitle: {
        id: 'wmic.fnol-success.pa.other-parties-title',
        defaultMessage: 'Other Parties'
    },
    FNOLReviewOtherPartiesInformationTitle: {
        id: 'wmic.fnol-success.pa.other-parties-information-title',
        defaultMessage: 'Other Party {num} Information'
    },
    FNOLReviewOtherPartiesVehicleInformationTitle: {
        id: 'wmic.fnol-success.pa.other-parties-vehicle-information-title',
        defaultMessage: 'Other Party {num} Vehicle'
    },
    FNOLReviewPassengersWitnessesTitle: {
        id: 'wmic.fnol-success.pa.other-party-passengers-witnesses-title',
        defaultMessage: 'Passengers / Witnesses'
    },
    FNOLReviewpassengersTitle: {
        id: 'wmic.fnol-success.pa.other-party-passengers-passenger-title',
        defaultMessage: 'Passenger {num}'
    },
    FNOLReviewwitnessesTitle: {
        id: 'wmic.fnol-success.pa.other-party-passengers-witness-title',
        defaultMessage: 'Witness {num}'
    },
    FNOLReviewFirstName: {
        id: 'wmic.fnol-success.pa.first-name-label',
        defaultMessage: 'First Name:'
    },
    FNOLReviewLastName: {
        id: 'wmic.fnol-success.pa.last-name-label',
        defaultMessage: 'Last Name:'
    },
    FNOLReviewPhoneNumber: {
        id: 'wmic.fnol-success.pa.phone-number-label',
        defaultMessage: 'Home Phone Number:'
    },
    FNOLReviewBusinessPhoneNumber: {
        id: 'wmic.fnol-success.pa.business-phone-number-label',
        defaultMessage: 'Business Phone Number:'
    },
    FNOLReviewCellPhoneNumber: {
        id: 'wmic.fnol-success.pa.cell-phone-number-label',
        defaultMessage: 'Cell Phone Number:'
    },
    FNOLReviewEmailAddress: {
        id: 'wmic.fnol-success.pa.email-address-label',
        defaultMessage: 'Email Address:'
    },
    FNOLReviewCompanyName: {
        id: 'wmic.fnol-success.pa.company-name-label',
        defaultMessage: 'Company Name:'
    },
    FNOLReviewFileNumber: {
        id: 'wmic.fnol-success.pa.file-number-label',
        defaultMessage: 'File Number:'
    },
    FNOLReviewWawanesaPolicyNumber: {
        id: 'wmic.fnol-success.pa.wawanesa-policy-number-label',
        defaultMessage: 'Wawanesa Policy Number:'
    },
    FNOLReviewDateOfLoss: {
        id: 'wmic.fnol-success.pa.date-of-loss-label',
        defaultMessage: 'Date of Loss:'
    },
    FNOLReviewTimeOfLoss: {
        id: 'wmic.fnol-success.pa.time-of-loss-label',
        defaultMessage: 'Time of Loss:'
    },
    FNOLReviewStreetFreewayLocation: {
        id: 'wmic.fnol-success.pa.street-freeway-location-label',
        defaultMessage: 'Street/Freeway Location:'
    },
    FNOLReviewStreet: {
        id: 'wmic.fnol-success.pa.street--location-label',
        defaultMessage: 'Street:'
    },
    FNOLReviewCity: {
        id: 'wmic.fnol-success.pa.city-label',
        defaultMessage: 'City:'
    },
    FNOLReviewCountry: {
        id: 'wmic.fnol-success.pa.country-label',
        defaultMessage: 'Country:'
    },
    FNOLReviewState: {
        id: 'wmic.fnol-success.pa.state-label',
        defaultMessage: 'State:'
    },
    FNOLReviewTellUsWhatHappened: {
        id: 'wmic.fnol-success.pa.tell-us-what-happened-label',
        defaultMessage: 'Tell us what happened?'
    },
    FNOLReviewAddress: {
        id: 'wmic.fnol-success.pa.address-label',
        defaultMessage: 'Address:'
    },
    FNOLReviewZipcode: {
        id: 'wmic.fnol-success.pa.zipcode-label',
        defaultMessage: 'Zip Code:'
    },
    FNOLReviewDriversLicenseNumber: {
        id: 'wmic.fnol-success.pa.drivers-license-number-label',
        defaultMessage: 'Drivers License Number:'
    },
    FNOLReviewInjuriesRecieved: {
        id: 'wmic.fnol-success.pa.injuries-recieved-label',
        defaultMessage: 'Tell us about any injuries or treatments received:'
    },
    FNOLReviewVehicleYear: {
        id: 'wmic.fnol-success.pa.vehicle-year-label',
        defaultMessage: 'Vehicle Year:'
    },
    FNOLReviewVehicleModel: {
        id: 'wmic.fnol-success.pa.vehicle-model-label',
        defaultMessage: 'Vehicle Model:'
    },
    FNOLReviewVehicleMake: {
        id: 'wmic.fnol-success.pa.vehicle-make-label',
        defaultMessage: 'Vehicle Make:'
    },
    FNOLReviewVehicleColor: {
        id: 'wmic.fnol-success.pa.vehicle-color-label',
        defaultMessage: 'Vehicle Color:'
    },
    FNOLReviewVehicleLicensePlate: {
        id: 'wmic.fnol-success.pa.vehicle-license-plate-label',
        defaultMessage: 'Vehicle License Plate:'
    },
    FNOLReviewVehicleVIN: {
        id: 'wmic.fnol-success.pa.vehicle-vin-label',
        defaultMessage: 'VIN:'
    },
    FNOLReviewVehicleDamages: {
        id: 'wmic.fnol-success.pa.vehicle-damages-label',
        defaultMessage: 'Vehicle Damages:'
    },
    FNOLReviewInsuredVehicleDamage: {
        id: 'wmic.fnol-success.pa.insured-vehicle-damages-label',
        defaultMessage: 'Tell us about the extent and location of the damage on this vehicle:'
    },
    FNOLReviewNoPassengersWarning: {
        id: 'wmic.fnol-success.pa.no-passengers-warning',
        defaultMessage: 'No passengers in any involved vehicles'
    },
    FNOLReviewNoWitnessesWarning: {
        id: 'wmic.fnol-success.pa.no-witnesses-warning',
        defaultMessage: 'No witnesses'
    },
    FNOLReviewNoOtherPartiesWarning: {
        id: 'wmic.fnol-success.pa.no-other-parties-warning',
        defaultMessage: 'Solo Vehicle Loss - No Other Party Involved'
    },
    FNOLPASubmitButton: {
        id: 'wmic.fnol-success.pa.submit',
        defaultMessage: 'Submit Claim'
    },
    FNOLPAEditButton: {
        id: 'wmic.fnol-success.pa.edit',
        defaultMessage: 'Edit Claim'
    },
})

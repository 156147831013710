// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__WMICAutoShopsMapComponent__far-map{background-color:#cfcfcf;height:100%}.app__WMICAutoShopsMapComponent__far-map img{max-width:none}@media screen and (max-width:768px){.app__WMICAutoShopsMapComponent__far-map{height:300px}}", "",{"version":3,"sources":["webpack://./../common/capabilities-react/wmic-capability-autoshops-react/components/WMICAutoShopsMapComponent/WMICAutoShopsMapComponent.module.scss"],"names":[],"mappings":"AAEA,yCACE,wBAAA,CACA,WAAA,CAEA,6CACE,cAAA,CAIJ,oCACE,yCACE,YAAA,CAAA","sourcesContent":["@import \"~wmic-amp-digital-theme-styles/sass/app/portal-specific/styles/sass/portal-variables.scss\";\n\n.far-map {\n  background-color: #cfcfcf;\n  height: 100%;\n\n  img {\n    max-width: none;\n  }\n}\n\n@media screen and (max-width: 768px) {\n  .far-map {\n    height: 300px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"far-map": "app__WMICAutoShopsMapComponent__far-map"
};
export default ___CSS_LOADER_EXPORT___;

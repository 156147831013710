import { defineMessages } from 'react-intl';

export default defineMessages({
    autoShopsTitle: {
        id: 'wmic.autoshops.list-view.title',
        defaultMessage: 'Need a Reliable Repair Shop?'
    },
    autoShopsListViewSubTitle: {
        id: 'wmic.autoshops.list-view.subtitle',
        defaultMessage: 'Use the directory below to find a Wawanesa approved repair facility near you.'
    },
    autoShopsRepairFacilityDirectory: {
        id: 'wmic.autoshops.list-view.RepairFacilityDirectory',
        defaultMessage: 'Repair Facility Directory'
    },
    autoShopsNoRepairShopsFound: {
        id: 'wmic.autoshops.list-view.NoRepairShopsFound',
        defaultMessage: 'NO REPAIR SHOPS FOUND'
    },
    autoShopsNoRepairShopsFoundInstructions: {
        id: 'wmic.autoshops.list-view.NoRepairShopsFoundInstructions',
        defaultMessage: 'There are no Wawanesa approved repair shops nearby. Enter a location above or zoom in using the map to find a repair facility.'
    },
    autoShopsListPrevious: {
        id: 'wmic.autoshops.list-view.autoShopsListPrevious',
        defaultMessage: 'Previous'
    },
    autoShopsListNext: {
        id: 'wmic.autoshops.list-view.autoShopsListNext',
        defaultMessage: 'Next Page'
    },
});

import { defineMessages } from 'react-intl';

export default defineMessages({
    forgotEmailTitle: {
        id: 'accountManagement.views.forgot-email.title',
        defaultMessage: 'Forgot your email address?'
    },
    forgotEmailDescription: {
        id: 'accountManagement.views.forgot-email.description',
        defaultMessage: '&lt;span&gt;If you have forgotten the email address you login with, and need assistance please contact us at &lt;a href="tel:{phoneNumberRaw}"&gt;{phoneNumber}&lt;/a&gt; Monday to Friday 7:30 AM to 7:30 PM or Saturdays 8:00 AM to 4:30 PM.&lt;/span&gt;'
    },
    backToLogin: {
         id: 'accountManagement.views.forgot-email.Back to Log in',
         defaultMessage: 'Back to Log in'
    }
 });
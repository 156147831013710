import { defineMessages } from 'react-intl';

export default defineMessages({
    registrationLinkExpired: {
        id: 'accountManagement.views.registration-link-expired.title',
        defaultMessage: 'Confirmation link expired',
    },
    registrationLinkExpiredExpiredDescription: {
        id: 'accountManagement.views.registration-link-expired.description',
        defaultMessage: 'Sorry this confirmation link has expired. You\'ll need to try creating your account again.'
    },
    createAccountButton: {
         id: 'accountManagement.views.registration-link-expired.create your account',
         defaultMessage: 'Create your account',
    },
 });
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__WMICPendingPaymentCancellation__wmic-pending-payment-paragraph{margin-bottom:3rem!important}.app__WMICPendingPaymentCancellation__wmic-pending-payment-value{display:inline-block}.app__WMICPendingPaymentCancellation__loaderDiv div{padding:0!important}", "",{"version":3,"sources":["webpack://./node_modules/gw-capability-policy-react/WMICBillingInfoBox/WMICPendingPaymentCancellation/WMICPendingPaymentCancellation.module.scss"],"names":[],"mappings":"AAIA,qEACE,4BAAA,CAGF,iEACE,oBAAA,CAIA,oDACE,mBAAA","sourcesContent":["@import \"~@jutro/theme/assets/sass/helpers\";\n@import \"~wmic-amp-digital-theme-styles/sass/base/config/colors.scss\";\n@import \"~wmic-amp-digital-theme-styles/sass/customer-colors.scss\";\n\n.wmic-pending-payment-paragraph {\n  margin-bottom: 3rem !important;\n}\n\n.wmic-pending-payment-value {\n  display: inline-block;\n}\n\n.loaderDiv {\n  div {\n    padding: 0px !important;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wmic-pending-payment-paragraph": "app__WMICPendingPaymentCancellation__wmic-pending-payment-paragraph",
	"wmic-pending-payment-value": "app__WMICPendingPaymentCancellation__wmic-pending-payment-value",
	"loaderDiv": "app__WMICPendingPaymentCancellation__loaderDiv"
};
export default ___CSS_LOADER_EXPORT___;

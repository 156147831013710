import React, { useState, useEffect, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { WMICLoader } from 'gw-components-platform-react';
import { WMICAutoShopsListMapViewComponent, WMICAutoShopsListViewComponent, WMICAutoShopsServiceErrorComponent } from 'wmic-capability-autoshops-react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { AUTO_SHOPS } from 'wmic-portals-utils-js/StringConstants';
import { MapUtil, WMICFeatureFlagUtil, WMICArrayUtil } from 'wmic-portals-utils-js';

import autoshopData from '../../converted_raw_autoshops.json';

import metadata from './WMICAutoShopsPage.metadata.json5';
import styles from './WMICAutoShopsPage.module.scss';

function WMICAutoShopsPage() {
    const [loading, setLoading] = useState(false);
    const [displayMode, setDisplayMode] = useState(undefined);
    const [loadDataPromise, setLoadDataPromise] = useState(undefined);
    const [googleMapsApi, updateGoogleMapsApi] = useState(undefined);
    const [featureFlags, setFeatureFlags] = useState(undefined);

    const featureFlagNames = WMICFeatureFlagUtil.getFeatureFlags();
    const flagsPromise = WMICFeatureFlagUtil.useFlags();

    const initFeatureFlags = async () => {
        const flags = await flagsPromise;
        setFeatureFlags(flags);
    }

    let googleMapsPromise;

    const loadGoogleMapsAPI = () => {
        const protocol = document.location.protocol === 'https:' ? 'https' : 'http';
        const googleMapsApiSrc = `${protocol}://maps.googleapis.com/maps/api/js?key=${MapUtil.getApiKey()}&libraries=${MapUtil.getLibraries()}&version=${MapUtil.getMapVersion()}`;
        if (!googleMapsPromise) {
            googleMapsPromise = new Promise((resolve, reject) => {
                const script = document.createElement('script');
                script.async = true;
                document.head.appendChild(script);
                script.src = googleMapsApiSrc;
                script.onerror = (err) => {
                    reject(err);
                };
                script.onload = () => {
                    resolve(window.google.maps);
                };
            });
        }
        return googleMapsPromise;
    };

    const initGoogleMapApi = useCallback(async () => {
        if (_.isUndefined(googleMapsApi)) {
            const initApi = await loadGoogleMapsAPI();
            updateGoogleMapsApi(initApi);            
        }
    }, [googleMapsApi]);

    const augmentAutoshopsWithFeatureFlagData = (autoshopsFeatureFlag) => {
        let resultAutoShops = [];
        // delete autoshops
        if (Array.isArray(autoshopsFeatureFlag.deletedOrgIds)) {
            resultAutoShops = autoshopData.auto_shops.filter((autoshop) => {
                return !autoshopsFeatureFlag.deletedOrgIds.includes(autoshop.ORG_ID);
            });
        } else {
            resultAutoShops = autoshopData.auto_shops;
        }

        // add / edit autoshops
        if (Array.isArray(autoshopsFeatureFlag.augmentedData)) {
            WMICArrayUtil.mergeByProperty(resultAutoShops, autoshopsFeatureFlag.augmentedData, 'ORG_ID');
        }

        autoshopData.auto_shops = resultAutoShops;
    };

    const initData = useCallback(() => {
        setLoading(true);

        if (featureFlags) {
            const autoshopsFeatureFlag = WMICFeatureFlagUtil.queryAvailabilityAMP(featureFlags, featureFlagNames.PREFERRED_AUTO_SHOPS);

            augmentAutoshopsWithFeatureFlagData(autoshopsFeatureFlag);
        }

        setLoadDataPromise(autoshopData);
        if (!autoshopData || !Array.isArray(autoshopData.auto_shops)) {
            const errorMessage = 'Error getting autoshops data.';
            throw Error(errorMessage);
        }

        setLoading(false);
    }, [featureFlags]);

    useEffect(() => {
        initFeatureFlags();
        initGoogleMapApi();

    // Only run once when page is init
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (featureFlags) {
            initData();
        }
    }, [featureFlags]);

    const handleShowListOnly = () => {
        setLoading(true);
        setDisplayMode(AUTO_SHOPS.LIST);
        setLoading(false);
    };

    const handleShowMap = () => {
        setLoading(true);
        setDisplayMode(AUTO_SHOPS.MAP);
        setLoading(false);
    };

    const overrideProps = {
        wmicLoader: {
            visible: loading
        },
        WMICAutoShopsListMapViewComponent: {
            visible: !loading && (!displayMode || displayMode === AUTO_SHOPS.MAP),
            onShowListOnly: handleShowListOnly,
            googleMapsApi,
            loadDataPromise
        },
        WMICAutoShopsListViewComponent: {
            visible: !loading && displayMode === AUTO_SHOPS.LIST,
            onShowMap: handleShowMap,
            googleMapsApi,
            loadDataPromise
        },
        WMICAutoShopsServiceErrorComponent: {
            visible: !loading && displayMode === AUTO_SHOPS.SERVICE_ERROR
        }
    };

    const resolvers = {
        resolveComponentMap: {
            WMICAutoShopsListMapViewComponent,
            WMICAutoShopsListViewComponent,
            WMICAutoShopsServiceErrorComponent,
            WMICLoaderComponent: WMICLoader
        },
        resolveClassNameMap: styles
    };

    WMICAutoShopsPage.propTypes = {
        match: PropTypes.shape({}).isRequired
    };

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            overrideProps={overrideProps}
            componentMap={resolvers.resolveComponentMap}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

export default withRouter(WMICAutoShopsPage);

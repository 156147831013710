// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__WMICPageHeader__header{display:flex;justify-content:center;padding:50px 20px 20px}.app__WMICPageHeader__header .app__WMICPageHeader__logo{max-width:18.125em}nav.app__WMICPageHeader__userMenu>ul{margin:0}nav.app__WMICPageHeader__userMenu>ul li{position:relative}nav.app__WMICPageHeader__userMenu>ul li ul{display:none}", "",{"version":3,"sources":["webpack://./node_modules/wmic-components-amp-common-react/WMICPageHeader/WMICPageHeader.module.scss"],"names":[],"mappings":"AAAA,6BACI,YAAA,CACA,sBAAA,CACA,sBAAA,CACA,wDACE,kBAAA,CAKF,qCACI,QAAA,CACA,wCACI,iBAAA,CACA,2CACI,YAAA","sourcesContent":[".header {\n    display        : flex;\n    justify-content: center;\n    padding        : 50px 20px 20px;\n    .logo {\n      max-width: 18.125em; /* 276px*/\n    }\n}\n\nnav.userMenu {\n    > ul {\n        margin: 0;\n        li {\n            position: relative;\n            ul {\n                display: none;\n            }\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "app__WMICPageHeader__header",
	"logo": "app__WMICPageHeader__logo",
	"userMenu": "app__WMICPageHeader__userMenu"
};
export default ___CSS_LOADER_EXPORT___;

import { defineMessages } from 'react-intl';

export default defineMessages({
    passwordResetSuccess: {
        id: 'accountManagement.views.password-reset-success.title',
        defaultMessage: 'Your password was reset!',
    },
    passwordResetSuccessDescription: {
        id: 'accountManagement.views.password-reset-success.description',
        defaultMessage: 'Your password has been successfully reset. Log in now to access your policy(s).',
    },
    loginButton: {
        id: 'accountManagement.views.password-reset-success.log in now',
        defaultMessage: 'Log in now',
    }
 });
import React, { useEffect, useState, useCallback, Fragment, useMemo } from 'react';
import appConfig from 'app-config';
import _ from 'lodash';
import { useTranslator } from '@jutro/locale';
import {
    ModalNext, ModalBody, ModalFooter, useModal
} from '@jutro/components';
import PropTypes from 'prop-types';

import {
    withViewModelService,
    ViewModelForm
} from '@xengage/gw-portals-viewmodel-react';
import { WMICLoader } from 'gw-components-platform-react';
import { useAuthentication } from 'wmic-digital-auth-react';
import { UsersProfileDetailsService } from 'gw-capability-profileinfo';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { WMICRichTextUtil, WMICFeatureFlagUtil, WMICAmpUtil, WMICRemoteLogger } from 'wmic-portals-utils-js';
import { WMICButton, WMICDropdownSelect, WMICAnimatedLoaderButton } from "wmic-components-platform-react";
import {useHistory} from "react-router-dom";
import WMICEditContactModal from './WMICEditContactModal/WMICEditContactModal';
import WMICPaymentInstrumentsList from './WMICPaymentInstrumentsList/WMICPaymentInstrumentsList';
import messages from './WMICUserProfile.messages';
import metadata from './WMICUserProfile.metadata.json5';
import styles from './WMICUserProfile.module.scss';

const { enrollmentUrls } = appConfig;

const SuccessModal = (props) => {
    const {
        isOpen, onResolve, onReject, bodyText, buttonText
    } = props;

    const handleClose = () => {
        onReject();
    };

    return (
        <ModalNext 
            isOpen={isOpen}
            onRequestClose={handleClose} 
            shouldCloseOnEsc
            className={styles.userProfileSuccessModal}>
            <ModalBody>
                <p>{bodyText}</p>
            </ModalBody>
            <ModalFooter>
                <WMICButton onClick={onResolve}>{buttonText}</WMICButton>
            </ModalFooter>
        </ModalNext>
    );
};

SuccessModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onResolve: PropTypes.func.isRequired,
    bodyText: PropTypes.string.isRequired,
    buttonText: PropTypes.string.isRequired
};

function WMICUserProfile(props) {
    const modalApi = useModal();
    const { authHeader, userInfo: authUserData } = useAuthentication();
    const featureFlags = WMICFeatureFlagUtil.getFeatureFlags();
    const translator = useTranslator();
    const { viewModelService } = props;
    const { onValidate, isComponentValid } = useValidation('WMICUserProfile');
    const [loading, updateLoading] = useState(true);
    const [refresh, updateRefresh] = useState(false);
    const [formSaved, setFormSaved] = useState(false);
    const [ldFlags, setLdFlags] = useState({});
    const [contactSummary, updateContactSummary] = useState({});
    const [accountSummary, updateAccountSummary] = useState({});
    const [initialContact, updateInitialContact] = useState({});
    const [distributionType, updateDistributionType] = useState('');
    const [isSaveChangesAnimated, setSaveChangesAnimated] = useState(false);

    const history = useHistory();

    const logger = WMICRemoteLogger.getWMICRemoteLogger(
        'WMICUserProfile',
        history.location.pathname,
        window.location,
        ldFlags,
        authHeader
    );

    const phoneType = useMemo(() => {
        return contactSummary.accountContact ? contactSummary.accountContact.primaryPhoneType.value.code : null;
    }, [contactSummary]);


    const manageBankAccountsAvailable = useCallback(() => {
        return WMICFeatureFlagUtil.queryAvailabilityAMP(ldFlags, featureFlags.MANAGEBANKACCOUNTSINPROFILE).isAvailable;
    },[ldFlags, featureFlags]);

    const getBankAccountTitle = useCallback(() => {
        return translator(messages.savedPaymentMethods);
    }, [translator]);

    const getBankAccountParagraph = useCallback(() => {
        return translator(messages.paymentMethodsCanBeSaved);
    }, [translator]);

    const getPaymentMethodsContent = useCallback(() => {
        if (accountSummary === undefined || _.isEmpty(accountSummary)) {
            return <Fragment/>
        }

        return (
                <WMICPaymentInstrumentsList
                    accountInfo={accountSummary}
                    onReject={()=>{}}
                    onResolve={()=>{}}
                    ldFlags={ldFlags}
                />
            )
    }, [accountSummary])

    const boldText = useCallback((title, value) => {
        return (
            <Fragment>
                <strong>{translator(title)}</strong>
                :&nbsp;
                {value}
            </Fragment>
        );
    }, [translator]);

    const generateAccountDetails = useCallback(() => {
        if (_.isEmpty(contactSummary.value)) {
            return null;
        }

        const {
            firstName, lastName, emailAddress1, prefix
        } = _.get(contactSummary, 'value.accountContact');

        return (
            <div>
                <p>
                    <span>
                        {prefix?.value?.name && `${boldText(messages.prefix, prefix.value.name)} <br />`}
                    </span>
                    {boldText(messages.firstName, _.capitalize(firstName))}
                    <br />
                    {boldText(messages.lastName, _.capitalize(lastName))}
                    <br />
                    {boldText(messages.email, emailAddress1)}
                </p>
                <div className="ww-card-notification-alert ww-card--50w">
                    <strong><span>{WMICRichTextUtil.translateRichText(translator(messages.changeEmail))}</span></strong>
                </div>
            </div>
        );
    }, [contactSummary, translator, boldText]);

    const initFeatureFlags = async () => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const rFlags = await WMICFeatureFlagUtil.useFlags(authUserData);
        setLdFlags(rFlags);
    }

    useEffect(() => {
        initFeatureFlags();
        // Only executed on the first render
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!_.isEmpty(ldFlags)) {
            const makeApiCall = async () => {
                const getContactsSummary = await WMICAmpUtil.getAccountContact(authHeader, authUserData);
                if (getContactsSummary) {
                    const submission = viewModelService.create(
                        getContactsSummary,
                        'pc',
                        'wmic.edge.us.capabilities.profileinfo.user.dto.AccountSummaryDTO'
                    );

                    if (manageBankAccountsAvailable()) {
                        const getAccountSummary = await UsersProfileDetailsService
                            .getAccountSummaryForProfile(authHeader);
                        _.set(getAccountSummary, 'accountContact', getContactsSummary.accountContact);
                        updateAccountSummary(getAccountSummary);
                    }

                    updateContactSummary(submission);
                    updateInitialContact(getContactsSummary.accountContact);
                    updateDistributionType(_.get(getContactsSummary, 'accountContact.prefDistributionMethod_WMIC', ''));
                    updateLoading(false);
                } else {
                    logger.error(new Error(`Contact Not Found for user with grantedAuthorities: ${JSON.stringify(authUserData.grantedAuthorities)}`));
                    history.push('technicalErrorPage/issueWithAccount')
                }
            }
            makeApiCall();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ldFlags]);

    const optionsList = useMemo(() => {
        const options = _.get(contactSummary, 'accountContact.primaryPhoneType.aspects.availableValues', [])
            .map((typeCode) => {
                return {
                    code: typeCode.code,
                    name: translator({
                        id: typeCode.name,
                        defaultMessage: typeCode.name
                    }),
                    ...(typeCode.code === 'nophone_wmic' && {isDisabled: true})
                };
            });
        return options;
    }, [translator, contactSummary]);

    const editModeContact = useCallback(() => {
        modalApi.showModal(
            <WMICEditContactModal
                email={authUserData.email}
                updateRefresh={updateRefresh}
                refresh={refresh}
                distributionType={distributionType}
                updateDistributionType={updateDistributionType}
            />
        );
    }, [contactSummary, distributionType, refresh]);


    const saveChangesButtonState = useMemo(() => {
        const getContact = _.get(contactSummary, 'value.accountContact', {});
        if (!contactSummary || !initialContact) {
            return true;
        }

        const contact = {
            emailAddress1: getContact.emailAddress1 || '',
            primaryPhoneType: getContact.primaryPhoneType || '',
            cellNumber: getContact.cellNumber ? getContact.cellNumber.replace(/-/g, '') : '',
            homeNumber: getContact.homeNumber ? getContact.homeNumber.replace(/-/g, '') : '',
            workNumber: getContact.workNumber ? getContact.workNumber.replace(/-/g, '') : '',
        };

        const defaultContact = {
            emailAddress1: '',
            primaryPhoneType: '',
            cellNumber: '',
            homeNumber: '',
            workNumber: '',
            ...initialContact
        };

        return (
            defaultContact.emailAddress1 === contact.emailAddress1
            && defaultContact.primaryPhoneType === contact.primaryPhoneType
            && defaultContact.cellNumber === contact.cellNumber
            && defaultContact.homeNumber === contact.homeNumber
            && defaultContact.workNumber === contact.workNumber
        );
    }, [initialContact, contactSummary]);

    const showSuccessModal = useCallback((bodyText) => {
        const buttonText = translator(messages.close);
        return modalApi.showModal(
            <SuccessModal buttonText={buttonText} bodyText={bodyText} />
        );
    }, [translator]);

    const saveChanges = useCallback(async () => {
        setSaveChangesAnimated(true);

        const updateContactApi = async () => {
            let bodyText = translator(messages.savedSuccessfully);
            setFormSaved(true);
            try {
                await UsersProfileDetailsService.updateContactSummary(contactSummary.value, authHeader);
                showSuccessModal(bodyText).then(() => updateRefresh(!refresh));
                updateInitialContact(contactSummary.value.accountContact);
            } catch {
                bodyText = translator(messages.somethingWentWrong);
                showSuccessModal(bodyText);
            }
        };
        await updateContactApi();
        setSaveChangesAnimated(false);
        
    }, [authHeader, contactSummary.value, updateInitialContact, setFormSaved, refresh, showSuccessModal, translator]);

    const writeValue = useCallback(
        (value, path) => {
            setFormSaved(false);
            const newContactSummary = _.clone(contactSummary);
            _.set(newContactSummary, path, value);
            updateContactSummary(newContactSummary);
        },
        [contactSummary]
    );

    const getPhonePropsOverride = (getPhoneType) => {
        const isRequired = phoneType === getPhoneType.toLowerCase();
        const isRequiredSymbol = isRequired ? "*" : "";
        const labelText = `${getPhoneType} ${translator(messages.phone)} ${isRequiredSymbol}`;

        const aspects = contactSummary?.accountContact?.homeNumber?.aspects;

        if (aspects) {
            // Define validationMessages property
            if (!aspects.valid) {
                Object.defineProperty(aspects, 'validationMessages', {
                    value: [aspects.validationMessage],
                    writable: true,
                    enumerable: true,
                    configurable: true
                });
            } else {
                Object.defineProperty(aspects, 'validationMessages', {
                    value: []
                });
            }

            if (!aspects.valid && aspects.validationMessage?.includes("This is a required field")) {
                aspects.validationMessages = ["Home Phone is required"];
            }
        }

        return {
            required: isRequired,
            messageProps: {
                invalidPhone: translator(messages.phoneValidationError),
                incompleteInput: translator(messages.phoneValidationError),
                requiredField: `${getPhoneType} ${translator(messages.phone)} is required`
            },
            label: labelText
        };
    };

    const goToChangePasswordPage = () => {
        window.location = enrollmentUrls.resetPassword;
    };

    const overrideProps = {
        changePasswordBtn: {
            onClick: goToChangePasswordPage
        },
        accountDetails: {
            content: generateAccountDetails()
        },
        primaryPhoneField: {
            availableValues: optionsList,
            className: 'primaryPhone',
            value: contactSummary?.accountContact?.primaryPhoneType.value.code,
            onValueChange: (value) => writeValue(value, 'accountContact.primaryPhoneType')
        },
        homeNumberField: getPhonePropsOverride('Home'),
        workNumberField: getPhonePropsOverride('Work'),
        mobileNumberField: getPhonePropsOverride('Mobile'),
        pmocType: {
            content: distributionType
        },
        pmocTypeAria: {
            content: translator(messages.preferredMethodAria, {pmocType: distributionType})
        },
        pmocChangeBtn: {
            onClick: editModeContact
        },
        saveChanges: {
            onClick: saveChanges,
            disabled: (
                saveChangesButtonState
                || !isComponentValid
                || !_.get(contactSummary, 'aspects.valid', true)
                || phoneType === 'nophone_wmic'
                || formSaved
            ),
            isAnimated: isSaveChangesAnimated
        },
        bankAccountDetailsContainer: {
            visible: manageBankAccountsAvailable()
        },
        bankAccountTitle: {
            content: getBankAccountTitle()
        },
        bankAccountParagraph: {
            content: getBankAccountParagraph()
        },
        paymentMethodsContainer: {
            content: getPaymentMethodsContent()
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            WMICButton,
            WMICDropdownSelect,
            WMICAnimatedLoaderButton
        }
    };

    if (loading) {
        return <WMICLoader />;
    }
    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={contactSummary}
            onValueChange={writeValue}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
            componentMap={resolvers.resolveComponentMap}
            onValidationChange={onValidate}
            showErrors={!isComponentValid}
        />
    );
}

WMICUserProfile.propTypes = {
    viewModelService: PropTypes.shape({
        create: PropTypes.func,
        clone: PropTypes.func
    }).isRequired
};

export default withViewModelService(WMICUserProfile);
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__HOPolicyChange__gridPaddingLeft{grid-template-columns:0fr 3fr;padding-bottom:var(--grid-padding-bottom)}.app__HOPolicyChange__checkBoxChange{align-items:flex-start;padding-top:var(--GW-SPACING-5)}h3[class=policyChangeSubHeader]{border-bottom:var(--GW-BORDER-WIDTH) solid var(--GW-BORDER-COLOR);margin-bottom:var(--GW-SPACING-3)}", "",{"version":3,"sources":["webpack://./node_modules/gw-capability-policychange-ho-react/HOPolicyChange.module.scss"],"names":[],"mappings":"AAEA,sCAEI,6BAAA,CADA,yCACA,CAGJ,qCAEI,sBAAA,CADA,+BACA,CAIJ,gCAEI,iEAAA,CADA,iCACA","sourcesContent":["@import \"~@jutro/theme/assets/sass/helpers\";\n\n.gridPaddingLeft{\n    padding-bottom: var(--grid-padding-bottom);\n    grid-template-columns: 0fr 3fr;\n}\n\n.checkBoxChange {\n    padding-top: var(--GW-SPACING-5);\n    align-items: flex-start;\n\n}\n\nh3[class='policyChangeSubHeader'] {\n    margin-bottom: var(--GW-SPACING-3);\n    border-bottom: var(--GW-BORDER-WIDTH) solid var(--GW-BORDER-COLOR);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"gridPaddingLeft": "app__HOPolicyChange__gridPaddingLeft",
	"checkBoxChange": "app__HOPolicyChange__checkBoxChange"
};
export default ___CSS_LOADER_EXPORT___;

import { FNOLConstants } from 'wmic-capability-fnol-react';
import { BaseYourInformationModel } from 'wmic-capability-fnol';

export default class FNOLHOYourInformationModel extends BaseYourInformationModel {
    getType() {
        return FNOLConstants.CLAIM_TYPE.RESIDENTIAL;
    }
}

FNOLHOYourInformationModel.RELATIONSHIP = BaseYourInformationModel.HO_RELATIONSHIP;
FNOLHOYourInformationModel.RELATIONSHIP_OPTIONS = BaseYourInformationModel.HO_RELATIONSHIP_OPTIONS;
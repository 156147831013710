import React, {
    useState,
    useCallback,
    useEffect,
    useRef
} from 'react';
import { MetadataContent } from '@jutro/legacy/uiconfig';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { MarkerClusterer, SuperClusterAlgorithm } from '@googlemaps/markerclusterer';
import { computeDistanceBetween } from 'spherical-geometry-js';
import { PhoneUtil } from '@xengage/gw-portals-viewmodel-js';
import { ServiceManager } from '@jutro/legacy/services';

import WMICAutoShopModel from '../../models/WMICAutoShopModel';
import metadata from './WMICAutoShopsMapComponent.metadata.json5';
import styles from './WMICAutoShopsMapComponent.module.scss';
import messages from './WMICAutoShopsMapComponent.messages';
import mapStyle from './mapStyle.json';
import markerSrc from './images/marker.png';
import selectedMarkerSrc from './images/selectedMarker.png';

const MAX_PINS = 50;
const INITIAL_ZOOM = 4;
const INITIAL_CENTER = { lat: 38.96845787313289, lng: -118.04360916884603 };

function WMICAutoShopsMapComponent(props) {
    const { mountNodeId, googleMapsApi, loadDataPromise, populateResultsListCallback, markerClickCallback, 
        onSetMapInstance, mapViewConfig, selectedOrgId } = props;
    const [previousCenter, setPreviousCenter] = useState(INITIAL_CENTER);
    const [selectedAutoShopOrgId, setSelectedAutoShopOrgId] = useState(undefined);
    const markerClustererInstance = useRef(undefined);
    const mapInstance = useRef(undefined);
    const allMarkers = useRef([]);

    const clusterRenderer = {
        render: ({ count, position }) => {
            // create svg url with fill color
            const svg = window.btoa(`
              <svg fill="#054163" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
                <circle cx="120" cy="120" opacity="1" r="95" />
                <circle cx="120" cy="120" opacity=".8" r="120" />
              </svg>`);
            // create marker using svg icon
            return new googleMapsApi.Marker({
                position,
                icon: {
                    url: `data:image/svg+xml;base64,${svg}`,
                    scaledSize: new googleMapsApi.Size(45, 45),
                },
                label: {
                    text: String(count),
                    color: 'white',
                    fontSize: '11px',
                },
                // adjust zIndex to be above other markers
                zIndex: Number(googleMapsApi.Marker.MAX_ZINDEX) + count,
            });
        },
    };

    const getCenter = (map) => {
        const center = map.getCenter();
        return {
            lat: center.lat(),
            lng: center.lng()
        };
    };

    const shouldPerformSearch = (map) => {
        let result = false;
        if (map) {
            const currentZoom = map.getZoom();
            const currentMapCenter = getCenter(map);
            const mapCenterHasMovedFromStart = ((INITIAL_CENTER.lat !== currentMapCenter.lat) || (INITIAL_CENTER.lng !== currentMapCenter.lng));
            const mapCenterHasMovedFromPrevious = ((previousCenter.lat !== currentMapCenter.lat) || (previousCenter.lng !== currentMapCenter.lng));
            const zoomIsCloseEnough = currentZoom > INITIAL_ZOOM;
            if ((mapCenterHasMovedFromStart && mapCenterHasMovedFromPrevious) || zoomIsCloseEnough) {
                result = true;
            }
        }
        return result;
    };

    const resetMarkerIcons = useCallback(() => {
        if (Array.isArray(allMarkers.current)) {
            allMarkers.current.forEach((marker) => {
                marker.setIcon(markerSrc);
            });
        }
    }, []);

    const convertLocationsToMarkers = useCallback((places) => {
        if (googleMapsApi) {
            const newAllMarkers = places.map((place) => {
                const marker = new googleMapsApi.Marker({
                    position: { lat: Number(place.geoX), lng: Number(place.geoY) },
                    icon: {
                        url: place.orgId === selectedAutoShopOrgId ? selectedMarkerSrc : markerSrc,
                    },
                    title: place.resourceName
                });
    
                marker.addListener('click', () => {
                    if (markerClickCallback && typeof markerClickCallback === 'function') {
                        markerClickCallback(place.orgId);
                    }
                    setSelectedAutoShopOrgId(place.orgId);
                    resetMarkerIcons();
                    marker.setIcon(selectedMarkerSrc);
                });
    
                return marker;
            });
            return newAllMarkers;
        }

    }, [googleMapsApi, markerClickCallback, resetMarkerIcons, selectedAutoShopOrgId]);

    const centreCompare = (baseCoords) => (a, b) => {
        const da = computeDistanceBetween(baseCoords, {lat: a.geoX, lng: a.geoY});
        const db = computeDistanceBetween(baseCoords, {lat: b.geoX, lng: b.geoY});

        if (da < db) {
            return -1;
        }

        if (da > db) {
            return 1;
        }

        a.dist = da;
        b.dist = db;
        
        return 0;
    };

    const handleMapRedraw = useCallback(() => {
        if (mapInstance.current && loadDataPromise && googleMapsApi) {
            setPreviousCenter(getCenter(mapInstance.current));
    
            const bounds = mapInstance.current.getBounds();
    
            // we are using the locations here but it might not have finished loading. Wait on the promise first.
            if (Array.isArray(loadDataPromise.auto_shops)) {
                const loadedData = loadDataPromise.auto_shops
                const localeService = ServiceManager.getService('locale-service');
                const phoneUtil = PhoneUtil();
                const autoshopModels = loadedData.map((shop) => {
                    shop.PHONE = phoneUtil.prettyPrint(shop.PHONE?.toString(), localeService.getDefaultCountryCode());

                    return new WMICAutoShopModel(shop);
                });

                const shopsInView = autoshopModels.filter((shop) => (
                    bounds.contains({
                        lat: parseFloat(shop.geoX),
                        lng: parseFloat(shop.geoY)
                    })
                )).sort(centreCompare(mapInstance.current.getCenter()));
    
                const newShopsInView = shopsInView.slice(0, MAX_PINS);

                const newMarkers = convertLocationsToMarkers(newShopsInView);
                allMarkers.current = newMarkers;
                markerClustererInstance.current.clearMarkers();
                markerClustererInstance.current.addMarkers(newMarkers);

                populateResultsListCallback(newShopsInView);
            }
        }
    }, [loadDataPromise, googleMapsApi]);

    useEffect(() => {
        if (mapViewConfig && mapInstance.current) {
            if (mapViewConfig.zoomLevel && mapInstance.current.getZoom() !== mapViewConfig.zoomLevel) {
                mapInstance.current.setZoom(mapViewConfig.zoomLevel);
            }
            if (mapViewConfig.centerCoordinates) {
                mapInstance.current.panTo(mapViewConfig.centerCoordinates);
            }
            handleMapRedraw();
        }
    }, [mapViewConfig]);

    useEffect(() => {
        if (selectedOrgId && selectedOrgId !== selectedAutoShopOrgId) {
            setSelectedAutoShopOrgId(selectedOrgId);
        }
    }, [selectedOrgId]);

    useEffect(() => {
        try {
            if (googleMapsApi && loadDataPromise) {

                const map = new googleMapsApi.Map(document.getElementById(mountNodeId), {
                    zoom: mapViewConfig.zoomLevel,
                    center: mapViewConfig.centerCoordinates,
                    disableDoubleClickZoom: true,
                    fullscreenControl: false,
                    styles: mapStyle,
                    mapTypeControl: true,
                    mapTypeControlOptions: {
                        mapTypeIds: [
                            googleMapsApi.MapTypeId.ROADMAP,
                            googleMapsApi.MapTypeId.SATELLITE
                        ]
                    }
                });

                googleMapsApi.event.addListener(map, 'idle', () => {
                    if (shouldPerformSearch(map)) {
                        handleMapRedraw();
                    }
                });

                mapInstance.current = map;

                onSetMapInstance(map);

                if (!markerClustererInstance.current) {
                    const newMarkerClusterer = new MarkerClusterer({ map: map, renderer: clusterRenderer, algorithm: new SuperClusterAlgorithm({radius: 80}) });
                    markerClustererInstance.current = newMarkerClusterer;
                }
            }
        } catch (e) {
        }

    }, [googleMapsApi, mountNodeId, loadDataPromise]);

    const overrideProps = {
        
    };

    const resolvers = {
        resolveClassNameMap: styles
    };

    return (
        <MetadataContent
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            {...resolvers} />
    );
}

WMICAutoShopsMapComponent.propTypes = {
    mountNodeId: PropTypes.string.isRequired,
    googleMapsApi: PropTypes.object.isRequired,
    loadDataPromise: PropTypes.object.isRequired,
    populateResultsListCallback: PropTypes.func.isRequired,
    markerClickCallback: PropTypes.func.isRequired,
    onSetMapInstance: PropTypes.func.isRequired,
    mapViewConfig: PropTypes.object.isRequired
};

export default WMICAutoShopsMapComponent;

import { defineMessages } from 'react-intl';

export default defineMessages({
    LoginModelRequired: {
        id: 'wmic.capabilities.emq.models.login-required',
        defaultMessage: 'Policy Number and Last Name are required.'
    },
    VehicleModelRequired: {
        id: 'wmic.capabilities.emq.models.vehicle-required',
        defaultMessage: 'EmqVehicles PolicyNumber, InsuredName and Vehicles are required.'
    },
});

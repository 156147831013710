// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__WMICPaymentHistoryElement__rowElement{padding:.5rem 1.5rem 1rem}.app__WMICPaymentHistoryElement__phoneWidth{width:100%}.app__WMICPaymentHistoryElement__wmic-payment-history-method{display:block!important;font-size:11.5px}.app__WMICPaymentHistoryElement__wmic-payment-history-date{font-weight:600}.app__WMICPaymentHistoryElement__wmic-payment-history-amount{text-align:end}", "",{"version":3,"sources":["webpack://./node_modules/gw-capability-policy-react/WMICBillingInfoBox/WMICPaymentHistoryElement/WMICPaymentHistoryElement.module.scss"],"names":[],"mappings":"AAGA,4CACE,yBAAA,CAGF,4CACE,UAAA,CAGF,6DACE,uBAAA,CACA,gBAAA,CAGF,2DACE,eAAA,CAGF,6DACE,cAAA","sourcesContent":["@import \"~@jutro/theme/assets/sass/helpers\";\n@import \"~wmic-amp-digital-theme-styles/sass/base/config/colors.scss\";\n\n.rowElement {\n  padding: 0.5rem 1.5rem 1rem 1.5rem;\n}\n\n.phoneWidth {\n  width: 100%;\n}\n\n.wmic-payment-history-method {\n  display: block !important;\n  font-size: 11.5px;\n}\n\n.wmic-payment-history-date {\n  font-weight: 600;\n}\n\n.wmic-payment-history-amount {\n  text-align: end;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rowElement": "app__WMICPaymentHistoryElement__rowElement",
	"phoneWidth": "app__WMICPaymentHistoryElement__phoneWidth",
	"wmic-payment-history-method": "app__WMICPaymentHistoryElement__wmic-payment-history-method",
	"wmic-payment-history-date": "app__WMICPaymentHistoryElement__wmic-payment-history-date",
	"wmic-payment-history-amount": "app__WMICPaymentHistoryElement__wmic-payment-history-amount"
};
export default ___CSS_LOADER_EXPORT___;

import React from 'react';
import messages from './Models.messages';
import styles from '../components/WMICAutoShopsListMapViewComponent/WMICAutoShopsListMapViewComponent.module.scss';
import modelStyles from './WMICAutoShopsModel.module.scss';

export default class WMICAutoShopModel {
    constructor({
        RESOURCE_NAME, ORG_ID, TYPE, STREET1, STREET2, CITY, STATE, POSTALCODE, GEOX, GEOY, COUNTRY, PHONE,
        EMAIL, RATING, RATING_COMMENT, IN_NET_WORK
    }) {
        this.resourceName = RESOURCE_NAME;
        this.orgId = ORG_ID;
        this.resourceType = TYPE;
        this.street1 = STREET1;
        this.street2 = STREET2;
        this.city = CITY;
        this.stateProvince = STATE;
        this.postalCode = POSTALCODE;
        this.geoX = GEOX;
        this.geoY = GEOY;
        this.country = COUNTRY;
        this.phone = PHONE;
        this.email = EMAIL;
        this.rating = RATING;
        this.ratingComment = RATING_COMMENT;
        this.inNetwork = IN_NET_WORK;
    }

    toHTML({translator, selectedOrgId, onClickAutoShop, getDirectionsLatLng}) {
        return (
            <li className={ this.orgId === selectedOrgId ? `${styles['active']} ${styles['ww-far-list-entry']} pt-5-5 pb-5-5` : `${styles['ww-far-list-entry']} pt-5-5 pb-5-5`} tabIndex="0" id={`entry-${this.orgId}`} key={`entry-${this.orgId}`}>
                <div className={`${styles['ww-far-list-item']}`}>
                            <a onClick={(event) => onClickAutoShop(event, this.geoX, this.geoY, this.orgId)} href="/" className={`${modelStyles['is-inline-block']}`}>
                                <h3 id={this.orgId} className={`${styles['title']} ${styles['is-6']}`}>{this.resourceName}</h3>
                            </a>
                    <p className={`${styles['ww-far-broker-location']} ${styles['mb-2']}`}>{this.street1}, {this.city}, {this.stateProvince}</p>
                    <div>
                        <a href={`tel:${this.orgId}`} className={styles['has-text-link']} aria-describedby={this.orgId}><i className={`${modelStyles['result-list-icons']} fas fa-phone`} /> {this.phone}</a>
                    </div>
                    <div>
                        <a target="_blank" rel="noreferrer" href={`https://www.google.com/maps/dir/?api=1&destination=${getDirectionsLatLng(this.geoX, this.geoY).lat},${getDirectionsLatLng(this.geoX, this.geoY).lng}`}
                            className={styles['has-text-link']} aria-describedby={this.orgId}><i className={`${modelStyles['result-list-icons']} fas fa-route`} ></i> {translator(messages.autoShopsDirections)}
                        </a>
                    </div>
                </div>
            </li>
        )
    }

    toListViewHtml({translator, getDirectionsLatLng}) {
        return (
            <li className={`${styles['ww-far-list-entry']} pt-5-5 pb-5-5`}  tabIndex="0" id={`entry-${this.orgId}`} key={`entry-${this.orgId}`}>
                <div className={`${styles['ww-far-list-item']}`}>
                    <h3 id={this.orgId} className={`${styles['not-a-link']} ${styles['title']} ${styles['is-6']} mb-0 pb-0`}>{this.resourceName}</h3>
                    <p className={`${styles['ww-far-broker-location']} ${styles['mb-2']}`}>{this.street1}, {this.city}, {this.stateProvince}</p>
                    <div>
                        <a href={`tel:${this.orgId}`} className={styles['has-text-link']} aria-describedby={this.orgId}><i className={`${modelStyles['result-list-icons']} fas fa-phone`} /> {this.phone}</a>
                    </div>
                    <div>
                        <a target="_blank" rel="noreferrer" href={`https://www.google.com/maps/dir/?api=1&destination=${getDirectionsLatLng(this.geoX, this.geoY).lat},${getDirectionsLatLng(this.geoX, this.geoY).lng}`}
                            className={styles['has-text-link']} aria-describedby={this.orgId}><i className={`${modelStyles['result-list-icons']} fas fa-route`}></i> {translator(messages.autoShopsDirections)}
                        </a>
                    </div>
                </div>
            </li>
        )
    }
}

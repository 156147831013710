// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__InvoicesTable__currencyContainer div[class*=fieldComponent]{padding-bottom:0}.app__InvoicesTable__tablePaymentScheduleDetail{padding:0}", "",{"version":3,"sources":["webpack://./node_modules/gw-capability-billing-react/InvoicesTable/InvoicesTable.module.scss"],"names":[],"mappings":"AACI,kEACI,gBAAA,CAGR,gDACI,SAAA","sourcesContent":[".currencyContainer {\n    div[class*='fieldComponent'] {\n        padding-bottom: 0;\n    }\n}\n.tablePaymentScheduleDetail {\n    padding: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"currencyContainer": "app__InvoicesTable__currencyContainer",
	"tablePaymentScheduleDetail": "app__InvoicesTable__tablePaymentScheduleDetail"
};
export default ___CSS_LOADER_EXPORT___;

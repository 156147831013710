import React, {
    useContext, useCallback, useState, useEffect
} from 'react';
import _ from 'lodash';
import { useTranslator } from '@jutro/locale';
import { CurrencyValue } from '@jutro/components';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { ViewModelServiceContext, ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { ClausesUtil } from '@xengage/gw-policycommon-util-js';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { withAuthenticationContext } from 'wmic-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { messages as paymentMessages } from 'gw-capability-policychange-common-react';
import metadata from './CoveragesPage.metadata.json5';
import messages from './CoveragesPage.messages';

const structureCustomQuote = (submissionVM, clauses) => {
    // convert OfferingDTO to CustomQuotedDTO structure
    return {
        coverages: clauses.homeowners
    };
};

const getCustomQuote = (vm, lobPath, lobName, filterChangedClauses = false) => {
    const lobOffering = _.get(vm, `${lobPath}.value`);
    let clausesToUpdate = {
        [lobName]: lobOffering.coverages
    };

    if (filterChangedClauses) {
        clausesToUpdate = ClausesUtil.structureClausesForServer(
            lobOffering.coverages,
            lobName,
            null
        );
    }

    return structureCustomQuote(vm, clausesToUpdate);
};

function CoveragesPage(props) {
    const [loadingClause, updateLoadingClause] = useState();
    const translator = useTranslator();
    const { EndorsementService } = useDependencies('EndorsementService');
    const { wizardData, updateWizardData, authHeader } = props;
    const { submissionVM } = wizardData;
    const {
        initialValidation,
        isComponentValid,
        disregardFieldValidation,
        registerComponentValidation,
        onValidate
    } = useValidation('CoveragesPage');
    const viewModelService = useContext(ViewModelServiceContext);

    const changeSubmission = useCallback(
        (value, changedPath) => {
            _.set(submissionVM, changedPath, value);
            ClausesUtil.setClauseValue(submissionVM, value, changedPath);
            updateWizardData(wizardData);
        },
        [submissionVM, wizardData, updateWizardData]
    );

    const writeValue = useCallback(
        (value, path) => {
            _.set(submissionVM, path, value);
            updateWizardData(wizardData);
        },
        [submissionVM, updateWizardData, wizardData]
    );

    const onUpdateCustomQuote = useCallback(
        (_basePath, lobPath) => {
            const lobName = ClausesUtil.getLobNameFromPath(lobPath);
            const customQuote = getCustomQuote(submissionVM, lobPath, lobName, false);
            return EndorsementService.updateCoverages(
                submissionVM.value.jobID,
                [],
                { homeowners: customQuote.coverages },
                authHeader
            ).then((updatedSubmission) => {
                const changedPath = lobPath.replace(/.children/, '');
                const updatedClauses = _.get(updatedSubmission, `${changedPath}.coverages`);
                const newSubmissionVM = viewModelService.clone(submissionVM);
                _.set(submissionVM, `${lobPath}.coverages`, updatedClauses);
                const removedFieldsFromBaseCoverages = ClausesUtil.getRemovedClausesID(
                    submissionVM,
                    newSubmissionVM,
                    `${lobPath}.coverages.baseCoverages`
                );
                const removedFieldsFromAdditionalCoverages = ClausesUtil.getRemovedClausesID(
                    submissionVM,
                    newSubmissionVM,
                    `${lobPath}.coverages.additionalCoverages`
                );
                const allRemovedFields = [
                    ...removedFieldsFromBaseCoverages,
                    ...removedFieldsFromAdditionalCoverages
                ];
                disregardFieldValidation(allRemovedFields);
                updateWizardData(wizardData);
                updateLoadingClause(undefined);
            });
        },
        [
            authHeader,
            disregardFieldValidation,
            EndorsementService,
            submissionVM,
            updateWizardData,
            viewModelService,
            wizardData
        ]
    );

    const onClauseChange = useCallback(
        (schedule, path) => {
            const lobOfferingPath = 'lobData.homeowners.offerings.children[0]';
            writeValue(schedule, path);
            return onUpdateCustomQuote({}, lobOfferingPath);
        },
        [onUpdateCustomQuote, writeValue]
    );

    const syncClauses = useCallback(
        async (value, changedPath) => {
            const basePath = ClausesUtil.getObjectPathFromChangedPath(changedPath);
            const baseObject = _.get(submissionVM, basePath);
            updateLoadingClause(baseObject.coveragePublicID || baseObject.publicID);
            await onClauseChange();
            updateWizardData(wizardData);
        },
        [onClauseChange, submissionVM, updateWizardData, wizardData]
    );

    const changeSubmissionAndSync = useCallback(
        (value, changedPath) => {
            changeSubmission(value, changedPath);
            syncClauses(value, changedPath);
        },
        [changeSubmission, syncClauses]
    );

    const onNext = useCallback(async () => {
        const updateResponse = await EndorsementService.updateCoverages(
            submissionVM.value.jobID,
            [],
            { homeowners: submissionVM.value.lobData.homeowners.offerings[0].coverages },
            authHeader
        );
        submissionVM.value = await EndorsementService.quoteEndorsement(
            [updateResponse.jobID],
            authHeader
        );
        return wizardData;
    }, [EndorsementService, authHeader, submissionVM.value, wizardData]);

    const convertCurrencyFormat = (basePremium) => {
        return (
            <div>
                <span>{translator(messages.mainCoverages)}</span>
                <span> : </span>
                <CurrencyValue amount={basePremium.amount} currency={basePremium.currency} showFractions />
            </div>
        );
    };

    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            phoneWide: {
                labelPosition: 'top'
            }
        },
        hoPolicyChangeBaseCoveragesId: {
            loadingClause: loadingClause,
            categoryDisplayName: convertCurrencyFormat(
                _.get(submissionVM.value, 'lobData.homeowners.offerings[0].coverages.basePremium')
            )
        },
        hoPolicyChangeAdditionalCoveragesId: {
            loadingClause: loadingClause,
            categoryDisplayName: translator(messages.additionalCoverages)
        }
    };

    const resolvers = {
        resolveCallbackMap: {
            onChangeClause: changeSubmission,
            onSyncCoverages: syncClauses,
            onChangeSubmissionAndSync: changeSubmissionAndSync,
            onValidate: onValidate
        }
    };
    const isPageLoading = useCallback(() => _.isUndefined(loadingClause), [loadingClause]);

    useEffect(() => {
        registerComponentValidation(isPageLoading);
    }, [isPageLoading, registerComponentValidation]);

    return (
        <WizardPage
            showPrevious
            skipWhen={initialValidation}
            disableNext={!isComponentValid}
            onNext={onNext}
            cancelLabel={translator(appConfig.persona === 'policyholder' ? paymentMessages.cancelAllChanges : paymentMessages.cancel)}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                callbackMap={resolvers.resolveCallbackMap}
                onValidationChange={onValidate}
            />
        </WizardPage>
    );
}

CoveragesPage.propTypes = wizardProps;

export default withAuthenticationContext(CoveragesPage);

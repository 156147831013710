import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useTranslator } from '@jutro/locale';
import { Grid, Flex } from '@jutro/layout';
import { Loader } from '@jutro/components';
import { useAuthentication } from 'wmic-digital-auth-react';
import { PolicyDiffService } from 'gw-capability-policyjob';
import messages from './PolicyDiffComponent.messages';
import styles from './PolicyDiffComponent.module.scss';

const MIN_HEADER_LEVEL = 4;

const renderPolicyHeader = (label, children, headerNumber) => {
    const HeaderComponent = `h${headerNumber}`;
    return (
        <div className={styles.policyChangesSection}>
            <HeaderComponent>{label}</HeaderComponent>
            <Grid>
                {children.map((item) => {
                    // eslint-disable-next-line no-use-before-define
                    return renderChildren(item, headerNumber + 1);
                })}
            </Grid>
        </div>
    );
};

const renderPolicyValues = (values) => {
    const { label, value1: beforeValue, value2: afterValue } = values;

    return (
        <Flex justifyContent="between">
            <span>{label}</span>
            <div>
                <span className={styles.oldValue}>{beforeValue}</span>
                <span className={styles.newValue}>{afterValue}</span>
            </div>
        </Flex>
    );
};

const renderChildren = (items, headerNumber) => {
    const { label, children } = items;

    if (children) {
        const sortedChildren = _.sortBy(children, [(child) => (child.children ? 1 : -1)]);
        return renderPolicyHeader(label, sortedChildren, headerNumber);
    }
    return renderPolicyValues(items);
};

function PolicyDiffComponent(props) {
    const { value: jobNumber } = props;
    const { authHeader } = useAuthentication();
    const [policyDiffData, setPolicyDiffData] = useState([]);
    const translator = useTranslator();
    const [isLoading, setLoading] = useState(true);
    useEffect(() => {
        PolicyDiffService.getPolicyDiffWithPrevious(jobNumber, authHeader).then((policyData) => {
            setPolicyDiffData(policyData);
        }).finally(() => setLoading(false));
    // only execute once
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const policyDifference = useMemo(() => {
        if (_.isEmpty(policyDiffData)) {
            return null;
        }
        return (
            <div className={styles.policyChangesSection}>
                <h3 className={styles.policyChangesSectionTitle}>
                    {translator(messages.policyDiffTitle)}
                </h3>
                {policyDiffData.map((item) => renderChildren(item, MIN_HEADER_LEVEL))}
            </div>
        );
    }, [policyDiffData, translator]);
    return (
        <Loader loaded={!isLoading}>
            <Grid vgap="large">
                {policyDifference}
            </Grid>
        </Loader>
    );
}

PolicyDiffComponent.propTypes = {
    value: PropTypes.string.isRequired
};

export default PolicyDiffComponent;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__WMICChangeDesignatedPersonComponent__wmic-title,.app__WMICChangeDesignatedPersonComponent__wmic-title-mobile{font-size:15px!important;font-weight:600!important;line-height:22.5px!important}.app__WMICChangeDesignatedPersonComponent__wmic-title-mobile{max-width:25rem!important}", "",{"version":3,"sources":["webpack://./../common/capabilities-react/gw-capability-policychange-common-react/components/WMICChangeDesignatedPerson/WMICChangeDesignatedPersonComponent.module.scss"],"names":[],"mappings":"AAUA,mHALI,wBAAA,CAEA,yBAAA,CADA,4BAQA,CAJJ,6DAGI,yBACA","sourcesContent":["@import \"~wmic-amp-digital-theme-styles/sass/base/config/colors.scss\";\n@import \"~wmic-amp-digital-theme-styles/sass/customer-colors.scss\";\n@import \"~wmic-amp-digital-theme-styles/sass/customer-variables.scss\";\n\n.wmic-title {\n    font-size: 15px !important;\n    line-height: 22.5px !important;\n    font-weight: 600 !important;\n}\n\n.wmic-title-mobile {\n    font-size: 15px !important;\n    line-height: 22.5px !important;\n    max-width: 25rem !important;\n    font-weight: 600 !important\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wmic-title": "app__WMICChangeDesignatedPersonComponent__wmic-title",
	"wmic-title-mobile": "app__WMICChangeDesignatedPersonComponent__wmic-title-mobile"
};
export default ___CSS_LOADER_EXPORT___;

/* eslint-disable no-warning-comments */
/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useRef, useCallback, useState } from 'react';
import _ from 'lodash';
import headerLogo from 'wmic-amp-digital-theme-styles/images/ww-portal-logo.svg';
import { WMICLink } from 'wmic-components-amp-common-react';
import { WMICAMPNavigationUtil, CONSTANTS, WMICTempStorageService } from 'wmic-portals-utils-js';
import { useHistory, useLocation } from 'react-router-dom';
import { useAuthentication } from 'wmic-digital-auth-react';
import { useTranslator } from '@jutro/locale';
import classNames from 'classnames';
import appConfig from 'app-config';
import messages from './WMICPageHeader.messages';
import styles from './WMICPageHeader.module.scss';

function WMICPageHeader() {
    const { isAuthenticated, logout: _logout, userInfo } = useAuthentication();
    const translator = useTranslator();
    const [showAccountTooltip, setShowAccountTooltip] = useState(false);
    const [showMobileMenu, setShowMobileMenu] = useState(false);
    const [showMobilePageLinks, setShowMobilePageLinks] = useState(false);
    const history = useHistory();
    const { pathname } = useLocation();
    const menuWrapperElement = useRef(null);
    const { oktaConfig } = appConfig;

    const isPublicURLWithHeader =
        window.location.pathname.includes('/auto-claim') ||
        window.location.pathname.includes('/residential-claim') ||
        window.location.pathname.includes('/auto/review') ||
        window.location.pathname.includes('/residential/review') ||
        window.location.pathname.includes('/success') ||
        window.location.pathname.includes('/preferred-autoshops') ||
        window.location.pathname.includes('/technicalErrorPage/fnol') ||
        window.location.pathname.includes('/logout-confirmation');
    const logoLinkClassName = classNames('ww-portal-logo', { 'ww-portal-logo-no-link': pathname === '/home' });
    const resetMobileMenu = (event) => {
        if (menuWrapperElement.current && !menuWrapperElement.current.contains(event.target)) {
            setShowMobileMenu(false);
        }
    };
    const isEnrollment = window.location.pathname.includes('/enrollment');

    useEffect(() => {
        document.addEventListener('click', resetMobileMenu, false);
        return () => {
            document.removeEventListener('click', resetMobileMenu, false);
        };
    }, []);

    const logout = () => {
        _logout();
    };

    const navigateTo = useCallback((evt, path = '/home') => {
        const newPath = path === '/' ? '/home' : path;
        evt.preventDefault();
        setShowMobileMenu(false);
        if (pathname !== newPath) {
            history.push(newPath);
        }
    }, [history, pathname]);

    const getPageLinks = useCallback(() => {
        const linkElements = WMICAMPNavigationUtil.getMobileViewLinks();
        let pageLinks = [];
        pageLinks = _.map(linkElements, (link) => {
            return (
                <li onClick={() => setShowMobilePageLinks(!showMobilePageLinks)}>
                    {link}
                </li>
            );
        });

        if (pageLinks?.length > 0) {
            return (
                <li className={showMobilePageLinks ? 'ww-main-side-menu selected' : 'ww-main-side-menu'}>
                    <WMICLink onClick={() => setShowMobilePageLinks(!showMobilePageLinks)} id="submenu3">
                        {translator(messages.policyMenu)}
                        <i className="fa fa-fw fa-caret-up" id="submenu31" />
                        <i className="fa fa-fw fa-caret-down" id="submenu32" />
                    </WMICLink>
                    <div className="ww-main-menu-flyout">
                        <ul>
                            {pageLinks}
                        </ul>
                    </div>
                </li>
            );
        }
    }, [showMobilePageLinks, translator]);

    const getUserProfileLink = () => (
        <WMICLink
            onClick={() => setShowAccountTooltip(!showAccountTooltip)}
            className='ww-popout-menu__item'
            to={{ pathname: `/userprofile` }}
        >
            {translator(messages.yourProfile)}
        </WMICLink>
    );
    
    const getHomeLink = () => {
        const regex = /\/account-management\/(oregon|california)\/(auto|residential)/;
        const match = regex.exec(window.location.pathname);

        if (!match) {
            return ['/', true];
        }

        const state = match[1]; // first capture group from the regex, will be either oregon or california
        return [`${CONSTANTS.QUICK_LINKS.WMIC_US}${state}`, false]; // 'https://www.wawanesa.com/us/:state'
    }

    const handleHomeNavigation = (evt) => {
        evt.preventDefault();
        WMICTempStorageService.pop(CONSTANTS.PREFILLED_STORAGE_KEYS.PA_POLICY_CHANGE_JOB_NUMBER);
        const [destination, isInternal] = getHomeLink();
        if (isInternal) {
            navigateTo(evt, destination);
            return;
        }

        window.location.href = destination;
    };


    if(isEnrollment) {
        return (
            <header>
                <div className={styles.header} >
                    <img className={styles.logo} src={headerLogo} alt="Wawanesa Insurance"></img>
                </div>
          </header>
        )
    }

    // eslint-disable-next-line max-len
    return (
        (isAuthenticated || isPublicURLWithHeader) ?
        (
            <header className="ww-site-header">
                <div className="ww-content-limit">
                    <a
                        href={getHomeLink()[0]}
                        className={logoLinkClassName}
                        onClick={handleHomeNavigation}
                    >
                        <img src={headerLogo} alt="Wawanesa Insurance" className="ww-portal-logo" />
                    </a>
                    <div className="ww-header-content no-print">
                        { (!isPublicURLWithHeader && isAuthenticated) &&
                            (
                                <nav id="user-menu" className={styles.userMenu} aria-label="Profile admin navigation">
                                    <ul>
                                        <li>
                                            <button className="ww-profile-trigger ww-link-button" aria-expanded={showAccountTooltip} onClick={() => setShowAccountTooltip(!showAccountTooltip)}>
                                                <div className="ww-profile-root">
                                                    <div className="ww-header-secondary-content" aria-label={translator(messages.helloAria, {name: userInfo.name})}>
                                                        <span aria-hidden>{translator(messages.hello)}</span>
                                                        <br />
                                                        <span aria-hidden id="ww-user-name">
                                                            {userInfo.name}
                                                        </span>
                                                    </div>
                                                    <div className="ww-profile-icon-root">
                                                        <span className="">
                                                            <svg
                                                                enableBackground="new 0 0 26 26"
                                                                id="ww-profile-icon"
                                                                version="1.1"
                                                                viewBox="0 0 26 26"
                                                                xmlSpace="preserve"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                                                height={30}
                                                                width={30}
                                                            >
                                                                <path
                                                                    d="M25,13c0-6.6166992-5.3828125-12-12-12S1,6.3833008,1,13c0,3.383606,1.413208,6.4386597,3.673645,8.6222534  c0.0529175,0.0689087,0.1156006,0.1247559,0.1889648,0.171814C7.0038452,23.7769165,9.8582764,25,13,25  s5.9961548-1.2230835,8.1373901-3.2059326c0.0733643-0.0470581,0.1360474-0.1029053,0.1889648-0.171814  C23.586792,19.4386597,25,16.383606,25,13z M13,2.5c5.7900391,0,10.5,4.7104492,10.5,10.5  c0,2.4549561-0.8532715,4.7108154-2.2702637,6.5008545c-0.6505127-2.0978394-2.5076294-3.7401123-5.0281372-4.4957886  c1.3735962-0.9940796,2.2720337-2.6046143,2.2720337-4.4244995c0-3.0141602-2.4550781-5.4663086-5.4736328-5.4663086  s-5.4736328,2.4521484-5.4736328,5.4663086c0,1.8198853,0.8984375,3.4304199,2.2720337,4.4244995  c-2.5205078,0.7556763-4.3776245,2.3979492-5.0281372,4.4957886C3.3532715,17.7108154,2.5,15.4549561,2.5,13  C2.5,7.2104492,7.2099609,2.5,13,2.5z M9.0263672,10.5805664c0-2.1870117,1.7822266-3.9663086,3.9736328-3.9663086  s3.9736328,1.7792969,3.9736328,3.9663086S15.1914063,14.546875,13,14.546875S9.0263672,12.7675781,9.0263672,10.5805664z   M6.0307617,20.8319702C6.2562256,18.0820313,9.1723633,16.046875,13,16.046875s6.7437744,2.0351563,6.9692383,4.7850952  C18.1130981,22.4855347,15.6757202,23.5,13,23.5S7.8869019,22.4855347,6.0307617,20.8319702z"
                                                                    fill="#1D1D1B"
                                                                />
                                                            </svg>
                                                        </span>
                                                        <span className="ww-link-button ww-profile-down-arrow">
                                                            <i className="fa fa-fw fa-caret-down" />
                                                        </span>
                                                    </div>
                                                </div>
                                            </button>
                                            <ul className={`ww-popout-menu ${showAccountTooltip ? 'active' : ''}`}>
                                                <li>
                                                    {getUserProfileLink()}
                                                </li>
                                                <li>
                                                    <button className='ww-link-button ww-popout-menu__item' onClick={() => logout()}>{translator(messages.logout)}</button>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </nav>
                            )
                        }
                    </div>
                </div>
                <div className="ww-main-menu no-print" id="wmicMainMenu">
                    <div className="ww-content-limit">
                        <ul className={`ww-header-nav ${showMobileMenu ? 'ww-mobile-active' : ''}`} tabIndex="-1" ref={showMobileMenu ? menuWrapperElement : undefined}>
                            <li className="ww-main-menu-root">
                                <a className='ww-home' href={getHomeLink()[0]} onClick={handleHomeNavigation}>{translator(messages.home)}</a>
                            </li>
                            <li className="ww-main-menu-toggle">
                                { isAuthenticated &&
                                    (
                                        <button className='ww-menu-mobile-item' onClick={() => { setShowMobileMenu(!showMobileMenu); setShowMobilePageLinks(false); }}>
                                            <i id="submenu1" className="fa fa-fw fa-bars" />
                                            <span>{translator(messages.menu)}</span>
                                        </button>
                                    )
                                }
                            </li>
                            <li className="ww-main-menu-mobile">
                                <a href={getHomeLink()[0]} className="ww-menu-mobile-item" onClick={handleHomeNavigation}>{translator(messages.home)}</a>
                            </li>
                            {getPageLinks()}
                            <li className="ww-main-menu-mobile">
                                <button onClick={(evt) => navigateTo(evt, '/userprofile')} className="ww-link-button ww-menu-mobile-item">
                                    <svg enableBackground="new 0 0 26 26" id="ww-profile-mobile-icon" version="1.1" viewBox="0 0 26 26" xmlSpace="preserve" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"><path d="M25,13c0-6.6166992-5.3828125-12-12-12S1,6.3833008,1,13c0,3.383606,1.413208,6.4386597,3.673645,8.6222534  c0.0529175,0.0689087,0.1156006,0.1247559,0.1889648,0.171814C7.0038452,23.7769165,9.8582764,25,13,25  s5.9961548-1.2230835,8.1373901-3.2059326c0.0733643-0.0470581,0.1360474-0.1029053,0.1889648-0.171814  C23.586792,19.4386597,25,16.383606,25,13z M13,2.5c5.7900391,0,10.5,4.7104492,10.5,10.5  c0,2.4549561-0.8532715,4.7108154-2.2702637,6.5008545c-0.6505127-2.0978394-2.5076294-3.7401123-5.0281372-4.4957886  c1.3735962-0.9940796,2.2720337-2.6046143,2.2720337-4.4244995c0-3.0141602-2.4550781-5.4663086-5.4736328-5.4663086  s-5.4736328,2.4521484-5.4736328,5.4663086c0,1.8198853,0.8984375,3.4304199,2.2720337,4.4244995  c-2.5205078,0.7556763-4.3776245,2.3979492-5.0281372,4.4957886C3.3532715,17.7108154,2.5,15.4549561,2.5,13  C2.5,7.2104492,7.2099609,2.5,13,2.5z M9.0263672,10.5805664c0-2.1870117,1.7822266-3.9663086,3.9736328-3.9663086  s3.9736328,1.7792969,3.9736328,3.9663086S15.1914063,14.546875,13,14.546875S9.0263672,12.7675781,9.0263672,10.5805664z   M6.0307617,20.8319702C6.2562256,18.0820313,9.1723633,16.046875,13,16.046875s6.7437744,2.0351563,6.9692383,4.7850952  C18.1130981,22.4855347,15.6757202,23.5,13,23.5S7.8869019,22.4855347,6.0307617,20.8319702z" fill="#1D1D1B"/></svg>
                                    {translator(messages.yourProfile)}
                                </button>
                            </li>
                            <li className="ww-main-menu-mobile">
                                <button className="ww-menu-mobile-item" onClick={() => logout()}>{translator(messages.logout)}</button>
                            </li>
                        </ul>
                    </div>
                </div>
            </header>
        ) : null
    )
}

export default WMICPageHeader;

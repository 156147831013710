module.exports = {
    /*
        Customer can override the sticky session id and can generate their own session id.

        // This param should be string value
        stickySessionName: 'X_GWRE_SESSION',

        // This should be a function, which return string value
        customerGenerateSessionID: function customerGenerateSessionID(){
        return `${Math.random()}`;
    }
    */

}

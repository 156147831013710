import 'ie-array-find-polyfill';
import Bowser from 'bowser';

// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';

function isBrowserSupported() {
    var blacklistedBrowsers = appConfig.browserBlacklist;

    var browser = Bowser.getParser(window.navigator.userAgent);
    var currentBrowser = browser.getBrowserName();
    if(blacklistedBrowsers){
        var browserConfigExists = currentBrowser in blacklistedBrowsers;
        var browserSupported = !browserConfigExists;

        if (browserConfigExists) {
            var unsupportedVersion = blacklistedBrowsers[currentBrowser];
            browserSupported = browser.compareVersion('>' + unsupportedVersion);
        }

        return browserSupported;
    } else {
        return true;
    }
}

function browserSupport() {
    if (!isBrowserSupported()) {
        var pageBody = document.body;

        // Create text container, style, and add text
        var containerDiv = document.createElement('div');
        containerDiv.style.cssText = 'width: 50%; margin: 5% auto; font-size: 2rem; text-align: left;';
    
        var warningTitle = document.createElement('h2');
        warningTitle.innerHTML = 'We\'re sorry';
        warningTitle.style.cssText = 'text-align: center;';
    
        var warningText = document.createElement('p');
        warningText.innerHTML = 'Your browser is not supported.';
        warningText.style.cssText = 'text-align:center;margin-bottom: 20px;';
    
        var subWarningText = document.createElement('p');
        subWarningText.innerHTML = 'We recommend using the latest version of one of these browsers:';
        subWarningText.style.cssText = 'font-size: 20px;';
    
        // // Browser List
        var browserList = document.createElement('ul');
        browserList.style.cssText = 'font-size: 20px;padding-left:17px;';
        
        var browserNames = ['Chrome',  'Firefox', 'Microsoft Edge', 'Safari'];
    
        for(var i = 0; i < browserNames.length; i++) {
            var browserListItem = document.createElement('li');
            browserListItem.style.cssText = 'list-style-type:circle;';
            browserListItem.textContent = browserNames[i];
            browserList.appendChild(browserListItem);
        }
    
        // Append elements
        containerDiv.appendChild(warningTitle);
        containerDiv.appendChild(warningText);
        containerDiv.appendChild(subWarningText);
        containerDiv.appendChild(browserList);
    
        // Put text container in the body tag
        pageBody.appendChild(containerDiv);
    
        // Remove application container
        var appContainerDiv = document.getElementById('root');
        pageBody.removeChild(appContainerDiv);
    }
}

export default browserSupport;
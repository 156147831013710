import _ from 'lodash';
import htmlParser from 'html-react-parser';

const checkSafeMatching = (phrase) => {
    return _.unescape(phrase.replace(/<[^<>]+>/gi, ''));
};

const translateRichText = (phrase = '') =>{
    if (typeof phrase === 'string') {
        return htmlParser(checkSafeMatching(phrase));
    }
    return '';
}
export { translateRichText }
import React, { useMemo } from 'react';
import { useTranslator } from '@jutro/locale';
import { InlineNotification } from '@jutro/components';
import { WMICRichTextUtil } from 'wmic-portals-utils-js';
import messages from './ValidationErrorMessage.messages';

const ValidateErrorMessage = props => {
    const { showMessage } = props;
    const translator = useTranslator();

    const messageText = useMemo(() => {
        let msg = '';
        const els = document.querySelectorAll('[required]');

        if (els && showMessage) {
            for (const el of els) {
                if (!el.validity.valid) {
                    const lbl = document.querySelector(
                        `label[for=${el.id}]`
                    ).innerText;

                    msg += `&lt;li&gt;${  lbl  }&lt;/li&gt;`;
                }
            }
        }

        return msg;
    }, [showMessage]);

    return (
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        <div
            aria-live="assertive"
            tabIndex="0"
            className={messageText ? '' : 'is-sr-only'}
        >
            <InlineNotification
                type="error"
                className="is-size-4 p-3 mb-4 ww-error-block"
                message={WMICRichTextUtil.translateRichText(translator(messages.validationRequaredList, {
                    fieldsList: messageText,
                }))}
                isDismissable={false}
            />
        </div>
    );
};

export default ValidateErrorMessage;

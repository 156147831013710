import { defineMessages } from 'react-intl';

export default defineMessages({
    enterYourDetailsHeader: {
        id: 'enrollment.components.enrollment.Enter Your Details',
        defaultMessage: 'Enter Your Details'
    },
    enroll: {
        id: 'enrollment.components.enrollment.Enroll',
        defaultMessage: 'Enroll'
    },
    enrollmentAddressLine1: {
        id: 'enrollment.components.enrollment.Address Line 1:',
        defaultMessage: 'Address Line 1:'
    },
    enrollmentPolicyNumber: {
        id: 'enrollment.components.enrollment.Policy Number:',
        defaultMessage: 'Policy Number:'
    },
    returnToHomePage: {
        id: 'enrollment.components.enrollment.Return to Home Page',
        defaultMessage: 'Return to Home Page'
    },
    enrollmentFailed: {
        id: 'enrollment.components.enrollment.Sorry we cannot process your enrollment request at this time',
        defaultMessage: 'Sorry we cannot process your enrollment request at this time'
    },
    enrollmentInvalid: {
        id: 'enrollment.components.enrollment.Sorry your enrollment request is invalid',
        defaultMessage: 'Sorry your enrollment request is invalid'
    },
    enrollmentSuccess: {
        id: 'enrollment.components.enrollment.Enrollment Successful',
        defaultMessage: 'Enrollment Successful'
    },
    fieldEmpty: {
        id: 'enrollment.components.enrollment.Enter your policy number and the first line of your address to associate your policy with this account',
        defaultMessage: 'Enter your policy number and the first line of your address to associate your policy with this account'
    },
    accessToYourPolicies: {
        id: 'enrollment.components.enrollment.You now have access to your policies:',
        defaultMessage: 'You now have access to your policies:'
    },
    accessToYourPolicy: {
        id: 'enrollment.components.enrollment.You now have access to your policy:',
        defaultMessage: 'You now have access to your policy:'
    }
});

import { EMQ_USAGE_TYPES, EMQ_VEHICLE_PROPS } from 'wmic-portals-utils-js/StringConstants';
export default class WMICVehicleInfo {
    constructor({
        AnnualMileage, CommutingMiles, DaysPerWeekCommute, OdometerReading, PrimaryUse,
        Vin, VehicleName, VehicleNumber
    }) {
        this.AnnualMileage = this.convertFieldToString(AnnualMileage);
        this.CommutingMiles = this.convertFieldToString(CommutingMiles);
        this.DaysPerWeekCommute = this.convertFieldToString(DaysPerWeekCommute);
        this.OdometerReading = this.convertFieldToString(OdometerReading);
        this.PrimaryUse = PrimaryUse;
        this.Vin = Vin;
        this.VehicleName = VehicleName;
        this.VehicleNumber = VehicleNumber;
    }

    // eslint-disable-next-line class-methods-use-this
    convertFieldToString(field) {
        if (!field) {
            field = '';
        }
        return field.toString();
    }

    isValid() {
        const required = [this.PrimaryUse, this.Vin, this.VehicleName, this.VehicleNumber];
        const fields = [EMQ_VEHICLE_PROPS.PRIMARYUSE, EMQ_VEHICLE_PROPS.VIN, EMQ_VEHICLE_PROPS.VEHICLENAME, EMQ_VEHICLE_PROPS.VEHICLENUMBER];
        if (!(this.PrimaryUse === undefined || this.PrimaryUse === null || this.PrimaryUse === '')) {
            if (this.PrimaryUse === EMQ_USAGE_TYPES.COMMUTING) {
                fields.push(EMQ_VEHICLE_PROPS.ODOMETERREADING, EMQ_VEHICLE_PROPS.ANNUALMILEAGE, EMQ_VEHICLE_PROPS.COMMUTINGMILES, EMQ_VEHICLE_PROPS.DAYSPERWEEKCOMMUTE);
                required.push(this.OdometerReading, this.AnnualMileage, this.CommutingMiles, this.DaysPerWeekCommute);
            } else {
                fields.push(EMQ_VEHICLE_PROPS.ODOMETERREADING, EMQ_VEHICLE_PROPS.ANNUALMILEAGE);
                required.push(this.OdometerReading, this.AnnualMileage);
            }
        }

        if (!required.every((v) => v)) {
            const emptyFields = fields.filter((r) => (!this[r])).join(', ');
            return false;
        }

        return true;
    }
}

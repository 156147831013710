import { defineMessages } from 'react-intl';

export default defineMessages({
    EMQLandingPageIntroductionText: {
        id: 'wmic.emq.landing-page.introduction-text',
        defaultMessage: 'From time to time, we are required by the state of California to request the estimated annual mileage for each of the vehicles you have insured with us.'
    },
    EMQLandingPageFollowingInformation: {
        id: 'wmic.emq.landing-page.following-instructions',
        defaultMessage: 'Before you start, you will need the following information for '
    },
    EMQLandingPageEachVehicle: {
        id: 'wmic.emq.landing-page.each-vehicle',
        defaultMessage: 'each vehicle:'
    },
    EMQLandingPagePolicyNumber: {
        id: 'wmic.emq.landing-page.ul.policy-number',
        defaultMessage: 'Auto Policy Number'
    },
    EMQLandingPageOdometerReading: {
        id: 'wmic.emq.landing-page.ul.odometer-reading',
        defaultMessage: 'Current odometer reading'
    },
    EMQLandingPageEstimatedMiles: {
        id: 'wmic.emq.landing-page.ul.estimated-miles',
        defaultMessage: 'Estimated number of miles for next 12 months'
    },
    EMQLandingPagePrimaryUse: {
        id: 'wmic.emq.landing-page.ul.primary-use',
        defaultMessage: 'Primary use'
    },
    EMQLandingPageDaysPerWeek: {
        id: 'wmic.emq.landing-page.ul.days-per-week',
        defaultMessage: 'Number of days per week driven'
    },
    EMQLandingPageMilesDriven: {
        id: 'wmic.emq.landing-page.ul.miles-driven',
        defaultMessage: 'Number of miles driven one-way'
    },
    EMQLandingPageHelpText: {
        id: 'wmic.emq.landing-page.help-finding',
        defaultMessage: 'Need help finding your policy number? '
    },
    EMQLandingPageClickHere: {
        id: 'wmic.emq.landing-page.click-here',
        defaultMessage: 'Click Here'
    },
    EMQLandingPageCardTitle: {
        id: 'wmic.emq.landing-page.card-title',
        defaultMessage: 'Start by finding your policy'
    },
    EMQLandingPageCardFormAuto: {
        id: 'wmic.emq.landing-page.card-form-auto',
        defaultMessage: 'Auto Policy Number '
    },
    EMQLandingPageCardFormName: {
        id: 'wmic.emq.landing-page.card-form-name',
        defaultMessage: 'Last Name '
    },
    EMQLandingPageCardFormButtonText: {
        id: 'wmic.emq.landing-page.card-next',
        defaultMessage: 'Next'
    },
    EMQLandingPageCardErrorText: {
        id: 'wmic.emq.landing-page.card-error',
        defaultMessage: 'The Auto Policy Number or Last Name is incorrect.'
    },
    EMQLandingPageLeftColumnThe: {
        id: 'wmic.emq.landing-page.the',
        defaultMessage: 'The '
    },
    EMQLandingPageLeftColumnOr: {
        id: 'wmic.emq.landing-page.or',
        defaultMessage: 'or '
    },
    EMQLandingPageLeftColumnError1: {
        id: 'wmic.emq.landing-page.error1',
        defaultMessage: 'associated with the account does not match. '
    },
    EMQLandingPageLeftColumnError2: {
        id: 'wmic.emq.landing-page.error2',
        defaultMessage: 'Please check your policy documents and enter the information again, or give us a call at '
    },
    EMQLandingPageLeftColumnPhone: {
        id: 'wmic.emq.landing-page.phone',
        defaultMessage: '1-800-640-2920'
    },
});

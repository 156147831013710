// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__WMICOneIncPaymentModal__modalHeaderBodyContainer{overflow:auto}.app__WMICOneIncPaymentModal__modalBodyContainer{padding:20px 25px}.app__WMICOneIncPaymentModal__modalBodyContainer>a{font-size:1.3rem!important}.app__WMICOneIncPaymentModal__maskBackground{background-color:var(--GW-MODAL-OVERLAY-BACKGROUND-COLOR);height:100vh;left:0;position:fixed;top:0;-webkit-user-select:none;-moz-user-select:none;user-select:none;width:100vw;z-index:900}", "",{"version":3,"sources":["webpack://./node_modules/wmic-components-amp-common-react/WMICOneIncPaymentModal/WMICOneIncPaymentModal.module.scss"],"names":[],"mappings":"AAAA,uDACI,aAAA,CAGJ,iDACI,iBAAA,CACA,mDACI,0BAAA,CAIR,6CAQI,yDAAA,CAFA,YAAA,CAJA,MAAA,CADA,cAAA,CAEA,KAAA,CAIA,wBAAA,CAAA,qBAAA,CAAA,gBAAA,CAFA,WAAA,CADA,WAIA","sourcesContent":[".modalHeaderBodyContainer {\n    overflow: auto;\n}\n\n.modalBodyContainer {\n    padding: 20px 25px;\n    &>a {\n        font-size: 1.3rem !important;\n    }\n}\n\n.maskBackground {\n    position: fixed;\n    left: 0;\n    top: 0;\n    z-index: 900;\n    width: 100vw;\n    height: 100vh;\n    user-select: none;\n    background-color: var(--GW-MODAL-OVERLAY-BACKGROUND-COLOR);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalHeaderBodyContainer": "app__WMICOneIncPaymentModal__modalHeaderBodyContainer",
	"modalBodyContainer": "app__WMICOneIncPaymentModal__modalBodyContainer",
	"maskBackground": "app__WMICOneIncPaymentModal__maskBackground"
};
export default ___CSS_LOADER_EXPORT___;

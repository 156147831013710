import { defineMessages } from 'react-intl';

export default defineMessages({
    reviewContactInfoWarningMsg: {
        id: 'portal.views.review-contact-info.reviewContactInfoWarningMsg',
        defaultMessage: 'Review your contact information'
    },
    updateMsg: {
        id: 'portal.views.review-contact-info.updateMsg',
        defaultMessage: 'Update your information so we can contact you with critical updates on your policy'
    },
    confirm: {
        id: 'portal.views.review-contact-info.confirm',
        defaultMessage: 'Confirm my information'
    },
    confirmTrackButtonIdentifier: {
        id: 'portal.views.review-contact-info.tracking.confirm',
        defaultMessage: 'Home Page - Calls to action - Periodic Update - Confirm'
    },
    secondaryTrackButtonIdentifier: {
        id: 'portal.views.review-contact-info.tracking.secondary button',
        defaultMessage: 'Home Page - Calls to action - Periodic Update - {buttonText}'
    },
    askMeLater: {
        id: 'portal.views.review-contact-info.askMeLater',
        defaultMessage: 'Ask me later'
    },
    reviewYourContactInfo: {
        id: 'portal.views.review-contact-info.reviewYourContactInfo',
        defaultMessage: 'Review your contact information'
    },
    pleaseReview: {
        id: 'portal.views.review-contact-info.pleaseReview',
        defaultMessage: 'Please review your primary contact information'
    },
    havingTheRightInfo: {
        id: 'portal.views.review-contact-info.hasAnOverdueAmountWhichIsInactive',
        defaultMessage: 'Having the right information on file allows us to contact you about important account details and notifications. Please let us know if you would like to change any of the following details.'
    },
    phoneNumber: {
        id: 'portal.views.review-contact-info.phoneNumber',
        defaultMessage: '&lt;strong&gt;Phone number: &lt;/strong&gt;{phoneNumber}'
    },
    email: {
        id: 'portal.views.review-contact-info.email',
        defaultMessage: '&lt;strong&gt;Email address: &lt;/strong&gt;{email}'
    },
    address: {
        id: 'portal.views.review-contact-info.address',
        defaultMessage: '&lt;strong&gt;Mailing address: &lt;/strong&gt;&lt;br/&gt;{address1}&lt;br/&gt;{address2}&lt;br/&gt;{address3}'
    },
    addressAria: {
        id: 'portal.views.review-contact-info.addressAria',
        defaultMessage: 'Mailing address: {address1}, {city}, {state}, {zipCode}'
    },
    noPhone: {
        id: 'portal.views.review-contact-onfo.NoPhone',
        defaultMessage: 'No phone'
    },
    toUpdate: {
        id: 'portal.views.review-contact-info.policyHasAnOverdueAmount',
        defaultMessage: 'To update your email or contact address contact us:'
    },
    dot: {
        id: 'portal.views.review-contact-info.dot',
        defaultMessage: '&#8226;'
    },
    contactNumber1: {
        id: 'portal.views.review-contact-info.contactNumber2',
        defaultMessage: ' 1-800-640-2920'
    },
    contactNumberAria: {
        id: 'portal.views.review-contact-info.contactNumberAria',
        defaultMessage: ' Call us at 1-800-640-2920'
    },
    contactNumber2: {
        id: 'portal.views.review-contact-info.contactNumber3',
        defaultMessage: ' Monday - Friday 7:30 AM - 7:30 PM and Saturday 8:00 AM - 4:30 PM&lt;br/&gt;'
    },
    mondayToFridayAria: {
        id: 'portal.views.review-contact-info.mondayToFridayAria',
        defaultMessage: ' Monday to Friday 7:30 AM to 7:30 PM and Saturday 8:00 AM to 4:30 PM'
    },
    contactMail: {
        id: 'portal.views.review-contact-info.contactMail',
        defaultMessage: ' service.us@wawanesa.com'
    },
    contactMailAria: {
        id: 'portal.views.review-contact-info.contactMailAria',
        defaultMessage: ' Email us at service.us@wawanesa.com'
    },
    edit: {
        id: 'portal.views.review-contact-info.Edit',
        defaultMessage: 'Edit'
    },
    editAria: {
        id: 'portal.views.review-contact-info.EditAria',
        defaultMessage: 'Edit my phone number'
    },
    editTrackButtonIdentifier: {
        id: 'portal.views.review-contact-info.tracking.Edit',
        defaultMessage: 'Home Page - Calls to action - Periodic Update - Edit Phone'
    },
    cancel: {
        id: 'portal.views.review-contact-info.Cancel',
        defaultMessage: 'Cancel'
    },
    updatePhoneTrackButtonIdentifier: {
        id: 'portal.views.review-contact-info.tracking.UpdatePhone',
        defaultMessage: 'Home Page - Calls to action - Periodic Update - Update Phone - {buttonText}'
    },
    periodicUpdateTrackButtonIdentifier: {
        id: 'portal.views.review-contact-info.tracking.PeriodicUpdate',
        defaultMessage: 'Home Page - Calls to action - Periodic Update - {buttonText}'
    },
    saveChanges: {
        id: 'portal.views.review-contact-info.saveChanges',
        defaultMessage: 'Save Changes'
    },
    saveChangesTrackButtonIdentifier: {
        id: 'portal.views.review-contact-info.tracking.saveChanges',
        defaultMessage: 'Home Page - Calls to action - Periodic Update - Update Phone - Save Changes'
    },
    updateContactInfo: {
        id: 'policy.common.components.callsToAction.types.periodic-update.update-phone.Update your contact information',
        defaultMessage: 'Update your contact information'
    }
});

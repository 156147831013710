import { defineMessages } from 'react-intl';


export default defineMessages({
    FNOLHOSuccessTitle: {
        id: 'wmic.fnol-success.ho.title',
        defaultMessage: 'Claim Successfully Submitted'
    },
    FNOLHOSuccessThanksText: {
        id: 'wmic.fnol-success.ho.thanks-text',
        defaultMessage: 'Thank you for submitting your claim online. A member of our claims team will contact you within the next business day.'
    },
    FNOLHOSuccessRecordTrackingNumberText: {
        id: 'wmic.fnol-success.ho.record-tracking-text',
        defaultMessage: 'Please record the number below to use as reference until a claim number is confirmed for you by a member of our claims team.'
    },
    FNOLHOSuccessAvailablityText: {
        id: 'wmic.fnol-success.ho.availability-text',
        defaultMessage: '&lt;span&gt;We are available 24 hours a day, 7 days a week at &lt;a href="tel:888-929-2252"&gt;888-WAWA-CLAIM (929-2252)&lt;/a&gt;&lt;/span&gt;'
    },
    FNOLHOSuccessClaimEmergencyText: {
        id: 'wmic.fnol-success.ho.claim-emergency-text',
        defaultMessage: 'If you are experiencing a claim emergency (i.e. you have a need for a tow or rental) please call immediately for assistance.'
    },
    FNOLHOSuccessPrintClaimText: {
        id: 'wmic.fnol-success.ho.print-claim-text',
        defaultMessage: 'Print Claim'
    },
    FNOLHOSuccessContactText: {
        id: 'wmic.fnol-success.ho.contact-text',
        defaultMessage: '&lt;span&gt;For your other insurance needs, our representatives are available Monday through Friday, 7:30am - 7:30pm PT, and on Saturday from 8:00am - 4:30pm PT at &lt;a href="tel:800-640-2920"&gt;800-640-2920&lt;/a&gt;.&lt;/span&gt;'
    }
})

import { defineMessages } from 'react-intl';

export default defineMessages({
    NewScheduledPersonalProperty: {
        id: 'endorsement.common.view.New Scheduled Personal Property',
        defaultMessage: 'New Scheduled Personal Property'
    },
    Type: {
        id: 'endorsement.ho.directives.templates.ho-scheduled-properties-edit.Type',
        defaultMessage: 'Type'
    },
    Price: {
        id: 'endorsement.common.view.Price',
        defaultMessage: 'Price'
    },
    Deductible: {
        id: 'endorsement.common.view.Deductible',
        defaultMessage: 'Deductible'
    },
    ValuationMethod: {
        id: 'endorsement.common.view.Valuation Method',
        defaultMessage: 'Valuation Method'
    }
});

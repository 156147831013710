import React, { useState, useCallback } from 'react';
import { MetadataForm } from '@jutro/legacy/uiconfig';
import { withRouter } from 'react-router-dom';
import { useTranslator } from '@jutro/locale';
import { WMICAnimatedLoaderButton } from 'wmic-components-platform-react';
import { WMICReCaptchaService } from 'wmic-portals-recaptcha-js';

import { translateRichText } from '../utils/WMICRichTextUtil';

import CheckEmailRegistrationDialog from '../dialogs/CheckEmailRegistrationDialog/CheckEmailRegistrationDialog';
import EnrollmentService from '../services/EnrollmentService';
import ErrorCodes from '../utils/WMICEnrollmentErrorCodes';

import metadata from './ResetPasswordPage.metadata.json5';
import styles from './ResetPasswordPage.module.scss';
import messages from './ResetPasswordPage.messages';

function ResetPasswordPage() {

    const [submitted, setSubmitted] = useState(false);
    const [formData, setFormData] = useState({});
    const [isValid, setIsValid] = useState(false);
    const [formErrorCode, setFormErrorCode] = useState('');
    const [isEmailSent, setIsEmailSent] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const FORBIDDEN_HTTP_STATUS = 403;

    const translator = useTranslator();

    const onValidationChange = (newIsValid) => {
        setIsValid(newIsValid);
    };

    const handleValueChange = (value, path) => {
        const newFormData = { ...formData };
        newFormData[path] = value;
        setFormData(newFormData);
    };

    const serverResponseError = useCallback(() => {
        let msg;

        switch(formErrorCode) {
            case ErrorCodes.ACCOUNT_NOT_FOUND:
                msg = messages.userNotFound;
                break;
            case messages.genericErrorMessage:
                msg = messages.genericErrorMessage
                break;
            case ErrorCodes.FORGOT_PASSWORD_NOT_ALLOWED:
                msg = messages.forgotPasswordNotAllowed
                break;
            default:
                msg = null;
        }

        return {
            formNotification: {
                message: translator(msg),
                visible: !!msg
            }
        };
    }, [formErrorCode]);


    const saveForm = useCallback(async () =>{
        if(!isValid) {
            setSubmitted(true);
            return;
        }
        setIsLoading(true);

        const token = await WMICReCaptchaService.fetchReCaptchaToken('submit', true);

        return EnrollmentService.sendResetPasswordEmail(formData.emailAddress, token)
            .then(res => {
                if(res.ok){
                    return setIsEmailSent(true);
                }
                if(res.status === FORBIDDEN_HTTP_STATUS ){
                    return Promise.reject({ code: ErrorCodes.FORGOT_PASSWORD_NOT_ALLOWED })
                }
                return res.json().then(err => Promise.reject(err));
            }).catch(error => {
                if(error?.code === ErrorCodes.ACCOUNT_INVALID_STATUS) {
                    error = {...error, code: error?.errorOkta ?? error?.code }
                }
                setFormErrorCode(error?.code || ErrorCodes.GENERIC_ERROR);
            }).finally(() => setIsLoading(false));
    });

    const overrideProps = {
        forgotEmail: {
            content: translateRichText(translator(messages.forgotEmailLink, { link: '/account-management/enrollment/forgot-email' }))
        },
        siteBody: {
            visible: !isEmailSent
        },
        confirmationDialog: {
            visible: isEmailSent,
            emailAddress: formData.emailAddress
        },
        submit: {
            isAnimated: isLoading
        },
        ...serverResponseError()
    };

    return (
        <MetadataForm
            uiProps={metadata.pageContent}
            data={formData}
            onDataChange={handleValueChange}
            onValidationChange={onValidationChange}
            showErrors={submitted}
            overrideProps={overrideProps}
            classNameMap={styles}
            callbackMap={{
                saveForm: saveForm
            }}
            componentMap={{
                confirmationDialog: CheckEmailRegistrationDialog,
                WMICButton: WMICAnimatedLoaderButton
            }} />
    );
}

export default withRouter(ResetPasswordPage);

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ":root{--gw-button-margin:0.6rem}.app__MortgageePage__buttonMargin{margin-right:.6rem;margin-right:var(--gw-button-margin)}.app__MortgageePage__cancelButton{float:right}.app__MortgageePage__titleRight{clear:both}.app__MortgageePage__mortgageePageTitle div[class*=fullWidth]{width:100%}", "",{"version":3,"sources":["webpack://./node_modules/gw-capability-policychange-ho-react/pages/Mortgagee/MortgageePage.module.scss"],"names":[],"mappings":"AAAA,MACI,yBAAA,CAGJ,kCACI,kBAAA,CAAA,oCAAA,CAGJ,kCACI,WAAA,CAGJ,gCACI,UAAA,CAIA,8DACI,UAAA","sourcesContent":[":root {\n    --gw-button-margin: 0.6rem;\n  }\n\n.buttonMargin {\n    margin-right: var(--gw-button-margin);\n}\n\n.cancelButton {\n    float: right,\n}\n\n.titleRight {\n    clear: both;    \n}\n\n.mortgageePageTitle {\n    div[class*=\"fullWidth\"] {\n        width: 100%;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonMargin": "app__MortgageePage__buttonMargin",
	"cancelButton": "app__MortgageePage__cancelButton",
	"titleRight": "app__MortgageePage__titleRight",
	"mortgageePageTitle": "app__MortgageePage__mortgageePageTitle"
};
export default ___CSS_LOADER_EXPORT___;

export const phoneNumber = {
    RAW: '+18006402920',
    FORMATTED: '800-640-2920'
};

export const confirmationPage = {
    RESET_PASSWORD: 'reset',
    REGISTRATION: 'registration',
    MIGRATION: 'migration'
};

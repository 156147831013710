import { defineMessages } from 'react-intl';

export default defineMessages({
    FNOLOtherPartiesTitle: {
        id: 'wmic.fnol-page.other-parties.title',
        defaultMessage: 'Other Parties'
    },
    FNOLWitnessesNoOtherPartiesWarning: {
        id: 'wmic.fnol-page.other-parties.no-other-parties-warning',
        defaultMessage: 'Solo Vehicle Loss - No Other Party Involved'
    },
    FNOLOtherPartiesDescription: {
        id: 'wmic.fnol-page.other-parties.descrption-label',
        defaultMessage: `Other Party {num} Information`
    },
    FNOLOtherPartiesMissingPersonsInformation: {
        id: 'wmic.fnol-page.other-parties.missing-info-checkbox',
        defaultMessage: "I don't have the other party's information"
    },
    FNOLOtherPartiesToggleLabel: {
        id: 'wmic.fnol-page.other-parties.toggle-label',
        defaultMessage: 'Are there any additional Other Parties not reported above?'
    },
    FNOLOtherPartiesTabsetLabel: {
        id: 'wmic.fnol-page.other-parties.tabset-label',
        defaultMessage: 'Other Parties:'
    },
    FNOLOtherPartiesCountDropdown: {
        id: 'wmic.fnol-page.other-parties.count-label',
        defaultMessage: 'How Many?'
    },
    FNOLOtherPartiesFormFirstName: {
        id: 'wmic.fnol-page.other-parties.first-name-label',
        defaultMessage: 'First Name'
    },
    FNOLOtherPartiesFormLastName: {
        id: 'wmic.fnol-page.other-parties.last-name-label',
        defaultMessage: 'Last Name'
    },
    FNOLOtherPartiesFormAddress: {
        id: 'wmic.fnol-page.other-parties.address-label',
        defaultMessage: 'Address'
    },
    FNOLOtherPartiesFormCity: {
        id: 'wmic.fnol-page.other-parties.city-label',
        defaultMessage: 'City'
    },
    FNOLOtherPartiesFormCountry: {
        id: 'wmic.fnol-page.other-parties.country-label',
        defaultMessage: 'Country'
    },
    FNOLOtherPartiesFormStateProvince: {
        id: 'wmic.fnol-page.other-parties.state-province-label',
        defaultMessage: 'State'
    },
    FNOLOtherPartiesFormZipCode: {
        id: 'wmic.fnol-page.other-parties.zipcode-label',
        defaultMessage: 'Zip Code'
    },
    FNOLOtherPartiesFormPostalCode: {
        id: 'wmic.fnol-page.other-parties.postalcode-label',
        defaultMessage: 'Postal Code'
    },
    FNOLOtherPartiesFormHomePhone: {
        id: 'wmic.fnol-page.other-parties.home-phone-label',
        defaultMessage: 'Home Phone Number'
    },
    FNOLOtherPartiesFormBusinessPhone: {
        id: 'wmic.fnol-page.other-parties.business-phone-label',
        defaultMessage: 'Business Phone Number'
    },
    FNOLOtherPartiesFormExt: {
        id: 'wmic.fnol-page.other-parties.ext-label',
        defaultMessage: 'Ext'
    },
    FNOLOtherPartiesFormCellPhone: {
        id: 'wmic.fnol-page.other-parties.cell-phone-label',
        defaultMessage: 'Cell Phone Number'
    },
    FNOLOtherPartiesFormDriversLicense: {
        id: 'wmic.fnol-page.other-parties.drivers-license-label',
        defaultMessage: 'Drivers License Number'
    },
    FNOLOtherPartiesFormInjuriesOrTreatments: {
        id: 'wmic.fnol-page.other-parties.injuries-or-treatments-label',
        defaultMessage: 'Tell us about any injuries or treatments received'
    },
    FNOLOtherPartiesOtherVehicleTitle: {
        id: 'wmic.fnol-page.other-parties.vehicle-info-title',
        defaultMessage: 'Other Party {num} Vehicle Information'
    },
    FNOLOtherPartiesVehicleYear: {
        id: 'wmic.fnol-page.other-parties.vehicle-year-label',
        defaultMessage: 'Vehicle Year'
    },
    FNOLOtherPartiesVehicleMake: {
        id: 'wmic.fnol-page.other-parties.vehicle-make-label',
        defaultMessage: 'Vehicle Make'
    },
    FNOLOtherPartiesVehicleModel: {
        id: 'wmic.fnol-page.other-parties.vehicle-model-label',
        defaultMessage: 'Vehicle Model'
    },
    FNOLOtherPartiesVehicleColor: {
        id: 'wmic.fnol-page.other-parties.vehicle-color-label',
        defaultMessage: 'Vehicle Color'
    },
    FNOLOtherPartiesLicensePlateNumber: {
        id: 'wmic.fnol-page.other-parties.license-plate-number-label',
        defaultMessage: 'License Plate Number'
    },
    FNOLOtherPartiesVehicleVin: {
        id: 'wmic.fnol-page.other-parties.vehicle-vin-label',
        defaultMessage: 'VIN'
    },
    FNOLOtherPartiesVehicleComment: {
        id: 'wmic.fnol-page.other-parties.vehicle-comment-label',
        defaultMessage: 'Tell us about the extent and location of the damage on this vehicle'
    },
    FNOLOtherPartiesVehicleLicensePlate: {
        id: 'wmic.fnol-page.other-parties.vehicle-license-plate-label',
        defaultMessage: 'License Plate Number'
    },
    FNOLOtherPartiesVehicleRequiredOption: {
        id: 'wmic.fnol-page.other-parties.toggle-required',
        defaultMessage: 'Field required, select an option.'
    }
})
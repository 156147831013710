const stateKey = 'fnol_current_state';

export default class WMICFnolStateService {
    constructor(props) {
        this.currentState = props.currentState;
        this.init();
    }

    static updateSessionStorage = () => {
        window.sessionStorage.setItem(stateKey, JSON.stringify(this.currentState));
    };

    static init() {
        if(this.currentState === undefined){
            const sessionStorageState = window.sessionStorage.getItem(stateKey);

            if (sessionStorageState) {
                this.currentState = JSON.parse(sessionStorageState)
            }
        }
    };

    static getState(item = undefined) {
        this.init();
        const state = this.currentState;
        return item ? state[item] : state;
    }

    static addState(newState) {
        this.currentState = Object.assign({}, this.currentState, newState);
        this.updateSessionStorage();
    }

    static clearState() {
        window.sessionStorage.removeItem(stateKey);
        this.currentState = undefined
    }
}

import { defineMessages } from 'react-intl';

export default defineMessages({
    mortgagee: {
        id: 'endorsement.ho.directives.templates.ho-additional-interests-edit.Add Mortgagee',
        defaultMessage: 'Add Mortgagee'
    },
    addNew: {
        id: 'endorsement.common.view.Add New',
        defaultMessage: 'Add New'
    },
    Name: {
        id: 'endorsement.ho.directives.templates.ho-additional-interests-edit.Name',
        defaultMessage: 'Name'
    },
    Street: {
        id: 'endorsement.ho.directives.templates.ho-additional-interests-edit.Street',
        defaultMessage: 'Street'
    },
    City: {
        id: 'endorsement.ho.directives.templates.ho-additional-interests-edit.City',
        defaultMessage: 'City'
    },
    State: {
        id: 'endorsement.ho.directives.templates.ho-additional-interests-edit.State',
        defaultMessage: 'State'
    },
    ZIPCode: {
        id: 'endorsement.ho.directives.templates.ho-additional-interests-edit.ZIP Code',
        defaultMessage: 'ZIP Code'
    },
    EfectiveDate: {
        id: 'endorsement.ho.directives.templates.ho-additional-interests-edit.Effective Date',
        defaultMessage: 'Effective Date'
    },
    Type: {
        id: 'endorsement.ho.directives.templates.ho-additional-interests-edit.Type',
        defaultMessage: 'Type'
    },
    Person: {
        id: 'endorsement.ho.directives.templates.ho-additional-interests-edit.Person',
        defaultMessage: 'Person'
    },
    Company: {
        id: 'endorsement.ho.directives.templates.ho-additional-interests-edit.Company',
        defaultMessage: 'Company'
    },
    FirstName: {
        id: 'endorsement.ho.directives.templates.ho-additional-interests-edit.First Name',
        defaultMessage: 'First Name'
    },
    LastName: {
        id: 'endorsement.ho.directives.templates.ho-additional-interests-edit.Last Name',
        defaultMessage: 'Last Name'
    },
    RemoveAdditionalInterest: {
        id: 'endorsement.ho.directives.templates.ho-additional-interests-edit.Remove Additional Interest?',
        defaultMessage: 'Remove Additional Interest?'
    },
    AddIntRemovalMsg: {
        id: 'endorsement.ho.directives.templates.ho-additional-interests-edit.AddIntRemovalMsg',
        defaultMessage: 'Are you sure you want to remove this additional interest from the list?'
    },
    cancelBtn: {
        id: 'endorsement.ho.directives.templates.ho-additional-interests-edit.Cancel',
        defaultMessage: 'Cancel'
    }
});

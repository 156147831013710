import { defineMessages } from 'react-intl';

export default defineMessages({
    validationUserExists: {
        id: 'accountManagement.views.register.validation.user exists',
        defaultMessage: 'User {emailAddress} already exists. Please try another email address or recover your password below.',
    },
    validationEncounteredAnIssue: {
        id: 'accountManagement.views.register.validation.encountered an issue',
        defaultMessage: 'We\'ve encountered an issue with creating your online account. Please call us at &lt;a href="tel:800-640-2920"&gt;800-640-2920&lt;/a&gt; Monday to Friday 7:30 am - 7:30 pm and Saturday 8:00 am - 4:30 pm PT to complete this process.',
    },
    genericErrorMessage: {
        id: 'accountManagement.views.register.genericErrorMessage',
        defaultMessage: 'Unable to process request at this moment',
    },
    register: {
        id: 'accountManagement.views.register.title',
        defaultMessage: 'Create account',
    },
    registerDescription: {
        id: 'accountManagement.views.register.description.Enter your email address',
        defaultMessage: 'Enter your email address below',
    },
    additionalDetails: {
        id: 'accountManagement.views.register.Additional details',
        defaultMessage: 'We\'ll need additional information to find your policy.',
    },
    emailLabel: {
        id: 'accountManagement.views.register.Email address',
        defaultMessage: 'Email address',
    },
    emailRequiredField: {
        id: 'accountManagement.views.register.Email address is a required field',
        defaultMessage: 'Email address is a required field',
    },
    emailValidation: {
        id: 'accountManagement.views.register.validation.Email address',
        defaultMessage: 'Invalid email address',
    },
    firstNameLabel: {
        id: 'accountManagement.views.register.First name',
        defaultMessage: 'First name',
    },
    lastNameLabel: {
        id: 'accountManagement.views.register.Last name',
        defaultMessage: 'Last name',
    },
    phoneNumberLabel: {
        id: 'accountManagement.views.register.Phone number',
        defaultMessage: 'Phone number',
    },
    passwordLabel: {
        id: 'accountManagement.views.register.or email us at',
        defaultMessage: ' or email us at ',
    },
    policyOrAccountNumberLabel: {
         id: 'accountManagement.views.Policy or Account number',
         defaultMessage: 'Policy or Account number',
    },
    zipCodeLabel: {
        id: 'accountManagement.views.register.Zip code',
        defaultMessage: 'Zip code',
    },
    submitButton: {
         id: 'accountManagement.views.register.submitButton',
         defaultMessage: 'Submit',
    }
 });

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__WMICErrorModal__wmic-error-text{font-size:1.3rem!important;margin-bottom:30px!important}.app__WMICErrorModal__wmic-error-body{display:block;margin-block-end:1em;margin-block-start:1em;margin-inline-end:0;margin-inline-start:0;unicode-bidi:isolate}", "",{"version":3,"sources":["webpack://./../common/capabilities-react/gw-capability-policychange-common-react/components/WMICErrorModal/WMICErrorModal.module.scss"],"names":[],"mappings":"AAAA,sCACI,0BAAA,CACA,4BAAA,CAGJ,sCACI,aAAA,CAEA,oBAAA,CADA,sBAAA,CAGA,mBAAA,CADA,qBAAA,CAEA,oBAAA","sourcesContent":[".wmic-error-text {\n    font-size: 1.3rem !important;\n    margin-bottom: 30px !important;\n}\n\n.wmic-error-body {\n    display: block;\n    margin-block-start: 1em;\n    margin-block-end: 1em;\n    margin-inline-start: 0px;\n    margin-inline-end: 0px;\n    unicode-bidi: isolate;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wmic-error-text": "app__WMICErrorModal__wmic-error-text",
	"wmic-error-body": "app__WMICErrorModal__wmic-error-body"
};
export default ___CSS_LOADER_EXPORT___;

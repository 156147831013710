import _ from 'lodash';

const ABSOLUTE_URL_PATTERN = /^(http|https)?:\/\//i;

function urlIsAbsolute(urlString) {
    return ABSOLUTE_URL_PATTERN.test(urlString);
}

function comparator(first, second) {
    const firstPrior = first.priority || 0;
    const secondPrior = second.priority || 0;
    if (firstPrior !== secondPrior) return firstPrior - secondPrior;
    if (first.id > second.id) return 1;
    if (first.id < second.id) return -1;
    return 0;
}

export default {
    /**
     * This function takes two params, url prefix,
     * i.e '../styles/images/common/personalizedoffers/'
     * and the relative path of the static image,
     * i.e 'Leaderboard.png'. It will check if the relative
     * path is an absolute path and return the relative path @returns {new URL}.
     * The function returns a composed URL with the path of the static image
     * @param {String} urlPrefix
     * @param {String} relativeUrl
     * @returns {URL}
     * */
    concatUrl(urlPrefix, relativeUrl) {
        return urlIsAbsolute(relativeUrl) ? relativeUrl : urlPrefix.concat(relativeUrl);
    },

    /**
     * This function will sort and compare an array of offers retrieve from the server
     * based on priorities. If the priorities are the same, it will sort the array
     * alphabetically based on the id. A single offers is returned for the target page.
     * @param {Array} offers
     * @param {string} offerType
     * @returns {Object}
     * */
    pickOffer(offers, offerType) {
        if (offers === undefined) {
            return 0;
        }
        const offersByType = _.filter(offers, { offerType });
        return offersByType.sort(comparator)[0];
    }
};

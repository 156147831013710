import { defineMessages } from 'react-intl';

export default defineMessages({
    EMQVehiclePageWelcome: {
        id: 'wmic.emq.vehicle-page.welcome',
        defaultMessage: 'Welcome '
    },
    EMQVehiclePageThereAre: {
        id: 'wmic.emq.vehicle-page.there-are',
        defaultMessage: 'There are '
    },
    EMQVehiclePageVehiclesListed: {
        id: 'wmic.emq.vehicle-page.vehicles-listed',
        defaultMessage: ' vehicles listed in your policy that need to be updated.'
    },
    EMQVehiclePagePleaseComplete: {
        id: 'wmic.emq.vehicle-page.please-complete',
        defaultMessage: 'Please complete '
    },
    EMQVehiclePageAll: {
        id: 'wmic.emq.vehicle-page.all',
        defaultMessage: 'all'
    },
    EMQVehiclePageBeforeClicking: {
        id: 'wmic.emq.vehicle-page.before-clicking',
        defaultMessage: ' of the fields before clicking '
    },
    EMQVehiclePageSubmit: {
        id: 'wmic.emq.vehicle-page.submit',
        defaultMessage: 'Submit'
    },
    EMQVehiclePageMandatory: {
        id: 'wmic.emq.vehicle-page.mandatory',
        defaultMessage: 'All fields are mandatory'
    },
    EMQVehiclePageCaliforniaLaw: {
        id: 'wmic.emq.vehicle-page.california-law',
        defaultMessage: 'California law requires us to inform you of the following:'
    },
    EMQVehiclePageAnyPerson: {
        id: 'wmic.emq.vehicle-page.any-person',
        defaultMessage: 'Any person who knowingly presents false or fraudulent information to obtain or amend insurance coverage or to make a claim for the payment of a loss is guilty of a crime and may be subject to fines and confinement in state prison.'
    }
});

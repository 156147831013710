import { defineMessages } from 'react-intl';

export default defineMessages({
    autoShopsTitle: {
        id: 'wmic.autoshops.list-map-view.title',
        defaultMessage: 'Need a Reliable Repair Shop?'
    },
    autoShopsListMapViewSubTitle: {
        id: 'wmic.autoshops.list-map-view.subtitle',
        defaultMessage: 'Use the map below to find a Wawanesa approved repair facility near you.'
    },
    autoShopsRefineYourSearch: {
        id: 'wmic.autoshops.list-map-view.RefineYourSearch',
        defaultMessage: 'REFINE YOUR SEARCH'
    },
    autoShopsRefineYourSearchInstructions: {
        id: 'wmic.autoshops.list-map-view.enterALocation',
        defaultMessage: 'Enter a location above or zoom in using the map to find a repair facility.'
    },
    autoShopsNoRepairShopsFound: {
        id: 'wmic.autoshops.list-map-view.NoRepairShopsFound',
        defaultMessage: 'NO REPAIR SHOPS FOUND'
    },
    autoShopsNoRepairShopsFoundInstructions: {
        id: 'wmic.autoshops.list-map-view.NoRepairShopsFoundInstructions',
        defaultMessage: 'There are no Wawanesa approved repair shops nearby. Enter a location above or zoom in using the map to find a repair facility.'
    },
    autoShopsListMapViewFacilitiesInView: {
        id: 'wmic.autoshops.list-map-view.facilities-in-view',
        defaultMessage: 'repair facilities in view'
    },
    autoShopsListMapViewFacilityInView: {
        id: 'wmic.autoshops.list-map-view.facility-in-view',
        defaultMessage: 'repair facility in view'
    },
    autoShopsListMapViewNoFacilityInView: {
        id: 'wmic.autoshops.list-map-view.no-facility-in-view',
        defaultMessage: 'No repair facilities were found based on your search terms.'
    },
});

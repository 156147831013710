import { defineMessages } from 'react-intl';

export default defineMessages({
    FNOLInsuredInformationTitle: {
        id: 'wmic.fnol-page.insured-information.title',
        defaultMessage: 'Wawanesa Policyholder Information'
    },
    FNOLOtherPartyInformationTitle: {
        id: 'wmic.fnol-page.insured-information.other-party-title',
        defaultMessage: 'Other Party Information'
    },
    FNOLOtherPartyInformationSameAsYourInformation: {
        id: 'wmic.fnol-page.insured-information.same-as-your-information',
        defaultMessage: 'Same as Your Information'
    },
    FNOLInsuredInformationRadioButtonLabel: {
        id: 'wmic.fnol-page.insured-information.radio-button-label',
        defaultMessage: 'Is The Driver Of The Insured Vehicle Same As Above?'
    },
    FNOLInsuredInformationInsuredVehicleTitle: {
        id: 'wmic.fnol-page.insured-information.insured-vehicle-title',
        defaultMessage: 'Driver Of The Insured Vehicle'
    },
    FNOLInsuredInformationInsureRadioYes: {
        id: 'wmic.fnol-page.insured-information.insured-radio.yes',
        defaultMessage: 'Yes'
    },
    FNOLInsuredInformationInsureRadioNo: {
        id: 'wmic.fnol-page.insured-information.insured-radio.no',
        defaultMessage: 'No'
    },
    FNOLInsuredInformationInsureRadioNoDriver: {
        id: 'wmic.fnol-page.insured-information.insured-radio.no-driver',
        defaultMessage: 'No Driver'
    },
    FNOLInsuredInformationInsureRadioUnknown: {
        id: 'wmic.fnol-page.insured-information.insured-radio.unknown',
        defaultMessage: 'Unknown'
    },
})
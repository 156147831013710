import productMetadata from 'product-metadata';

function getAddressMetadata() {
    // RAW metadata path
    // metadata.pc["wmic.edge.us.capabilities.address.dto.AddressDTO"].value.metadata[1].value.expression.value

    const suiteMetadata = window.wmic.viewmodel.metadata;
    const addressMetaData = suiteMetadata.get('pc').types.get('wmic.edge.us.capabilities.address.dto.AddressDTO').metadata._unnamedArray[1].value.expression.value;
    return addressMetaData;
}

/* eslint-disable prefer-template */
/* eslint-disable no-underscore-dangle */
/* eslint-disable max-len */
export default {
    usPostalCodeIsValid: (postalcode, country) => {
        const addressMetaData = getAddressMetadata()
        if (postalcode === undefined) {
            return true;
        }
        let pattern;
        if (country.code === 'US') {
            pattern = addressMetaData[0].regEx;
        } else {
            return true;
        }

        const patternRegEx = new RegExp('^' + pattern + '$');
        return patternRegEx.test(postalcode);
    },
    canadaPostalCodeIsValid: (postalcode, country) => {
        const addressMetaData = getAddressMetadata()
        if (postalcode === undefined) {
            return true;
        }
        let pattern;

        if (country.code === 'CA') {
            pattern = addressMetaData[0].regEx;
        } else {
            return true;
        }

        const patternRegEx = new RegExp('^' + pattern + '$');
        return patternRegEx.test(postalcode);
    },
    doesZipCodeMatchCity: (postalcode, zipCodeMatchesCity) => {
        return zipCodeMatchesCity;
    }
};

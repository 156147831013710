import { defineMessages } from 'react-intl';

export default defineMessages({
    autoShopsTitle: {
        id: 'wmic.autoshops.service-error.title',
        defaultMessage: 'Need a Reliable Repair Shop?'
    },
    autoShopsListMapViewSubTitle: {
        id: 'wmic.autoshops.service-error.subtitle',
        defaultMessage: 'Use the map below to find a Wawanesa approved repair facility near you.'
    },
});

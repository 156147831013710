import { defineMessages } from 'react-intl';


export default defineMessages({
    FNOLHOReviewTitle: {
        id: 'wmic.fnol-review.ho.title',
        defaultMessage: 'Claim Summary'
    },
    FNOLHOReviewYourInformationTitle: {
        id: 'wmic.fnol-review.ho.your-information-title',
        defaultMessage: 'Your Information'
    },
    FNOLHOReviewPoliceReportTitle: {
        id: 'wmic.fnol-review.ho.police-report-title',
        defaultMessage: 'Police Report'
    },
    FNOLHOReviewInsuredOptionInsured: {
        id: 'wmic.fnol-review.ho.insured-option.wawanesa-policy-holder',
        defaultMessage: 'Wawanesa Policy Holder'
    },
    FNOLHOReviewInsuredOptionMortgagee: {
        id: 'wmic.fnol-review.ho.insured-option.mortgagee',
        defaultMessage: 'Mortgagee'
    },
    FNOLHOReviewInsuredOptionAnotherInsuranceCompany: {
        id: 'wmic.fnol-review.ho.insured-option.another-insurance-company',
        defaultMessage: 'Another Insurance Company'
    },
    FNOLHOReviewInsuredOptionAttorney: {
        id: 'wmic.fnol-review.ho.insured-option.attorney',
        defaultMessage: 'Attorney'
    },
    FNOLReviewPoliceReportDeptNameLabel: {
        id: 'wmic.fnol-review.ho.police-dept-label',
        defaultMessage: 'Law enforcement agency:'
    },
    FNOLReviewPoliceReportReportNumberLabel: {
        id: 'wmic.fnol-review.ho.police-report-number-label',
        defaultMessage: 'Accident report/case number:'
    },
    FNOLReviewPoliceReportContactLabel: {
        id: 'wmic.fnol-review.ho.police-contact-label',
        defaultMessage: 'Address and phone number for this agency:'
    },
    FNOLHOReviewLossInformationTitle: {
        id: 'wmic.fnol-review.ho.loss-information-title',
        defaultMessage: 'Loss Information'
    },
    FNOLHOReviewWawanesaPolicyholderInformationTitle: {
        id: 'wmic.fnol-review.ho.wawanesa-policyholder-information-title',
        defaultMessage: 'Wawanesa Policyholder Information'
    },
    FNOLReviewMortgageeTitle: {
        id: 'wmic.fnol-review.ho.mortgagee-title',
        defaultMessage: 'Mortgagee'
    },
    FNOLReviewMortgageesTitle: {
        id: 'wmic.fnol-review.ho.mortgagees-section-title',
        defaultMessage: 'Mortgagee {num}'
    },
    FNOLReviewName: {
        id: 'wmic.fnol-review.ho.name-label',
        defaultMessage: 'Name:'
    },
    FNOLReviewFirstName: {
        id: 'wmic.fnol-review.ho.first-name-label',
        defaultMessage: 'First Name:'
    },
    FNOLReviewLastName: {
        id: 'wmic.fnol-review.ho.last-name-label',
        defaultMessage: 'Last Name:'
    },
    FNOLReviewPhoneNumber: {
        id: 'wmic.fnol-review.ho.phone-number-label',
        defaultMessage: 'Home Phone Number:'
    },
    FNOLReviewBusinessPhoneNumber: {
        id: 'wmic.fnol-review.ho.business-phone-number-label',
        defaultMessage: 'Business Phone Number:'
    },
    FNOLReviewCellPhoneNumber: {
        id: 'wmic.fnol-review.ho.cell-phone-number-label',
        defaultMessage: 'Cell Phone Number:'
    },
    FNOLReviewEmailAddress: {
        id: 'wmic.fnol-review.ho.email-address-label',
        defaultMessage: 'Email Address:'
    },
    FNOLReviewCompanyName: {
        id: 'wmic.fnol-review.ho.company-name-label',
        defaultMessage: 'Company Name:'
    },
    FNOLReviewFileNumber: {
        id: 'wmic.fnol-review.ho.file-number-label',
        defaultMessage: 'File Number:'
    },
    FNOLReviewWawanesaPolicyNumber: {
        id: 'wmic.fnol-review.ho.wawanesa-policy-number-label',
        defaultMessage: 'Wawanesa Policy Number:'
    },
    FNOLReviewDateOfLoss: {
        id: 'wmic.fnol-review.ho.date-of-loss-label',
        defaultMessage: 'Date of Loss:'
    },
    FNOLReviewTimeOfLoss: {
        id: 'wmic.fnol-review.ho.time-of-loss-label',
        defaultMessage: 'Time of Loss:'
    },
    FNOLReviewTellUsWhatHappened: {
        id: 'wmic.fnol-review.ho.tell-us-what-happened-label',
        defaultMessage: 'Tell us what happened?'
    },
    FNOLReviewAddress: {
        id: 'wmic.fnol-review.ho.address-label',
        defaultMessage: 'Address:'
    },
    FNOLReviewCity: {
        id: 'wmic.fnol-review.ho.city-label',
        defaultMessage: 'City:'
    },
    FNOLReviewCountry: {
        id: 'wmic.fnol-review.ho.country-label',
        defaultMessage: 'Country:'
    },
    FNOLReviewState: {
        id: 'wmic.fnol-review.ho.state-label',
        defaultMessage: 'State:'
    },
    FNOLReviewZipcode: {
        id: 'wmic.fnol-review.ho.zipcode-label',
        defaultMessage: 'Zip Code:'
    },
    FNOLReviewNoMortgagesWarning: {
        id: 'wmic.fnol-review.ho.no-mortgages-warning',
        defaultMessage: 'No mortgages'
    },
    FNOLHOSubmitButton: {
        id: 'wmic.fnol-page.ho.submit',
        defaultMessage: 'Submit Claim'
    },
    FNOLHOSubmitButtonTrackButtonIdentifier: {
        id: 'wmic.fnol-page.ho.tracking.submit',
        defaultMessage: 'FNOL - {claimType} - {state} - Submit'
    },
    FNOLHOEditButton: {
        id: 'wmic.fnol-page.ho.edit',
        defaultMessage: 'Edit Claim'
    },
    FNOLHOEditButtonTrackButtonIdentifier: {
        id: 'wmic.fnol-page.ho.tracking.edit',
        defaultMessage: 'FNOL - {claimType} - {state} - Edit'
    },
})

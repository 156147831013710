/* eslint-disable prefer-template */
/* eslint-disable no-underscore-dangle */
/* eslint-disable max-len */

import _ from 'lodash';

const LANEWAY = 'Laneway_WMIC';
const HOMEOWNERS = 'HO3';
const REVENUE_PROPERTY = 'Basic_wmic';
const SEASONAL = 'Seasonal_wmic';
const DWELLING = 'dwelling';

export default {
    dwellingCoverageLimitationOnly: (dwellingType, coverageLimitation) => {
        if (dwellingType && [REVENUE_PROPERTY, SEASONAL].includes(dwellingType.code)) {
            return coverageLimitation && coverageLimitation.code === DWELLING;
        }
        return true;
    },
    isLanewayValid: (residenceType, associatedDwellingPublicID, dwellings) => {
        const lanewayPolicyTypes = [HOMEOWNERS, REVENUE_PROPERTY];
        let isLanewayValid = true;

        if (residenceType && residenceType.code === LANEWAY) {
            if (dwellings) {
                isLanewayValid = _.some(dwellings, (dwelling) => {
                    return dwelling.residenceType !== LANEWAY
                        && (dwelling.hOPolicyType && lanewayPolicyTypes.includes(dwelling.hOPolicyType))
                        && (dwelling.associatedDwellingPublicID == null || dwelling.publicID === associatedDwellingPublicID);
                });
            }
        }
        return isLanewayValid;
    }
};

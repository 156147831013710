// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__FaqSectionComponent__faqSection{padding:var(--GW-LAYOUT-6) var(--GW-LAYOUT-6) var(--GW-LAYOUT-8) var(--GW-LAYOUT-6)}.app__FaqSectionComponent__gwSectionContainer{margin:var(--GW-LAYOUT-6) 0}", "",{"version":3,"sources":["webpack://./node_modules/gw-capability-faq-react/components/FaqSectionComponent/FaqSectionComponent.module.scss"],"names":[],"mappings":"AAAA,sCACE,mFAAA,CAGF,8CACE,2BAAA","sourcesContent":[".faqSection {\n  padding: var(--GW-LAYOUT-6) var(--GW-LAYOUT-6) var(--GW-LAYOUT-8) var(--GW-LAYOUT-6);\n}\n\n.gwSectionContainer {\n  margin: var(--GW-LAYOUT-6) 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"faqSection": "app__FaqSectionComponent__faqSection",
	"gwSectionContainer": "app__FaqSectionComponent__gwSectionContainer"
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__WMICAccordionCard__wmic-accordion-title{--GW-FONT-WEIGHT-SEMI-BOLD:600;--GW-ACCORDION-BORDER-FOCUS:1px solid var(--GW-SEPARATOR-COLOR);border:1px solid var(--GW-SEPARATOR-COLOR);border-radius:2px 2px 0 0;padding:var(--GW-SPACING-10)}.app__WMICAccordionCard__wmic-accordion-card-heading svg{stroke:#444;stroke-width:28px}.app__WMICAccordionCard__wmic-accordion-collapse{border-left:1px solid var(--GW-SEPARATOR-COLOR);border-right:1px solid var(--GW-SEPARATOR-COLOR)}.app__WMICAccordionCard__wmic-accordion-card-body{margin:0}.app__WMICAccordionCard__wmic-fixed-heigh{height:100%}.app__WMICAccordionCard__wmic-fixed-heigh.app__WMICAccordionCard__wmic-accordion-card{border:none}", "",{"version":3,"sources":["webpack://./node_modules/wmic-components-amp-common-react/WMICAccordionCard/WMICAccordionCard.module.scss"],"names":[],"mappings":"AAAA,8CACI,8BAAA,CACA,+DAAA,CAEA,0CAAA,CACA,yBAAA,CAFA,4BAEA,CAIA,yDACI,WAAA,CACA,iBAAA,CAIR,iDACI,+CAAA,CACA,gDAAA,CAGJ,kDACI,QAAA,CAGJ,0CACI,WAAA,CACA,sFACI,WAAA","sourcesContent":[".wmic-accordion-title {\n    --GW-FONT-WEIGHT-SEMI-BOLD: 600;\n    --GW-ACCORDION-BORDER-FOCUS: 1px solid var(--GW-SEPARATOR-COLOR);\n    padding: var(--GW-SPACING-10);\n    border: 1px solid var(--GW-SEPARATOR-COLOR);\n    border-radius: 2px 2px 0 0;\n}\n\n.wmic-accordion-card-heading {\n    svg {\n        stroke: rgb(68, 68, 68);;\n        stroke-width: 28px;\n    }\n}\n\n.wmic-accordion-collapse {\n    border-left: 1px solid var(--GW-SEPARATOR-COLOR);\n    border-right: 1px solid var(--GW-SEPARATOR-COLOR);\n}\n\n.wmic-accordion-card-body {\n    margin: 0;\n}\n\n.wmic-fixed-heigh {\n    height: 100%;\n    &.wmic-accordion-card {\n        border: none;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wmic-accordion-title": "app__WMICAccordionCard__wmic-accordion-title",
	"wmic-accordion-card-heading": "app__WMICAccordionCard__wmic-accordion-card-heading",
	"wmic-accordion-collapse": "app__WMICAccordionCard__wmic-accordion-collapse",
	"wmic-accordion-card-body": "app__WMICAccordionCard__wmic-accordion-card-body",
	"wmic-fixed-heigh": "app__WMICAccordionCard__wmic-fixed-heigh",
	"wmic-accordion-card": "app__WMICAccordionCard__wmic-accordion-card"
};
export default ___CSS_LOADER_EXPORT___;

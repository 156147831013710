import WMICAddressDTOValidationFunctions from './aspects/_WMICAddressDTOValidationFunctions';
import WMICQuoteBaseDataDTOValidationFunctions from './aspects/_WMICQuoteBaseDataDTOValidationFunctions';
import WMICValidationFunctions from './aspects/_WMICValidationFunctions';
import WMICDwellingDTOValidationFunctions from './aspects/_WMICDwellingDTOValidationFunctions';
import WMICLocalDateDTOValidationFunctions from './aspects/_WMICLocalDateDTOValidationFunctions';
import WMICDriverDTOValidationFunctions from './aspects/_WMICDriverDTOValidationFunctions';
import WMICDriverUtilValidationFunctions from './aspects/_WMICDriverUtilValidationFunctions';
import WMICWatercraftRiskDTOValidatonFunctions from './aspects/_WMICWatercraftRiskDTOValidationFunctions';

import DateFormatter from './util/DateFormatter';

export const validationFunctions = {
    WMICAddressDTOValidationFunctions,
    WMICDwellingDTOValidationFunctions,
    WMICLocalDateDTOValidationFunctions,
    WMICQuoteBaseDataDTOValidationFunctions,
    WMICValidationFunctions,
    WMICDriverDTOValidationFunctions,
    WMICDriverUtilValidationFunctions,
    WMICWatercraftRiskDTOValidatonFunctions,
};

export const utils = {
    DateFormatter
}

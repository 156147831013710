// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__WMICAutoShopsServiceErrorComponent__header{color:#545454}.app__WMICAutoShopsServiceErrorComponent__header h1{font-size:4rem;font-weight:600;line-height:1.125;margin-bottom:2rem!important}.app__WMICAutoShopsServiceErrorComponent__header h1,.app__WMICAutoShopsServiceErrorComponent__header p{text-align:center}.app__WMICAutoShopsServiceErrorComponent__error-title{color:#254e6c;font-size:2.4rem;font-weight:600;margin-bottom:1.2rem}.app__WMICAutoShopsServiceErrorComponent__has-text-centered{text-align:center}.app__WMICAutoShopsServiceErrorComponent__section{padding:4.8rem 2.4rem}.app__WMICAutoShopsServiceErrorComponent__has-background-info-light{background-color:#eff5fb}", "",{"version":3,"sources":["webpack://./../common/capabilities-react/wmic-capability-autoshops-react/components/WMICAutoShopsServiceErrorComponent/WMICAutoShopsServiceErrorComponent.module.scss"],"names":[],"mappings":"AAKA,iDACE,aAJU,CAKV,oDAEE,cAAA,CADA,eAAA,CAEA,iBAAA,CACA,4BACA,CAGF,uGAHE,iBAIA,CAIJ,sDACE,aAAA,CACA,gBAAA,CACA,eAAA,CACA,oBAAA,CAGF,4DACE,iBAAA,CAGF,kDACE,qBAAA,CAGF,oEACE,wBAAA","sourcesContent":["@import \"~wmic-amp-digital-theme-styles/sass/app/portal-specific/styles/sass/portal-variables.scss\";\n\n$grey-dark: #545454;\n$text: $grey-dark;\n\n.header { \n  color: $text;\n  h1 {\n    font-weight: 600;\n    font-size: 4rem;\n    line-height: 1.125;\n    margin-bottom: 2rem !important;\n    text-align: center;\n  }\n\n  p {\n    text-align: center;\n  }\n}\n\n.error-title {\n  color: #254e6c;\n  font-size: 2.4rem;\n  font-weight: 600;\n  margin-bottom: 1.2rem;\n}\n\n.has-text-centered {\n  text-align: center;\n}\n\n.section {\n  padding: 4.8rem 2.4rem;\n}\n\n.has-background-info-light {\n  background-color: #eff5fb;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "app__WMICAutoShopsServiceErrorComponent__header",
	"error-title": "app__WMICAutoShopsServiceErrorComponent__error-title",
	"has-text-centered": "app__WMICAutoShopsServiceErrorComponent__has-text-centered",
	"section": "app__WMICAutoShopsServiceErrorComponent__section",
	"has-background-info-light": "app__WMICAutoShopsServiceErrorComponent__has-background-info-light"
};
export default ___CSS_LOADER_EXPORT___;

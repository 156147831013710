// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__WMICAutoShopsModel__fa,.app__WMICAutoShopsModel__fas,.app__WMICAutoShopsModel__result-list-icons{font-family:Font Awesome\\ 5 Free!important;font-weight:900}.app__WMICAutoShopsModel__is-inline-block{display:inline-block}.app__WMICAutoShopsModel__not-a-link{font-size:1em;font-weight:400;line-height:1.5}", "",{"version":3,"sources":["webpack://./../common/capabilities-react/wmic-capability-autoshops-react/models/WMICAutoShopsModel.module.scss"],"names":[],"mappings":"AAEA,uGACI,0CAAA,CACA,eAAA,CAGJ,0CACI,oBAAA,CAGJ,qCACI,aAAA,CACA,eAAA,CACA,eAAA","sourcesContent":["@import \"~wmic-amp-digital-theme-styles/sass/base/config/colors.scss\";\n\n.fa, .fas, .result-list-icons {\n    font-family: 'Font Awesome 5 Free' !important;\n    font-weight: 900;\n}\n\n.is-inline-block {\n    display: inline-block;\n}\n\n.not-a-link {\n    font-size: 1em;\n    font-weight: 400;\n    line-height: 1.5;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fa": "app__WMICAutoShopsModel__fa",
	"fas": "app__WMICAutoShopsModel__fas",
	"result-list-icons": "app__WMICAutoShopsModel__result-list-icons",
	"is-inline-block": "app__WMICAutoShopsModel__is-inline-block",
	"not-a-link": "app__WMICAutoShopsModel__not-a-link"
};
export default ___CSS_LOADER_EXPORT___;

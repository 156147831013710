import { useLayoutEffect } from 'react';
import { LobIconUtil } from 'wmic-portals-utils-js';

/**
 * This hook is use to replacing all Material icon class to FontAwesome icon class
 * @param {domRef} domRef is the reference of a DOM element by using the useRef hook
 */
export default function useFaIcons(domRef) {
    useLayoutEffect(() => {
        const dom = domRef.current;
        if(dom !== null) {
            // get all elements that contain the material ui icon class
            const results = dom.querySelectorAll('[class^="mir mi-"]');
            results.forEach((element) => {
                // get classes for an element (e.g. icon)
                const mirClasses = [...element.classList].filter((cl) => LobIconUtil.isMir(cl));
                const faClasses = mirClasses.map((mir) => LobIconUtil.convertMirToFa(mir));
                // remove material icon class and replace with fontawesome class
                mirClasses.forEach((mir) => element.classList.remove(mir));
                faClasses.forEach((fa) => element.classList.add(fa));
            });
        }
    })
};

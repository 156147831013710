/* eslint-disable no-warning-comments */
/* eslint-disable react/jsx-no-target-blank */
import React, { useCallback, useState, useEffect } from 'react';
import _ from 'lodash';
import { WMICAddPolicyModal } from 'wmic-components-amp-common-react';
import { WMICFeatureFlagUtil } from 'wmic-portals-utils-js';
import { useHistory } from 'react-router-dom';
import { useAuthentication } from 'wmic-digital-auth-react';
import { useModal } from '@jutro/components';
import WMICEnrollmentPageFooter from './WMICEnrollmentPageFooter/WMICEnrollmentPageFooter';
import WMICDefaultPageFooter from './WMICDefaultPageFooter/WMICDefaultPageFooter';

function WMICPageFooter() {
    const modalApi = useModal();
    const featureFlags = WMICFeatureFlagUtil.getFeatureFlags();
    const [addedPolicies, updateAddedPolicies] = useState([]);
    const { isAuthenticated, userInfo: authUserData } = useAuthentication();
    const [ldFlags, setLdFlags] = useState([]);
    const rFlagsPromise = WMICFeatureFlagUtil.useFlags(authUserData);

    const history = useHistory();
    const isEnrollment = window.location.pathname.includes('/enrollment') || window.location.pathname.includes('/login');

    const navigateToContactUs = useCallback((evt) => {
        evt.preventDefault();
        history.push('/contactUs');
    }, [history]);

    const initFeatureFlags = async () => {
        const rFlags = await rFlagsPromise;
        setLdFlags(rFlags);
    }

    useEffect(() => {
        initFeatureFlags();
    }, [])

    const getFeatureAvailability = useCallback((featureName) => {
        const response = WMICFeatureFlagUtil.queryAvailabilityAMP(ldFlags, featureName);
        return response;
    }, [ldFlags]);

    const isPublicURLWithFooter =
        window.location.pathname.includes('/auto-claim') ||
        window.location.pathname.includes('/residential-claim') ||
        window.location.pathname.includes('/preferred-autoshops') ||
        window.location.pathname.includes('/technicalErrorPage/fnol') ||
        window.location.pathname.includes('/logout-confirmation');

    const addPolicyModal = () => {
        modalApi.showModal(
            <WMICAddPolicyModal
                data={{}}
                isOpen
                updateAddedPolicies={updateAddedPolicies}
            />
        ).catch(_.noop);
    };

    useEffect(() => {
        if (!_.isEmpty(addedPolicies)) {
            // refresh page to retrieve a billing data of added policies
            window.location.reload(false);
        }
    }, [addedPolicies]);


    const preferredAutoShopsAvailable = () => {
        return getFeatureAvailability(featureFlags.PREFERRED_AUTO_SHOPS).isAvailable
    }

    return isEnrollment ?  
        <WMICEnrollmentPageFooter /> : 
        <WMICDefaultPageFooter
            addPolicyModal={addPolicyModal}
            preferredAutoShopsAvailable={preferredAutoShopsAvailable}
            isPublicURLWithFooter={isPublicURLWithFooter}
            navigateToContactUs={navigateToContactUs}
            isAuthenticated={isAuthenticated}
        />
}

export default WMICPageFooter;

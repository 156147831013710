import { cloneDeep } from 'lodash'

export const WMICFnolSharedDataServiceObject = {
    isAutoglassOnly: {
        value: null
    },
    reportingParty: {
        reportingPartyRelationToInsured: '',
        insuredOption: '',
        firstName: '',
        lastName: '',
        emailAddress: '',
        phoneNumber: '',
        businessPhoneNumber: '',
        businessPhoneNumberExt: '',
        cellPhoneNumber: '',
        companyInfo: {
            companyName: '',
            fileNumber: ''
        }
    },
    lossInformation: {
        policyNumber: '',
        dateOfLoss: '',
        timeOfLoss: '',
        lossLocation: {
            street: '',
            city: '',
            state: '',
            country: 'US'
        },
        lossDescription: ''
    },
    policeInformation: {
        policeDeptName: '',
        reportNumber: '',
        policeDeptContactInfo: ''
    },
    insured: {
        sameAsInformer: false,
        firstName: '',
        lastName: '',
        address: {
            street: '',
            city: '',
            country: 'US',
            state: '',
            postalCode: ''
        },
        phoneNumber: '',
        businessPhoneNumber: '',
        businessPhoneNumberExt: '',
        cellPhoneNumber: '',
        vehicle: {
            vehicleYear: '',
            vehicleMake: '',
            vehicleModel: '',
            vehicleColor: '',
            licensePlateNumber: '',
            VIN: '',
            vehicleDamages: ''
        },
        driverOption: '',
        dateOfBirth: '',
        driversLicenseNo: '',
        injuries: ''
    },
    driverOfInsuredVehicle: {
        firstName: '',
        lastName: '',
        address: {
            street: '',
            city: '',
            country: 'US',
            stateProvince: '',
            postalCode: ''
        },
        phoneNumber: '',
        businessPhoneNumber: '',
        businessPhoneNumberExt: '',
        cellPhoneNumber: '',
        dateOfBirth: '',
        driversLicenseNo: '',
        injuries: ''
    },
    otherPartyClaimant: {
        firstName: '',
        lastName: '',
        address: {
            street: '',
            city: '',
            country: 'US',
            state: '',
            postalCode: ''
        },
        phoneNumber: '',
        businessPhoneNumber: '',
        businessPhoneNumberExt: '',
        cellPhoneNumber: '',
        vehicle: {
            vehicleYear: '',
            vehicleMake: '',
            vehicleModel: '',
            vehicleColor: '',
            licensePlateNumber: '',
            VIN: '',
            vehicleDamages: ''
        }
    },
    driverOfInsuredVehicleBlank: {
        firstName: '',
        lastName: '',
        address: {
            street: '',
            city: '',
            country: 'US',
            stateProvince: '',
            postalCode: ''
        },
        phoneNumber: '',
        businessPhoneNumber: '',
        businessPhoneNumberExt: '',
        cellPhoneNumber: '',
        dateOfBirth: '',
        driversLicenseNo: '',
        injuries: ''
    },
    otherPartyModel: {
        unknownParty: false,
        passengerLocation: '',
        firstName: '',
        lastName: '',
        address: {
            street: '',
            city: '',
            country: 'US',
            stateProvince: '',
            postalCode: ''
        },
        phoneNumber: '',
        businessPhoneNumber: '',
        businessPhoneNumberExt: '',
        cellPhoneNumber: '',
        dateOfBirth: '',
        driversLicenseNo: '',
        injuries: ''
    },
    vehicleModel: {
        vehicleYear: '',
        vehicleMake: '',
        vehicleModel: '',
        vehicleColor: '',
        LicensePlateNumber: '',
        VIN: '',
        vehicleDamages: ''
    },
    policeReportSelected: {
        value: null
    },
    otherPartiesSelected: {
        value: null
    },
    passengersSelected: {
        value: null
    },
    witnessesSelected: {
        value: null
    },
    mortgageesSelected: {
        value: null
    },
    otherParties: {
        list: [],
    },
    witnesses: {
        list: [],
    },
    passengers: {
        list: []
    },
    mortgagees: {
        list: []
    }
};

const WMICFnolSharedDataService = cloneDeep(WMICFnolSharedDataServiceObject)

export default WMICFnolSharedDataService;
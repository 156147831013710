import { defineMessages } from 'react-intl';

export default defineMessages({
    FNOLDisclaimerText: {
        id: 'wmic.fnol-page.disclaimer',
        defaultMessage: 'For information about the categories of personal information collected, the purposes for which it is collected or used and whether that information is sold or shared, visit our '
    },
    FNOLDisclaimerLink: {
        id: 'wmic.fnol-page.disclaimer-link',
        defaultMessage: 'Privacy Policy'
    },
    FNOLDisclaimerCalifornia: {
        id: 'wmic.fnol-page.disclaimer-california',
        defaultMessage: 'California law requires us to inform you of the following:&lt;br&gt;Any person who knowingly presents false or fraudulent information to obtain or amend insurance coverage or to make a claim for the payment of a loss is guilty of a crime and may be subject to fines and confinement in state prison.'
    }
})

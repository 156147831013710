import { defineMessages } from 'react-intl';

export default defineMessages({
    expiredPasswordResetLink: {
        id: 'accountManagement.views.expired-password-reset.title',
        defaultMessage: 'Password Reset Link Expired',
    },
    expiredPasswordResetLinkDescription: {
        id: 'accountManagement.views.expired-password-reset.description',
        defaultMessage: 'Sorry this password reset link has expired. You\'ll need to Try reseting your password again.'
    },
    resetPasswordtButton: {
         id: 'accountManagement.views.expired-password-reset.reset password',
         defaultMessage: 'Reset password',
    },
 });
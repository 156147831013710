import { defineMessages } from 'react-intl';


export default defineMessages({
    FNOLPAReviewTitle: {
        id: 'wmic.fnol-review.pa.title',
        defaultMessage: 'Claim Summary'
    },
    FNOLPAReviewYourInformationTitle: {
        id: 'wmic.fnol-review.pa.your-information-title',
        defaultMessage: 'Your Information'
    },
    FNOLPAReviewPoliceReportTitle: {
        id: 'wmic.fnol-review.pa.police-report-title',
        defaultMessage: 'Police Report'
    },
    FNOLPAReviewInsuredOptionInsured: {
        id: 'wmic.fnol-review.insured-option.wawanesa-policy-holder',
        defaultMessage: 'Wawanesa Policy Holder'
    },
    FNOLPAReviewInsuredOptionOtherParty: {
        id: 'wmic.fnol-review.insured-option.other-party',
        defaultMessage: 'Other Party Involved In The Accident'
    },
    FNOLPAReviewInsuredOptionLienholder: {
        id: 'wmic.fnol-review.insured-option.lienholder',
        defaultMessage: 'Lienholder'
    },
    FNOLPAReviewInsuredOptionAnotherInsuranceCompany: {
        id: 'wmic.fnol-review.insured-option.another-insurance-company',
        defaultMessage: 'Another Insurance Company'
    },
    FNOLPAReviewInsuredOptionAttorney: {
        id: 'wmic.fnol-review.insured-option.attorney',
        defaultMessage: 'Attorney'
    },
    FNOLReviewPoliceReportDeptNameLabel: {
        id: 'wmic.fnol-review.pa.police-dept-label',
        defaultMessage: 'Law enforcement agency:'
    },
    FNOLReviewPoliceReportReportNumberLabel: {
        id: 'wmic.fnol-review.pa.police-report-number-label',
        defaultMessage: 'Accident report/case number:'
    },
    FNOLReviewPoliceReportContactLabel: {
        id: 'wmic.fnol-review.pa.police-contact-label',
        defaultMessage: 'Address and phone number for this agency:'
    },
    FNOLPAReviewLossInformationTitle: {
        id: 'wmic.fnol-review.pa.loss-information-title',
        defaultMessage: 'Loss Information'
    },
    FNOLPAReviewWawanesaPolicyholderInformationTitle: {
        id: 'wmic.fnol-review.pa.wawanesa-policyholder-information-title',
        defaultMessage: 'Wawanesa Policyholder Information'
    },
    FNOLPAReviewOtherPartyInformationTitle: {
        id: 'wmic.fnol-review.pa.other-party-information-title',
        defaultMessage: 'Other Party Information'
    },
    FNOLPAReviewOtherPartyVehicleInformationTitle: {
        id: 'wmic.fnol-review.pa.other-party-vehicle-information-title',
        defaultMessage: 'Other Party Vehicle Information'
    },
    FNOLPAReviewWawanesaInsuredVehicleInformationTitle: {
        id: 'wmic.fnol-review.pa.wawanesa-insured-vehicle-information-title',
        defaultMessage: 'Wawanesa Insured Vehicle Information'
    },
    FNOLPAReviewOtherPartiesTitle: {
        id: 'wmic.fnol-review.pa.other-parties-title',
        defaultMessage: 'Other Parties'
    },
    FNOLReviewOtherPartiesInformationTitle: {
        id: 'wmic.fnol-review.pa.other-parties-information-title',
        defaultMessage: 'Other Party {num} Information'
    },
    FNOLReviewOtherPartiesVehicleInformationTitle: {
        id: 'wmic.fnol-review.pa.other-parties-vehicle-information-title',
        defaultMessage: 'Other Party {num} Vehicle'
    },
    FNOLReviewPassengersWitnessesTitle: {
        id: 'wmic.fnol-review.pa.other-party-passengers-witnesses-title',
        defaultMessage: 'Passengers / Witnesses'
    },
    FNOLReviewpassengersTitle: {
        id: 'wmic.fnol-review.pa.other-party-passengers-passenger-title',
        defaultMessage: 'Passenger {num}'
    },
    FNOLReviewwitnessesTitle: {
        id: 'wmic.fnol-review.pa.other-party-passengers-witness-title',
        defaultMessage: 'Witness {num}'
    },
    FNOLReviewFirstName: {
        id: 'wmic.fnol-review.pa.first-name-label',
        defaultMessage: 'First Name:'
    },
    FNOLReviewLastName: {
        id: 'wmic.fnol-review.pa.last-name-label',
        defaultMessage: 'Last Name:'
    },
    FNOLReviewPhoneNumber: {
        id: 'wmic.fnol-review.pa.phone-number-label',
        defaultMessage: 'Home Phone Number:'
    },
    FNOLReviewBusinessPhoneNumber: {
        id: 'wmic.fnol-review.pa.business-phone-number-label',
        defaultMessage: 'Business Phone Number:'
    },
    FNOLReviewCellPhoneNumber: {
        id: 'wmic.fnol-review.pa.cell-phone-number-label',
        defaultMessage: 'Cell Phone Number:'
    },
    FNOLReviewEmailAddress: {
        id: 'wmic.fnol-review.pa.email-address-label',
        defaultMessage: 'Email Address:'
    },
    FNOLReviewCompanyName: {
        id: 'wmic.fnol-review.pa.company-name-label',
        defaultMessage: 'Company Name:'
    },
    FNOLReviewFileNumber: {
        id: 'wmic.fnol-review.pa.file-number-label',
        defaultMessage: 'File Number:'
    },
    FNOLReviewWawanesaPolicyNumber: {
        id: 'wmic.fnol-review.pa.wawanesa-policy-number-label',
        defaultMessage: 'Wawanesa Policy Number:'
    },
    FNOLReviewDateOfLoss: {
        id: 'wmic.fnol-review.pa.date-of-loss-label',
        defaultMessage: 'Date of Loss:'
    },
    FNOLReviewTimeOfLoss: {
        id: 'wmic.fnol-review.pa.time-of-loss-label',
        defaultMessage: 'Time of Loss:'
    },
    FNOLReviewStreetFreewayLocation: {
        id: 'wmic.fnol-review.pa.street-freeway-location-label',
        defaultMessage: 'Street/Freeway Location:'
    },
    FNOLReviewStreet: {
        id: 'wmic.fnol-review.pa.street--location-label',
        defaultMessage: 'Street:'
    },
    FNOLReviewCity: {
        id: 'wmic.fnol-review.pa.city-label',
        defaultMessage: 'City:'
    },
    FNOLReviewCountry: {
        id: 'wmic.fnol-review.pa.country-label',
        defaultMessage: 'Country:'
    },
    FNOLReviewState: {
        id: 'wmic.fnol-review.pa.state-label',
        defaultMessage: 'State:'
    },
    FNOLReviewTellUsWhatHappened: {
        id: 'wmic.fnol-review.pa.tell-us-what-happened-label',
        defaultMessage: 'Tell us what happened?'
    },
    FNOLReviewAddress: {
        id: 'wmic.fnol-review.pa.address-label',
        defaultMessage: 'Address:'
    },
    FNOLReviewZipcode: {
        id: 'wmic.fnol-review.pa.zipcode-label',
        defaultMessage: 'Zip Code:'
    },
    FNOLReviewDriversLicenseNumber: {
        id: 'wmic.fnol-review.pa.drivers-license-number-label',
        defaultMessage: 'Drivers License Number:'
    },
    FNOLReviewInjuriesRecieved: {
        id: 'wmic.fnol-review.pa.injuries-recieved-label',
        defaultMessage: 'Tell us about any injuries or treatments received:'
    },
    FNOLReviewVehicleYear: {
        id: 'wmic.fnol-review.pa.vehicle-year-label',
        defaultMessage: 'Vehicle Year:'
    },
    FNOLReviewVehicleModel: {
        id: 'wmic.fnol-review.pa.vehicle-model-label',
        defaultMessage: 'Vehicle Model:'
    },
    FNOLReviewVehicleMake: {
        id: 'wmic.fnol-review.pa.vehicle-make-label',
        defaultMessage: 'Vehicle Make:'
    },
    FNOLReviewVehicleColor: {
        id: 'wmic.fnol-review.pa.vehicle-color-label',
        defaultMessage: 'Vehicle Color:'
    },
    FNOLReviewVehicleLicensePlate: {
        id: 'wmic.fnol-review.pa.vehicle-license-plate-label',
        defaultMessage: 'Vehicle License Plate:'
    },
    FNOLReviewVehicleVIN: {
        id: 'wmic.fnol-review.pa.vehicle-vin-label',
        defaultMessage: 'VIN:'
    },
    FNOLReviewVehicleDamages: {
        id: 'wmic.fnol-review.pa.vehicle-damages-label',
        defaultMessage: 'Vehicle Damages:'
    },
    FNOLReviewInsuredVehicleDamage: {
        id: 'wmic.fnol-review.pa.insured-vehicle-damages-label',
        defaultMessage: 'Tell us about the extent and location of the damage on this vehicle:'
    },
    FNOLReviewNoPassengersWarning: {
        id: 'wmic.fnol-review.pa.no-passengers-warning',
        defaultMessage: 'No passengers in any involved vehicles'
    },
    FNOLReviewNoWitnessesWarning: {
        id: 'wmic.fnol-review.pa.no-witnesses-warning',
        defaultMessage: 'No witnesses'
    },
    FNOLReviewNoOtherPartiesWarning: {
        id: 'wmic.fnol-review.pa.no-other-parties-warning',
        defaultMessage: 'Solo Vehicle Loss - No Other Party Involved'
    },
    FNOLPASubmitButton: {
        id: 'wmic.fnol-review.pa.submit',
        defaultMessage: 'Submit Claim'
    },
    FNOLPASubmitButtonTrackButtonIdentifier: {
        id: 'wmic.fnol-review.pa.tracking.submit',
        defaultMessage: 'FNOL - {claimType} - {state} - Submit'
    },
    FNOLPAEditButton: {
        id: 'wmic.fnol-review.pa.edit',
        defaultMessage: 'Edit Claim'
    },
    FNOLPAEditButtonTrackButtonIdentifier: {
        id: 'wmic.fnol-review.pa.tracking.edit',
        defaultMessage: 'FNOL - {claimType} - {state} - Edit'
    },
})

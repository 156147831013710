import React, { useEffect, useState } from 'react';
import { MetadataForm } from '@jutro/legacy/uiconfig';
import { WMICFnolUtilsService } from 'wmic-capability-fnol';
import { FNOLConstants } from 'wmic-capability-fnol-react';
import { useTranslator } from '@jutro/locale';
import CONSTANTS from 'wmic-portals-utils-js/StringConstants';
import _ from 'lodash';

import metadata from './FNOLInformationDetailComponent.metadata.json5';
import styles from '../../FNOLStyle.module.scss';
import messages from './FNOLInformationDetailComponent.messages.js'


function FNOLInformationDetailComponent(props) {
    const { insuredInformation, state, claimType, handleDataChange, driverOptionValue, variant, showValidationDeep, showFormatValidationErrorDeep } = props;
    const translator = useTranslator();
    const [showDriverDetails, setShowDriverDetails] = useState(true)
    const [showRemainingDriverFields, setShowRemainingDriverFields] = useState(true)

    const countryOptionChanged = () => {
        if(insuredInformation.address.country){
            let newState;
            switch (insuredInformation.address.country) {
                case CONSTANTS.COUNTRY.CA:
                    newState = CONSTANTS.STATE.AB;
                    break;
                case CONSTANTS.COUNTRY.US:
                    newState = state;
                    break;
                default:
                    break;
            }
            handleDataChange(newState,'address.state')
        }
    }

    useEffect(() => {
        countryOptionChanged();
        switch (driverOptionValue) {
            case CONSTANTS.FNOL.DRIVER_VALES.OUR_INSURED:
                setShowDriverDetails(false);
                setShowRemainingDriverFields(true);
                break;
            case CONSTANTS.FNOL.DRIVER_VALES.NO_DRIVER:
                setShowDriverDetails(false);
                setShowRemainingDriverFields(false);
                break;
            default:
                setShowDriverDetails(true);
                setShowRemainingDriverFields(true);
                break;
        };
        switch (claimType) {
            case FNOLConstants.CLAIM_TYPE.AUTO:
                setShowRemainingDriverFields(true);
                break;
            case FNOLConstants.CLAIM_TYPE.RESIDENTIAL:
                setShowRemainingDriverFields(false);
                break;
            default:
                break;
        }
    }, [claimType, driverOptionValue])

    const canShowAdditionalDriverFields = () => {
        return (showRemainingDriverFields && variant !== CONSTANTS.FNOL.VARIANTS.INSURED)
    }

    const setZipLabel = (country) => {
        return country === CONSTANTS.COUNTRY.US ? translator(messages.FNOLInformationDetailZIP)  : translator(messages.FNOLInformationDetailPostal) 
    }

    const resolvers = {
        resolveClassNameMap: styles
    }
    
    const overrideProps = {
        FNOLInformationDetailDriverDetails: {
            visible: showDriverDetails
        },
        FNOLInformationDetailAdditionalDriver:{
            visible: canShowAdditionalDriverFields()
        },
        FNOLInformationDetailZIP: {
            mask: FNOLConstants.setZipMask(insuredInformation.address.country),
            label: setZipLabel(insuredInformation.address.country)
        },
        FNOLInformationDetailState: {
            availableValues: WMICFnolUtilsService.getStatesShown(insuredInformation)
        },
        FNOLInformationDetailCountry: {
            availableValues: WMICFnolUtilsService.getSelectCanadaUSA()
        },
        FNOLYourInformationPhoneTitle: {
            visible: claimType === FNOLConstants.CLAIM_TYPE.RESIDENTIAL
        },
        FNOLInformationDetailFirstName: {
            disabled: insuredInformation.sameAsInformer
        },
        FNOLInformationDetailLastName: {
            disabled: insuredInformation.sameAsInformer
        },
        FNOLInformationDetailHomePhone: {
            disabled: insuredInformation.sameAsInformer
        },
        FNOLInformationDetailBusinessPhone: {
            disabled: insuredInformation.sameAsInformer
        },
        FNOLInformationDetailExt: {
            disabled: insuredInformation.sameAsInformer,
            registerValidation: () => _.noop
        },
        FNOLInformationDetailCellPhone: {
            disabled: insuredInformation.sameAsInformer
        },
        FNOLInformationDetailFirstNameError: {
            visible: showValidationDeep('firstName', 'field')
        },
        FNOLInformationDetailLastNameError: {
            visible: showValidationDeep('lastName', 'field')
        },
    }

    return (
        <MetadataForm
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
            data={insuredInformation}
            onDataChange={(value, path) => {
                handleDataChange(value, path, variant);
                if (path === 'address.country') {countryOptionChanged();}
            }} />
    );
}

export default FNOLInformationDetailComponent
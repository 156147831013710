import { setComponentMapOverrides } from '@jutro/legacy/uiconfig';
import WMICAutoShopsPage from './pages/WMICAutoShopsPage/WMICAutoShopsPage';
import WMICAutoShopsListMapViewComponent from './components/WMICAutoShopsListMapViewComponent/WMICAutoShopsListMapViewComponent';
import WMICAutoShopsMapComponent from './components/WMICAutoShopsMapComponent/WMICAutoShopsMapComponent';
import WMICAutoShopsListViewComponent from './components/WMICAutoShopsListViewComponent/WMICAutoShopsListViewComponent';
import WMICAutoShopsServiceErrorComponent from './components/WMICAutoShopsServiceErrorComponent/WMICAutoShopsServiceErrorComponent';

setComponentMapOverrides(
    {
        WMICAutoShopsPage: { component: 'WMICAutoShopsPage' },
        WMICAutoShopsListMapViewComponent: { component: 'WMICAutoShopsListMapViewComponent' },
        WMICAutoShopsMapComponent: { component: 'WMICAutoShopsMapComponent' },
        WMICAutoShopsListViewComponent: { component: 'WMICAutoShopsListViewComponent' },
        WMICAutoShopsServiceErrorComponent: { component: 'WMICAutoShopsServiceErrorComponent' }
    },
    {
        WMICAutoShopsPage,
        WMICAutoShopsListMapViewComponent,
        WMICAutoShopsMapComponent,
        WMICAutoShopsListViewComponent,
        WMICAutoShopsServiceErrorComponent
    }
);

export { default as WMICAutoShopsPage } from './pages/WMICAutoShopsPage/WMICAutoShopsPage';
export { default as WMICAutoShopsListMapViewComponent } from './components/WMICAutoShopsListMapViewComponent/WMICAutoShopsListMapViewComponent';
export { default as WMICAutoShopsMapComponent } from './components/WMICAutoShopsMapComponent/WMICAutoShopsMapComponent';
export { default as WMICAutoShopsListViewComponent } from './components/WMICAutoShopsListViewComponent/WMICAutoShopsListViewComponent';
export { default as WMICAutoShopsServiceErrorComponent } from './components/WMICAutoShopsServiceErrorComponent/WMICAutoShopsServiceErrorComponent';

import { defineMessages } from 'react-intl';

export default defineMessages({
    PolicyChanges: {
        id: 'endorsement.common.view.Policy Changes',
        defaultMessage: 'Policy Changes'
    },
    PolicyChangeEfectiveDate: {
        id: 'endorsement.common.views.payment.Policy Change Effective Date',
        defaultMessage: 'Policy Change Effective Date'
    },
    SelectTypeOfChanges: {
        id: 'endorsement.common.view.Select Type of Changes',
        defaultMessage: 'Select Type of Changes'
    }
});

import { FNOLConstants } from 'wmic-capability-fnol-react';
import { BaseYourInformationModel } from 'wmic-capability-fnol';

export default class FNOLPAYourInformationModel extends BaseYourInformationModel {
    getType() {
        return FNOLConstants.CLAIM_TYPE.AUTO;
    }
}

FNOLPAYourInformationModel.RELATIONSHIP = BaseYourInformationModel.PA_RELATIONSHIP;
FNOLPAYourInformationModel.RELATIONSHIP_OPTIONS = BaseYourInformationModel.PA_RELATIONSHIP_OPTIONS;
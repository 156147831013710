import { defineMessages } from 'react-intl';

export default defineMessages({
    FNOLWitnessesTitle: {
        id: 'wmic.fnol-page.witnesses.title',
        defaultMessage: ''
    },
    FNOLWitnessesNoWitnessesWarning: {
        id: 'wmic.fnol-page.witnesses.no-witnesses-warning',
        defaultMessage: 'No Witnesses'
    },
    FNOLWitnessesDescription: {
        id: 'wmic.fnol-page.witnesses.descrption-label',
        defaultMessage: `Witness {num} Information`
    },
    FNOLWitnessesToggleLabel: {
        id: 'wmic.fnol-page.witnesses.toggle-label',
        defaultMessage: 'Were there any witnesses?'
    },
    FNOLWitnessesTabsetLabel: {
        id: 'wmic.fnol-page.witnesses.tabset-label',
        defaultMessage: 'Witnesss:'
    },
    FNOLWitnessesCountDropdown: {
        id: 'wmic.fnol-page.witnesses.count',
        defaultMessage: 'How Many?'
    },
    FNOLWitnessesFormFirstName: {
        id: 'wmic.fnol-page.witnesses.first-name-label',
        defaultMessage: 'First Name'
    },
    FNOLWitnessesFormLastName: {
        id: 'wmic.fnol-page.witnesses.las-tname-label',
        defaultMessage: 'Last Name'
    },
    FNOLWitnessesFormAddress: {
        id: 'wmic.fnol-page.witnesses.address-label',
        defaultMessage: 'Address'
    },
    FNOLWitnessesFormCity: {
        id: 'wmic.fnol-page.witnesses.city-label',
        defaultMessage: 'City'
    },
    FNOLWitnessesFormCountry: {
        id: 'wmic.fnol-page.witnesses.country-label',
        defaultMessage: 'Country'
    },
    FNOLWitnessesFormStateProvince: {
        id: 'wmic.fnol-page.witnesses.state-province-label',
        defaultMessage: 'State'
    },
    FNOLWitnessesFormZipCode: {
        id: 'wmic.fnol-page.witnesses.zipcode-label',
        defaultMessage: 'Zip Code'
    },
    FNOLWitnessesFormPostalCode: {
        id: 'wmic.fnol-page.witnesses.postalcode-label',
        defaultMessage: 'Postal Code'
    },
    FNOLWitnessesFormHomePhone: {
        id: 'wmic.fnol-page.witnesses.home-phone-label',
        defaultMessage: 'Home Phone Number'
    },
    FNOLWitnessesFormBusinessPhone: {
        id: 'wmic.fnol-page.witnesses.business-phone-label',
        defaultMessage: 'Business Phone Number'
    },
    FNOLWitnessesFormExt: {
        id: 'wmic.fnol-page.witnesses.ext-label',
        defaultMessage: 'Ext'
    },
    FNOLWitnessesFormCellPhone: {
        id: 'wmic.fnol-page.witnesses.cell-phone-label',
        defaultMessage: 'Cell Phone Number'
    },
    FNOLWitnessesFormDriversLicense: {
        id: 'wmic.fnol-page.witnesses.drivers-license-label',
        defaultMessage: 'Drivers License Number'
    },
    FNOLWitnessesFormInjuriesOrTreatments: {
        id: 'wmic.fnol-page.witnesses.injuries-or-treatments-label',
        defaultMessage: 'Tell us about any injuries or treatments received'
    },
    FNOLWitnessesOtherVehicleTitle: {
        id: 'wmic.fnol-page.witnesses.vehicle-info-title',
        defaultMessage: 'Other Party {num} Vehicle Information'
    },
    FNOLWitnessesVehicleYear: {
        id: 'wmic.fnol-page.witnesses.vehicle-year-label',
        defaultMessage: 'Vehicle Year'
    },
    FNOLWitnessesVehicleMake: {
        id: 'wmic.fnol-page.witnesses.vehicle-make-label',
        defaultMessage: 'Vehicle Make'
    },
    FNOLWitnessesVehicleModel: {
        id: 'wmic.fnol-page.witnesses.vehicle-model-label',
        defaultMessage: 'Vehicle Model'
    },
    FNOLWitnessesVehicleColor: {
        id: 'wmic.fnol-page.witnesses.vehicle-color-label',
        defaultMessage: 'Vehicle Color'
    },
    FNOLWitnessesVehicleVin: {
        id: 'wmic.fnol-page.witnesses.vehicle-vin-label',
        defaultMessage: 'Vehicle Vin'
    },
    FNOLWitnessesVehicleComment: {
        id: 'wmic.fnol-page.witnesses.vehicle-comment-label',
        defaultMessage: 'Tell us about the extent and location of the damage on this vehicle'
    },
    FNOLWitnessesVehicleRequiredOption:{
        id: "wmic.fnol-page.witnesses.toggle-required",
        defaultMessage: "Field required, select an option."
    },
})
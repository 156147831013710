// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__WMICEMQPage__ww-emq-background{background-color:#fff!important}.app__WMICEMQPage__ww-emq-page-body{margin:0 auto;max-width:100rem}", "",{"version":3,"sources":["webpack://./../common/capabilities-react/wmic-capability-emq-react/pages/WMICEMQPage/WMICEMQPage.module.scss"],"names":[],"mappings":"AAGA,qCACI,+BAAA,CAGJ,oCAEI,aAAA,CADA,gBACA","sourcesContent":["@import \"~wmic-amp-digital-theme-styles/sass/base/config/colors.scss\";\n\n\n.ww-emq-background {\n    background-color: $white-color !important;\n}\n\n.ww-emq-page-body {\n    max-width: 100rem;\n    margin: 0 auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ww-emq-background": "app__WMICEMQPage__ww-emq-background",
	"ww-emq-page-body": "app__WMICEMQPage__ww-emq-page-body"
};
export default ___CSS_LOADER_EXPORT___;

/* eslint-disable camelcase */
import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';

export default class EnrollmentService {
    /**
    * Enrolls a new policy or account for the current user
    *
    * @param {Object} data enrollment data
    * @param {Object} additionalHeaders additional headers to pass to the backend (e.g. auth)
    * @returns {Promise} the promise from the backend call
    */
    static addEnrollmentRecord(data, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('selfEnrollment'), 'addEnrollmentRecord', [data], additionalHeaders);
    }

    static searchForLinkablePolicies_WMIC(data, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('selfEnrollment'), 'searchForLinkablePolicies', [data], additionalHeaders);
    }

    static updateDistributionTypeWithConsent_WMIC(data, additionalHeaders = {}, authFirstName = null, authLastName = null) {
        return JsonRPCService.send(getProxiedServiceUrl('selfEnrollment'), 'updateDistributionTypeWithConsent_WMIC', [data, authFirstName, authLastName], additionalHeaders);
    }

    static addLinkablePolicies_WMIC(data, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('selfEnrollment'), 'addLinkablePolicies', [data], additionalHeaders);
    }

    static addEnrollmentRecord_WMIC(data, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('selfEnrollment'), 'addEnrollmentRecord', [data], additionalHeaders);
    }
}
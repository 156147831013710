import React, { useState, useEffect, useCallback, Fragment, useMemo } from "react";
import PropTypes from "prop-types";
import htmlParser from "html-react-parser";
import _ from "lodash";
import {
    ModalNext,
    ModalBody,
    ModalFooter
} from "@jutro/components";
import { WMICLoader } from "gw-components-platform-react";
import { WMICButton } from "wmic-components-platform-react";
import { PolicyService } from "gw-capability-policy";
import { UsersProfileDetailsService } from "gw-capability-profileinfo";
import { useTranslator } from "@jutro/locale";
import {WMICRichTextUtil, WMICFeatureFlagUtil, WMICRemoteLogger, WMICAmpUtil} from "wmic-portals-utils-js";
import { useAuthentication } from "wmic-digital-auth-react";
import { WMICLink, WMICEditPhone } from "wmic-components-amp-common-react";
import { useValidation } from '@xengage/gw-portals-validation-react';
import classnames from "classnames";
import {useHistory} from "react-router-dom";
import styles from "./WMICReviewContactInfoModal.module.scss";
import messages from "./WMICReviewContactInfo.messages";

const WMICReviewContactInfoModal = ({
    isOpen,
    onResolve,
    onClose,
    updateIsContactReviewed
}) => {
    const translator = useTranslator();
    const featureFlags = WMICFeatureFlagUtil.getFeatureFlags();
    const [accountData, setAccountData] = useState(["undefined"]);
    const [accountDataBackup, setAccountDataBackup] = useState();
    const [accountNumber, setAccountNumber] = useState();
    const { authHeader, userInfo: authUserData } = useAuthentication();
    const [isLoading, setIsLoading] = useState(true);
    const [isEdit, setIsEdit] = useState(false);
    const [isUpdated, setIsUpdated] = useState(false);
    const [ldFlags, setLdFlags] = useState([]);
    const rFlagsPromise = WMICFeatureFlagUtil.useFlags(authUserData);
    const { onValidate, isComponentValid } = useValidation("WMICEditPhone");
    const history = useHistory();


    function handleError(e) {
        onResolve();
        onClose();
        const logger = WMICRemoteLogger.getWMICRemoteLogger(
            'WMICReviewContactInfoModal',
            history.location.pathname,
            window.location,
            ldFlags,
            authHeader
        );
        logger.error(e);
        history.push('technicalErrorPage/issueWithAccount')
    }

    const initFeatureFlags = async () => {
        const rFlags = await rFlagsPromise;
        setLdFlags(rFlags);
    }

    const initAccData = useCallback(async () => {

        const accountContactSummary = await WMICAmpUtil.getAccountContact(authHeader, authUserData);

        if (accountContactSummary) {
            setAccountData(accountContactSummary.accountContact);
            setAccountNumber(accountContactSummary.accountNumber);
        } else {
            throw new Error(`Contact Not Found for user with grantedAuthorities: ${JSON.stringify(authUserData.grantedAuthorities)}`);
        }
    }, [authHeader, authUserData]);

    useEffect(() => {
        setIsLoading(true);
        initFeatureFlags();
        initAccData()
            .then(() => {
                setIsLoading(false);
            })
            .catch((e) => {
                handleError(e);
            });
    }, []);

    const getFeatureAvailability = useCallback((featureName) => {
        const response = WMICFeatureFlagUtil.queryAvailabilityAMP(ldFlags, featureName);
        return response;
    }, [ldFlags]);

    const showAddress = () => {
        return getFeatureAvailability(featureFlags.CTA_PERIODIC_UPDATES_ADDRESS).isAvailable
    }

    const formatPhoneNumber = useCallback((number) => {
        if (!number) {
            return translator(messages.noPhone);
        }
        number = number.replace(/-/g,'');
        return `(${number.substr(0, 3)}) ${number.substr(3, 3)}-${number.substr(6)}`;
    }, []);

    const primaryBtnText = useMemo(() => {
        const btnText = isEdit ? messages.saveChanges : messages.confirm;
        return translator(btnText);
    }, [translator, isEdit]);

    const secondaryBtnText = useMemo(() => {
        const btnText = isEdit ? messages.cancel : messages.askMeLater;
        return translator(btnText);
    }, [translator, isEdit]);

    const handleCloseModalWithButtonText = (buttonText) => {
        switch (buttonText) {
            case secondaryBtnText:
                localStorage.setItem(
                    'reviewContactInfoContainerNotShown',
                    JSON.stringify(false)
                );
                break;
            case primaryBtnText:
                localStorage.setItem(
                    'reviewContactInfoContainerNotShown',
                    JSON.stringify(true)
                );
        }
        
        if (isEdit) {
            setAccountData(accountDataBackup);
            setIsEdit(false);
            return;
        }
        onResolve();
        onClose();
    };

    const getPhoneNumber = useCallback(() => {
        if (accountData === "undefined") {
            return "";
        }
        let phoneNumber;
        const phoneType = _.get(accountData, "primaryPhoneType", {});
        switch (phoneType) {
            case "home":
                phoneNumber = _.get(accountData, "homeNumber", {});
                break;
            case "work":
                phoneNumber = _.get(accountData, "workNumber", {});
                break;
            case "mobile":
                phoneNumber = _.get(accountData, "cellNumber", {});
                break;
            default:
                break;
        }
        const primaryPhoneNumber = formatPhoneNumber(phoneNumber);

        return primaryPhoneNumber;
    }, [accountData, formatPhoneNumber]);

    const getAccountDataFieladIfPresent = useCallback(
        (fieldName) => {
            if (accountData === "undefined") {
                return "";
            }

            return _.get(accountData, fieldName, "");
        },
        [accountData]
    );

    const saveChanges = useCallback(() => {
        const accountSummary = {
            accountNumber,
            accountContact: accountData
        };
        const updateContactApi = async () => {
            try {
                await UsersProfileDetailsService.updateContactSummary(accountSummary, authHeader);
                setIsUpdated(true);
            } catch {
                setIsUpdated(false);
            }
        };
        updateContactApi();
    }, [authHeader, accountData, accountNumber]);

    const handleConfirm = () => {
        if (isEdit) {
            saveChanges();
            setIsEdit(false);
            return;
        }
        const changes = ["PhoneNumber", "Email"];
        const periodicUpdatePostParamsDTOWMIC = {
            PeriodicUpdateTypes: changes,
            PeriodicUpdateAnswer: "upToDate",
        };
        PolicyService.postPeriodicUpdates(
            periodicUpdatePostParamsDTOWMIC,
            authHeader
        )
            .then((response) => {
                if (!response) {
                    throw new Error("Server error.");
                }
            })
            .catch((e) => {
                console.error(e);
                throw e;
            })
            .finally(() => {
                updateIsContactReviewed(true);
                handleCloseModalWithButtonText(primaryBtnText);
            });
    };

    const onPhoneNumberEdit = () => {
        setAccountDataBackup(accountData);
        setIsEdit(true);
    };

    const phoneType = useMemo(() => {
        return accountData ? accountData.primaryPhoneType : null;
    }, [accountData]);

    const secondaryBtnTrackingText = useMemo(() => {
        const trackingText = isEdit ? messages.updatePhoneTrackButtonIdentifier : messages.periodicUpdateTrackButtonIdentifier;
        return translator(trackingText, {buttonText: secondaryBtnText});
    }, [translator, isEdit, secondaryBtnText]);

    const primaryBtnTrackingText = useMemo(() => {
        const trackingText = isEdit ? messages.saveChangesTrackButtonIdentifier : messages.confirmTrackButtonIdentifier;
        return translator(trackingText);
    }, [translator, isEdit]);

    const modalTitle = useMemo(() => {
        const btnText = isEdit
            ? messages.updateContactInfo
            : messages.reviewYourContactInfo;
        return translator(btnText);
    }, [translator, isEdit]);

    const primaryBtnDisable = useMemo(() => {
        const isDisabled =
            !isComponentValid ||
            !_.get(accountData, "aspects.valid", true) ||
            phoneType === "nophone_wmic";
        return isEdit ? isDisabled : false;
    }, [
        isComponentValid,
        accountData,
        phoneType,
        isEdit,
    ]);

    const handleClose = () => {
        handleCloseModalWithButtonText(secondaryBtnText);
    };

    return (
        <ModalNext isOpen={isOpen}
                   shouldCloseOnEsc
                   shouldCloseOnOverlayClick={false}
                   onRequestClose={handleClose}
                   className={styles.modal}>
            <h1 className={styles.modalHeader}><i className='fa fa-user-circle-o'/>{modalTitle}</h1>
            {isLoading ? (
                <WMICLoader />
            ) : (
                <Fragment>
                    <ModalBody>
                        {isEdit ? (
                            <WMICEditPhone
                                onValidate={onValidate}
                                isComponentValid={isComponentValid}
                                accountData={accountData}
                                onValueChange={(updatedAccountData) => { setAccountData(updatedAccountData) }}
                            />
                        ) : (
                            <div className={styles.modalBody}>
                                <div className={styles.headerInfo}>
                                    <p
                                        className={classnames(
                                            styles.header,
                                            styles.noMargin
                                        )}
                                    >
                                        {htmlParser(
                                            translator(messages.pleaseReview)
                                        )}
                                    </p>
                                    <p>
                                        {htmlParser(
                                            WMICRichTextUtil.safeUnescape(
                                                translator(
                                                    messages.havingTheRightInfo
                                                )
                                            )
                                        )}
                                    </p>
                                </div>
                                <div className={styles.contactInfo}>
                                    {htmlParser(
                                        WMICRichTextUtil.safeUnescape(
                                            translator(messages.phoneNumber, {
                                                phoneNumber: getPhoneNumber(),
                                            })
                                        )
                                    )}
                                    &nbsp;
                                    <WMICLink onClick={onPhoneNumberEdit}
                                              aria-label={translator(messages.editAria)}
                                              role="link"
                                              trackButtonIdentifier={translator(messages.editTrackButtonIdentifier)}>
                                        {translator(messages.edit)}
                                    </WMICLink>
                                </div>
                                <div className={styles.contactInfo}>
                                    {htmlParser(
                                        WMICRichTextUtil.safeUnescape(
                                            translator(messages.email, {
                                                email: getAccountDataFieladIfPresent(
                                                    "emailAddress1"
                                                ),
                                            })
                                        )
                                    )}
                                </div>
                                {showAddress() && <div className={styles.contactInfo}>
                                    <p aria-hidden>
                                        {htmlParser(
                                            WMICRichTextUtil.safeUnescape(
                                                translator(messages.address, {
                                                    address1: getAccountDataFieladIfPresent(
                                                        "primaryAddress.addressLine1"
                                                    ),
                                                    address2: `${getAccountDataFieladIfPresent(
                                                        "primaryAddress.city"
                                                    )} ${getAccountDataFieladIfPresent(
                                                        "primaryAddress.state"
                                                    )}`,
                                                    address3: getAccountDataFieladIfPresent(
                                                        "primaryAddress.postalCode"
                                                    ),
                                                })
                                            )
                                        )}
                                    </p>
                                    <p className="sr-only">
                                        {translator(messages.addressAria, {
                                            address1: getAccountDataFieladIfPresent(
                                                "primaryAddress.addressLine1"
                                            ),
                                            city: getAccountDataFieladIfPresent(
                                                "primaryAddress.city"
                                            ),
                                            state: getAccountDataFieladIfPresent(
                                                "primaryAddress.state"
                                            ),
                                            zipCode: getAccountDataFieladIfPresent(
                                                "primaryAddress.postalCode"
                                            ),
                                        })}
                                    </p>
                                </div>}
                                <div>
                                    <p className={styles.header}>
                                        {htmlParser(
                                            translator(messages.toUpdate)
                                        )}
                                    </p>
                                    <ul
                                        className={classnames(
                                            "ww-base-ul",
                                            styles.contactList
                                        )}
                                    >
                                        <li>
                                            <WMICLink
                                                href="tel:1-800-640-2920"
                                                id="contactNumber"
                                                aria-label={translator(messages.contactNumberAria)}
                                            >
                                                {htmlParser(
                                                    translator(
                                                        messages.contactNumber1
                                                    )
                                                )}
                                            </WMICLink>
                                            <span aria-hidden="true">
                                                {htmlParser(
                                                    WMICRichTextUtil.safeUnescape(
                                                        translator(
                                                            messages.contactNumber2
                                                        )
                                                    )
                                                )}
                                            </span>
                                            <span className="sr-only">
                                                {htmlParser(
                                                    WMICRichTextUtil.safeUnescape(
                                                        translator(
                                                            messages.mondayToFridayAria
                                                        )
                                                    )
                                                )}
                                            </span>

                                        </li>
                                        <li>
                                            <WMICLink
                                                href="mailto:service.us@wawanesa.com"
                                                id="contactMail"
                                                aria-label={translator(messages.contactMailAria)}
                                            >
                                                {htmlParser(
                                                    translator(
                                                        messages.contactMail
                                                    )
                                                )}
                                            </WMICLink>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        )}
                    </ModalBody>
                    <ModalFooter>
                        <WMICButton
                            onClick={() =>
                                handleCloseModalWithButtonText(secondaryBtnText)
                            }
                            type="outlined"
                            trackButtonIdentifier={secondaryBtnTrackingText}
                        >
                            {secondaryBtnText}
                        </WMICButton>
                        <WMICButton
                            onClick={handleConfirm}
                            type="primary"
                            disabled={primaryBtnDisable}
                            trackButtonIdentifier={primaryBtnTrackingText}
                        >
                            {primaryBtnText}
                        </WMICButton>
                    </ModalFooter>
                </Fragment>
            )}
        </ModalNext>
    );
};

WMICReviewContactInfoModal.propTypes = {
    policies: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            details: PropTypes.object,
        })
    ).isRequired,
    onPayPolicyClick: PropTypes.func.isRequired,
};

export default WMICReviewContactInfoModal;

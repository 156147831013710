import { defineMessages } from 'react-intl';

export default defineMessages({
    dialogCheckEmail: {
        id: 'accountManagement.views.check-email.title',
        defaultMessage: 'Check your email'
    },
    dialogRecheckEmail: {
        id: 'accountManagement.views.check-email.recheck title',
        defaultMessage: 'Recheck your email'
    },
    dialogMigrationCheckEmail: {
        id: 'accountManagement.views.check-email.change title',
        defaultMessage: 'Please change your password'
    },
    dialogCheckEmailDescriptionUnformatted: {
        id: 'accountManagement.views.check-email.description.unformatted',
        defaultMessage: 'We\'ve sent a confirmation email to {email}. Please click on the link in the email within the next 24 hours to complete your registration.'
    },
    dialogCheckEmailDescription: {
        id: 'accountManagement.views.check-email.description',
        defaultMessage: '&lt;span&gt;We\'ve sent a confirmation email to &lt;strong&gt;{email}&lt;/strong&gt;. Please click on the link in the email within the next 24 hours to complete your registration.&lt;/span&gt;'
    },
    dialogCheckEmailDescriptionResetUnformatted: {
        id: 'accountManagement.views.check-email.description reset.unformatted',
        defaultMessage: 'We\'ve sent a password reset email to {email}. Please click on the link in the email within the next 24 hours to update your password.'
    },
    dialogCheckEmailDescriptionReset: {
        id: 'accountManagement.views.check-email.description reset',
        defaultMessage: '&lt;span&gt;We\'ve sent a password reset email to &lt;strong&gt;{email}&lt;/strong&gt;. Please click on the link in the email within the next 24 hours to update your password.&lt;/span&gt;'
    },
    dialogCheckEmailDescriptionMigrationUnformatted: {
        id: 'accountManagement.views.check-email.description migration.unformatted',
        defaultMessage: 'A password reset link has been sent to {email}.Please click on the link in the email within the next 24 hours to complete your registration.'
    },
    dialogCheckEmailDescriptionMigration: {
        id: 'accountManagement.views.check-email.description migration',
        defaultMessage: '&lt;span&gt;A password reset link has been sent to &lt;strong&gt;{email}&lt;/strong&gt;.Please click on the link in the email within the next 24 hours to complete your registration.&lt;/span&gt;'
    },
    dialogCheckEmailMigrationNotice: {
        id: 'accountManagement.views.check-email.description migration notice',
        defaultMessage: '&lt;span&gt;We\'ve recently upgraded our system. We\'ll need you to reset your online account password.&lt;/span&gt;'
    },
    dialogCheckResendEmailUnformatted: {
        id: 'accountManagement.views.check-email.resending the email.unformatted',
        defaultMessage: "If you didn't receive the email check your spam folder or try resending the email.",
    },
    dialogCheckResendEmail: {
        id: 'accountManagement.views.check-email.resending the email',
        defaultMessage: 'If you didn\'t receive the email check your spam folder or try {resendEmail}.',
    },
    dialogCheckResendEmailLink: {
        id: 'accountManagement.views.check-email.resending the email link',
        defaultMessage: 'resending the email'
    },
    dialogCheckResendEmailConfirmModalTitle: {
        id: 'accountManagement.views.check-email.confirm modal title',
        defaultMessage: 'Email has been resent'
    },
    dialogCheckResendEmailConfirmModalMesssage: {
        id: 'accountManagement.views.check-email. confirm modal message',
        defaultMessage: 'Your confirmation email has been resent to {email}'
    },
    dialogCheckResendEmailConfirmModalButtonText: {
        id: 'accountManagement.views.check-email.confirm modal button text',
        defaultMessage: 'OK'
    },
    loginLink: {
         id: 'accountManagement.views.check-email.already have an account',
         defaultMessage: 'Already have an account? {loginLink}'
    },
    loginNow: {
         id: 'accountManagement.views.check-email.Log in now',
         defaultMessage: 'Log in now'
    },
    furtherAssistanceTextUnformatted: {
        id: 'accountManagement.views.check-email.further assistance.unformatted',
        defaultMessage: "If you need further assistance contact us by phone at 800-640-2920 Monday to Friday 7:30 am to 7:30 pm PT and Saturday 8:00 am to 4:30 pm PT",
    },
 });

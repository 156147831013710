import { MetadataContent } from '@jutro/legacy/uiconfig';
import { useTranslator } from '@jutro/locale';

import metadata from './FNOLPAAutoGlassComponent.metadata.json5';
import styles from './FNOLPAAutoGlassComponent.module.scss';
import messages from './FNOLPAAutoGlassComponent.messajes';
import React from "react";


function FNOLPAAutoGlassComponent(props){
    const { state } = props;

    const handleClick = () => {
        window.open("https://submit.glassclaim.com/SelfService/Default.aspx?ClientTag=d2fd3766-dfe2-46c3-b96c-372a234ca42e", "_blank");
    }

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: { handleClick }
    };

    const translator = useTranslator();

    const overrideProps = {
        FNOLPAAutoGlassButton: {
            trackbuttonidentifier: translator(messages.FNOLPAAutoGlassButtonTrackButtonIdentifier, {state})
        }
    }

    return (
        <MetadataContent
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            {...resolvers} />
    );
}

export default FNOLPAAutoGlassComponent;

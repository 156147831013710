import React, { Fragment, useMemo, useState, useCallback } from 'react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { WMICEMQVehicleAccordionComponent } from 'wmic-capability-emq-react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import metadata from './WMICEMQVehiclesComponent.metadata.json5';
import styles from './WMICEMQVehiclesComponent.module.scss';
import messages from './WMICEMQVehiclesComponent.messages';

function WMICEMQVehiclesComponent(props) {
    const translator = useTranslator();
    const { vehicles, updateVehicles } = props;
    const [localVehicles, updateLocalVehicles] = useState(vehicles);
    const history = useHistory();

    const generateVehicleAccordionOverrides = useMemo(() => {
        const overrides = vehicles.Vehicles.map((vehicle, index) => {
            return {
                [`vehicleAccordion${index}`]: {
                    setData: (val) => {
                        vehicles.Vehicles[index].NewEmqVehicle = val;
                        updateLocalVehicles(vehicles);
                        updateVehicles(vehicles);
                    }
                }
            };
        });
        return Object.assign({}, ...overrides);
    }, [vehicles, updateVehicles]);

    const submitEMQData = useCallback(() => {
        // TODO handle data submission
        history.push('/california/estimated-mileage/success');
    }, []);

    const getVehiclePageText = (data) => {
        return (
            <Fragment>
                <p className={styles['ww-margin-1rem']}>
                    {translator(messages.EMQVehiclePageWelcome)}
                    <strong>
                        {data.insuredName}
                    </strong>
                </p>
                <p className={styles['ww-margin-1rem']}>
                    {translator(messages.EMQVehiclePageThereAre)}
                    <strong>
                        {data.Vehicles.length}
                    </strong>
                    {translator(messages.EMQVehiclePageVehiclesListed)}
                </p>
                <p>
                    {translator(messages.EMQVehiclePagePleaseComplete)}
                    <strong>
                        {translator(messages.EMQVehiclePageAll)}
                    </strong>
                    {translator(messages.EMQVehiclePageBeforeClicking)}
                    <strong>
                        {translator(messages.EMQVehiclePageSubmit)}
                    </strong>
                    .
                </p>
            </Fragment>
        );
    };

    const resolvers = {
        resolveComponentMap: {
            WMICEMQVehicleAccordionComponent: WMICEMQVehicleAccordionComponent
        },
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onSubmit: submitEMQData
        },
    };

    const overrideProps = {
        emqVehiclePageTextContainer: {
            content: getVehiclePageText(vehicles)
        },
        ...generateVehicleAccordionOverrides
    };

    WMICEMQVehiclesComponent.propTypes = {
        vehicles: PropTypes.arrayOf({
            NewEmqVehicle: PropTypes.shape({})
        }).isRequired,
        updateVehicles: PropTypes.func.isRequired
    };


    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            componentMap={resolvers.resolveComponentMap}
            model={vehicles}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
        />
    );
}

export default WMICEMQVehiclesComponent;

import { defineMessages } from 'react-intl';

export default defineMessages({
    FNOLMortgageesTitle: {
        id: 'wmic.fnol-page.mortgagees.title',
        defaultMessage: 'Mortgagee'
    },
    FNOLMortgageesNoMortgageesWarning: {
        id: 'wmic.fnol-page.mortgagees.no-mortgage-warning',
        defaultMessage: 'No Mortgage'
    },
    FNOLMortgageesDescription: {
        id: 'wmic.fnol-page.mortgagees.description-label',
        defaultMessage: `Mortgage {num} Information`
    },
    FNOLMortgageesToggleLabel: {
        id: 'wmic.fnol-page.mortgagees.toggle-label',
        defaultMessage: 'Does the home have any mortgages?'
    },
    FNOLMortgageesTabsetLabel: {
        id: 'wmic.fnol-page.mortgagees.tabset-label',
        defaultMessage: 'Mortgagees:'
    },
    FNOLMortgageesCountDropdown: {
        id: 'wmic.fnol-page.mortgagees.count-label',
        defaultMessage: 'How Many?'
    },
    FNOLMortgageesFormName: {
        id: 'wmic.fnol-page.mortgagees.name-label',
        defaultMessage: 'Name'
    },
    FNOLMortgageesFormAddress: {
        id: 'wmic.fnol-page.mortgagees.address-label',
        defaultMessage: 'Address'
    },
    FNOLMortgageesFormCity: {
        id: 'wmic.fnol-page.mortgagees.city-label',
        defaultMessage: 'City'
    },
    FNOLMortgageesFormCountry: {
        id: 'wmic.fnol-page.mortgagees.country-label',
        defaultMessage: 'Country'
    },
    FNOLMortgageesFormStateProvince: {
        id: 'wmic.fnol-page.mortgagees.state-province-label',
        defaultMessage: 'State'
    },
    FNOLMortgageesFormZipCode: {
        id: 'wmic.fnol-page.mortgagees.postalcode-label',
        defaultMessage: 'Zip Code'
    },
    FNOLMortgageesFormPostalCode: {
        id: 'wmic.fnol-page.mortgagees.zipcode-label',
        defaultMessage: 'Postal Code'
    },
    FNOLMortgageesFormHomePhone: {
        id: 'wmic.fnol-page.mortgagees.home-phone-label',
        defaultMessage: 'Home Phone Number'
    },
    FNOLMortgageesFormBusinessPhone: {
        id: 'wmic.fnol-page.mortgagees.business-phone-label',
        defaultMessage: 'Business Phone Number'
    },
    FNOLMortgageesFormExt: {
        id: 'wmic.fnol-page.mortgagees.ext-label',
        defaultMessage: 'Ext'
    },
    FNOLMortgageesFormCellPhone: {
        id: 'wmic.fnol-page.mortgagees.cell-phone-label',
        defaultMessage: 'Cell Phone Number'
    }
})
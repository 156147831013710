import { defineMessages } from 'react-intl';

export default defineMessages({
    forgotPassword: {
        id: 'accountManagement.views.forgot-login-info.forgot password',
        defaultMessage: 'Forgot password?'
    },
    forgotEmail: {
        id: 'accountManagement.views.forgot-login-info.forgot email',
        defaultMessage: 'Forgot email?'
    }
 });
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__WMICModal__wawaModal{width:50vw}.app__WMICModal__headerContainer{font-size:36px;padding:20px 20px 10px}.app__WMICModal__bodyContainer{padding:5px 20px 40px}", "",{"version":3,"sources":["webpack://./node_modules/gw-capability-policy-react/WMICModal/WMICModal.module.scss"],"names":[],"mappings":"AACA,2BACE,UAAA,CAGF,iCACE,cAAA,CACA,sBAAA,CAGF,+BACE,qBAAA","sourcesContent":["\n.wawaModal {\n  width: 50vw;\n}\n\n.headerContainer {\n  font-size: 36px;\n  padding: 20px 20px 10px 20px;\n}\n\n.bodyContainer {\n  padding: 5px 20px 40px 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wawaModal": "app__WMICModal__wawaModal",
	"headerContainer": "app__WMICModal__headerContainer",
	"bodyContainer": "app__WMICModal__bodyContainer"
};
export default ___CSS_LOADER_EXPORT___;

import { defineMessages } from 'react-intl';

export default defineMessages({
    createPassword: {
        id: 'accountManagement.views.create-password.title',
        defaultMessage: 'Create password',
    },
    changePassword: {
        id: 'accountManagement.views.change-password.title',
        defaultMessage: 'Reset password',
    },
    createPasswordDescription: {
        id: 'accountManagement.views.create-password.description.Enter your email address',
        defaultMessage: 'Please create your password within 5 minutes.',
    },
    createPasswordTimeout: {
        id: 'accountManagement.views.create-password.description.timeout',
        defaultMessage: 'Password entry timeout: {formattedTimerString}',
    },
    passwordDescription: {
        id: 'accountManagement.views.create-password.password description placeholder',
        defaultMessage: '{passwordRequirementLabel}',
    },
    passwordDescriptionShow: {
        id: 'accountManagement.views.create-password.Your password needs to include the following',
        defaultMessage: 'Your password needs to include the following:',
    },
    passwordDescriptionHide: {
        id: 'accountManagement.views.create-password.view password requirements hide',
        defaultMessage: 'View password requirements'
    },
    passwordRequirementA: {
        id: 'accountManagement.views.create-password.password requirementA',
        defaultMessage: "At least 8 characters and at most 20 characters"
    },
    passwordRequirementB: {
        id: 'accountManagement.views.create-password.password requirementB',
        defaultMessage: "At least 1 number"
    },
    passwordRequirementC: {
        id: 'accountManagement.views.create-password.password requirementC',
        defaultMessage: "At least 1 uppercase letter"
    },
    passwordRequirementD: {
        id: 'accountManagement.views.create-password.password requirementD',
        defaultMessage: "At least 1 lowercase letter"
    },
    passwordRequirementE: {
        id: 'accountManagement.views.create-password.password requirementE',
        defaultMessage: "At least 1 special character"
    },
    passwordRequirementF: {
        id: 'accountManagement.views.create-password.password requirementF',
        defaultMessage: "Cannot be the same as your current password"
    },
    passwordValidation: {
        id: 'accountManagement.views.create-password.validation.password',
        defaultMessage: "Password does not meet minimum requirement"
    },
    passwordConfirmValidation: {
        id: 'accountManagement.views.create-password.validation.confirm password',
        defaultMessage: "Password does not match"
    },
    genericErrorMessage: {
        id: 'accountManagement.views.create-password.genericErrorMessage',
        defaultMessage: 'Unable to process request at this moment',
    },
    passwordReuseErrorMessage: {
        id: 'accountManagement.views.create-password.passwordReuseErrorMessage',
        defaultMessage: 'Your new password cannot be the same as your current password',
    },
    password: {
        id: 'accountManagement.views.create-password.Password',
        defaultMessage: 'Password',
    },
    confirmPassword: {
        id: 'accountManagement.views.create-password.Confirm your password',
        defaultMessage: 'Confirm your password',
    },
    submitButton: {
        id: 'accountManagement.views.create-password.Submit',
        defaultMessage: 'Submit',
    },
});

import { defineMessages } from 'react-intl';

export default defineMessages({
    register: {
        id: 'accountManagement.views.reset-password.title',
        defaultMessage: 'Reset Password',
    },
    registerDescription: {
        id: 'accountManagement.views.reset-password.description.Enter your email',
        defaultMessage: 'Enter your email to receive a password reset link.',
    },
    emailLabel: {
        id: 'accountManagement.views.reset-password.Email address',
        defaultMessage: 'Email address',
    },
    submitButton: {
        id: 'accountManagement.views.reset-password.Submit',
        defaultMessage: 'Submit',
    },
    forgotEmailLink: {
        id: 'accountManagement.views.reset-password.Forgot email',
        defaultMessage: '&lt;a href="{link}"&gt;Forgot email&lt;/a&gt;',
    },
    genericErrorMessage: {
        id: 'accountManagement.views.reset-password.genericErrorMessage',
        defaultMessage: 'Unable to process request at this moment',
    },
    invalidEmail: {
        id: 'accountManagement.views.reset-password.validation.Email address',
        defaultMessage: 'Invalid email address'
    },
    userNotFound: {
        id: 'accountManagement.views.reset-password.validation.User not found',
        defaultMessage: 'User not registered or active'
    },
    forgotPasswordNotAllowed: {
        id: 'accountManagement.views.reset-password.validation.User is not registered',
        defaultMessage: 'User not registered or active'
    }
 });